import React from 'react';
import { connect } from "react-redux";
import './CheckoutPriceTable.scss';
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  getPriceOfActiveAlternatives,
  getPriceOfActiveItems,
  getPriceOfGroups,
  getPriceOfOptionals
} from "../../redux/selectors";
import Icon from "../icon/Icon";
import { mapTypeToIcon } from "../../../vendor/utils/Mappings";
import { currencyFormat } from "../../../vendor/utils/Utils";

function CheckoutPriceTable (props) {

  const renderGroups = (groups, itemMap) => {
    return <>
      { groups.map ( group => {
        return <div className={ 'checkout-price-table__item' } key={ group.items.toString () }>
          <div className={ 'checkout-price-table__icon' }>
            <Icon name={ mapTypeToIcon ( group.type ) }/>
          </div>
          <div className={ 'checkout-price-table__label' }>{ group.label }</div>
          <div
            className={ 'checkout-price-table__price' }>{ currencyFormat ( group.price + getPriceOfActiveAlternatives ( itemMap, group.items ) ) }</div>
        </div>
      } ) }
    </>
  }

  const renderOptionalPrice = (optionalItemsPrice) => {
    return (
      <div className={ 'checkout-price-table__item' }>
        <div className={ 'checkout-price-table__icon' }>
          <Icon name={ mapTypeToIcon ( 'Ausflug' ) }/>
        </div>
        <div className={ 'checkout-price-table__label' }>Ausflüge</div>
        <div className={ 'checkout-price-table__price' }>{ currencyFormat ( optionalItemsPrice ) }</div>
      </div>
    )
  }

  const renderTotalPrice = (totalPrice) => {
    return <div className={ 'checkout-price-table__total-price-wrapper' }>
      <div className={ 'checkout-price-table__total-price-label ts-bold' }>
        Gesamt
      </div>

      <div className={ 'checkout-price-table__total-price ts-bold' }>
        { currencyFormat ( totalPrice ) }
      </div>
    </div>
  }

  const classes = classNames (
    'checkout-price-table checkout-summary__box',
    props.additionalClasses
  );

  return (
    <div className={ classes }>
      <div>
        { renderGroups ( props.offerItems, props.itemMap ) }
        { props.optionalItemsPrice !== 0 && renderOptionalPrice ( props.optionalItemsPrice ) }
      </div>
      <div className={ 'checkout-price-table__line' }></div>
      { renderTotalPrice ( props.totalPrice ) }
    </div>
  )
}

CheckoutPriceTable.propTypes = {
  /**
   * amount of possible steps
   */
  steps: PropTypes.number,
  /**
   * progress
   */
  currentStep: PropTypes.number,
};

CheckoutPriceTable.defaultProps = {
  steps: 3,
  currentStep: 1
};

const mapStateToProps = (state, ownProps) => {
  return {
    itemMap: state.itemManagement.itemMap,
    optionalItemsPrice: getPriceOfOptionals ( state.itemManagement.itemMap ),
    offerItems: state.offerContentManagement.offerItems,
    totalPrice: getPriceOfGroups ( state.offerContentManagement.offerItems ) + getPriceOfActiveItems ( state.itemManagement.itemMap ),
  }
};

export default connect ( mapStateToProps, {} ) ( CheckoutPriceTable );
