import React, {Component, lazy, Suspense} from 'react';
import './MainPage.scss'
import {InvoiceContent} from "./invoice/InvoiceContent";
import TravelCountdown from "../components/travelcountdown/TravelCountdown";
import Footer from "../components/footer/Footer";
import OfferContent from "./offer/OfferContent";
import OptionalsContent from "./optionals/OptionalsContent";
import DocumentsContent from "./documents/DocumentsContent";
import Stage from "../components/stage/Stage";
import {Preloader} from "../components/preloader/Preloader";
import {Redirect} from "react-router-dom";
import {OfferListService} from "../services/OfferListService";

import withDataProvider from "../hoc/WithDataprovider";
import {DEBUG, DebugBar} from "../debug/DebugBar";
import {DataProviderService} from "../services/DataProviderService";
import {StorageManager, storageManager} from "../services/StorageManager";
import {Consent} from "../components/consent/Consent";
import {HeaderPortal} from "../components/header/portal/HeaderPortal";
import Link from "../components/link/Link";
import classNames from "classnames";
import {getQueryVariable, isDevEnvironment} from "../../vendor/utils/Utils";
import {BREAKPOINTS} from "../../vendor/utils/BreakpointUtils";
import {connect} from "react-redux";
import {
	dispatchIsAuthenticated,
	dispatchIsDesktop,
	dispatchUpdateOffer,
	dispatchUpdateOfferList,
	dispatchEnvironment
} from "../redux/actions/globalStateManagementAction";
import {getUtcSafeDate, isAfterDate} from "../../vendor/utils/DateUtils";
import {Modal} from "../components/modal/Modal";
import {
	dispatchFetchData,
	FETCH_TYPE_DOCUMENTS_COMMON,
	FETCH_TYPE_DOCUMENTS_ITEMS,
	FETCH_TYPE_INVOICE,
	FETCH_TYPE_OFFER_CONTENT,
	FETCH_TYPE_OPTIONALS,
} from "../redux/actions/fetchAction";
import {Navigation} from "../components/navigation/Navigation";
import {NavigationItem} from "../components/navigation/NavigationItem";
import DigitalOfferRoutesContent from "./offerRoutes/RoutesContent";
import PropTypes from "prop-types";
import {viewportUtils} from "../../vendor/utils/ViewportUtils";

const NotificationStack = lazy(() => import('../components/notification/NotificationStack'));

const tabToFetchTypeMap = {
	'reise': [FETCH_TYPE_OFFER_CONTENT],
	'rechnung': [FETCH_TYPE_INVOICE],
	'dokumente': [FETCH_TYPE_DOCUMENTS_COMMON, FETCH_TYPE_DOCUMENTS_ITEMS],
	'ausfluege': [FETCH_TYPE_OPTIONALS]
};

const StageWithDataProvider = withDataProvider(Stage, {
	useMock: false,
	endpoint: 'stage'
});

class MainPagePortal extends Component {

	constructor(props) {
		super(props);

		this.widthChange = this.widthChange.bind(this);
		this.mediaQuery = window.matchMedia(`print, (min-width: ${BREAKPOINTS.desktop}px)`);
		this.mediaQuery.addListener(this.widthChange);
		props.dispatchIsDesktop(this.mediaQuery.matches);
		props.dispatchEnvironment('portal');

		this.state = {
			selectedId: (this.props.match.params && this.props.match.params.tab) || 'reise',
			waitForAuth: true
		};

		/**
		 * setCookie with queryParam from CPV
		 */
		const token = new URL(window.location.href).searchParams.get("token");

		if (token) {
			storageManager.authToken = token;
			window.history.replaceState(null, null, window.location.pathname);
		}

		this.radioGroupUpdateHandler = this.radioGroupUpdateHandler.bind(this);
		this.contentChangedHandler = this.contentChangedHandler.bind(this);
		this.onOfferChange = this.onOfferChange.bind(this);
		this.reloadClickHandler = this.reloadClickHandler.bind(this);
	}

	/**
	 * handles widthChangeEvents
	 */
	widthChange() {
		this.props.dispatchIsDesktop(this.mediaQuery.matches);
	}

	/**
	 * changeHandler for NotificationStack
	 *
	 * @param id {string} id of new notification
	 * @param context {string} name of tab content the notification belongs to
	 */
	contentChangedHandler() {
		this.setState({showReloadModal: true});
	}

	onOfferChange(event) {
		const currentOffer = event.offer;

		storageManager.offerNumber = currentOffer.offerNumber;

		this.props.dispatchUpdateOffer(currentOffer);

		//change route depending on offerNumber
		this.updateHistory(currentOffer.offerNumber, null)
	}

	/**
	 * check if routeChange should cause content change
	 * @param prevProps {object}
	 * @param prevState {object}
	 * @param snapshot
	 */
	componentDidUpdate(prevProps, prevState, snapshot) {

		const tab = this.props.match && this.props.match.params && this.props.match.params.tab;

		if (tab && (prevState.selectedId !== tab)) {
			this.setState({
				selectedId: tab,
				showReloadModal: false
			});
		}

		if (this.props.hintId && (prevProps.hintId !== this.props.hintId)) {
			this.updateHistory(this.props.offerNumber, this.props.hintId)
		}

	}

	componentDidMount() {

		const initialOfferNumber = (this.props.match && this.props.match.params.offer);
		let useMock = getQueryVariable(this.props.location.search, 'useMockOffers');

		this.dataProvider = new OfferListService({endpoint: 'list', useMock: useMock});

		this.dataProvider.getOffer(initialOfferNumber).then(response => {

			this.props.dispatchUpdateOffer(response.currentOffer);
			this.props.dispatchUpdateOfferList(response.offerList);
			this.props.dispatchIsAuthenticated(true); // TODO: woher weiss dieser request, das man angemeldet ist???

			this.setState({
				waitForAuth: false,
			});

			this.updateHistory(response.currentOffer.offerNumber, null);

		}).catch(err => {
			this.setState({waitForAuth: false});
		})
	}

	componentWillUnmount() {
		if (this.dataProvider) {
			this.dataProvider.cancelRequest();
			this.dataProvider = null;
		}
	}

	/**
	 * clickHandler if reloadButton has been clicked
	 */
	reloadClickHandler() {
		this.setState({showReloadModal: false});

		//get the fetchTypes to call the correct end
		const fetchTypeArray = tabToFetchTypeMap[this.state.selectedId];

		if (fetchTypeArray && this.props.dispatchFetchData) {
			fetchTypeArray.forEach((value, index, array) => {
				this.props.dispatchFetchData(value, true);
			})
		}
	}

	/**
	 * update history
	 * @param offerToken
	 * @param hintId
	 */
	updateHistory(offerNumber = this.props.offerNumber, hintId = this.props.match.params.hintId) {

		const path = this.props.match.path.split(':')[0];

		if (this.props.hasRouteHints) {
			this.props.history.push(`${path}${offerNumber}/${(this.props.match.params && this.props.match.params.tab)}/${(hintId || '')}`);
		} else {
			this.props.history.push(`${path}${offerNumber}/${((this.props.match.params && this.props.match.params.tab) || 'reise')}`);
		}

	}

	/**
	 * append content to radioGroupContent
	 * @param uid {string} name of tabcontent
	 * @param updateId {string}	id to destinguish if content needs to fetch new data
	 * @return {*}
	 */
	contentFactory(uid) {
		let content = null;
		switch (uid) {
			case 'reise':
				content = <OfferContent/>;
				break;
			case 'rechnung':
				content = <InvoiceContent/>;
				break;
			case 'dokumente':
				content = <DocumentsContent/>;
				break;
			case 'ausfluege':
				content = <OptionalsContent/>;
				break;
			case 'routes':
				content = <DigitalOfferRoutesContent match={this.props.match}/>;
				break;

			default:
				break;
		}

		return content;
	}

	radioGroupUpdateHandler() {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}

	render() {

		const showDebugBar = isDevEnvironment() && process.env.REACT_APP_GLOBAL_DEBUG === "true" && process.env.REACT_APP_GLOBAL_DISABLE_MOCK === "false";

		if (this.state.waitForAuth) {
			return <Preloader type={'full-screen'}/>;
		}

		if (this.props.isAuthenticated) {
			const content = this.contentFactory(this.state.selectedId);

			if (!content) {
				return <Redirect to={{pathname: "/404 "}}/>
			}

			if (!this.props.offerNumber) {
				return <Redirect to='/keine-reisen'/>
			}

			const classes = classNames(
				'main-page',
				this.props.additionalClasses
			);

			// if routeHint is false and tab is "routes" fallback to "reise"
			if (!this.props.hasRouteHints && this.props.match.params.tab === 'routes') {
				return <Redirect to={`/${this.props.offerNumber}/reise`}/>
			}

			const renderNotifications = isAfterDate(new Date(this.props.journeyEnd), getUtcSafeDate(new Date()));
			return (
				<div className={classes}>
					<div className={'main-page__header-wrapper'}>
						{showDebugBar && <DebugBar show={[DEBUG.TRACKING, DEBUG.NOTIFICATION]}/>}

						{renderNotifications &&
						<div className='main-page__header-notifications'>
							<Suspense fallback={<Preloader/>}>
								<NotificationStack
									matchParams={this.props.match.params}
									stackLimit={2}
									dataProvider={new DataProviderService({
										useMock: false,
										endpoint: 'portal/notifications'
									})}
									onContentChanged={this.contentChangedHandler}
									onMatchedNotificationClicked={this.reloadClickHandler}
								/>
							</Suspense>
						</div>
						}

						<HeaderPortal onOfferChange={this.onOfferChange}
									  stickToTop={false}
						/>

					</div>
					<StageWithDataProvider withContent={true} join={false} updateId={this.props.offerNumber}/>

					<Navigation>
						<NavigationItem uid='reise'
										linkPath={`/${this.props.offerNumber}/reise`}
										label='Ihre Reise'
										selectedId={this.state.selectedId}
										onCLick={viewportUtils.scrollToContentTop}
						/>
						<NavigationItem uid='rechnung'
										linkPath={`/${this.props.offerNumber}/rechnung`}
										label='Ihre Rechnung'
										selectedId={this.state.selectedId}
										onCLick={viewportUtils.scrollToContentTop}
						/>
						<NavigationItem uid='dokumente'
										linkPath={`/${this.props.offerNumber}/dokumente`}
										label='Ihre Reiseunterlagen'
										selectedId={this.state.selectedId}
										onCLick={viewportUtils.scrollToContentTop}
						/>
						<NavigationItem uid='ausfluege'
										linkPath={`/${this.props.offerNumber}/ausfluege`}
										label='Buchbare Ausflüge'
										selectedId={this.state.selectedId}
										onCLick={viewportUtils.scrollToContentTop}
						/>
						{this.props.hasRouteHints &&
						<NavigationItem uid='routes'
										linkPath={`/${this.props.offerNumber}/routes/${((this.props.match.params && this.props.match.params.hintId) || '')}`}
										label='Routenvorschläge'
										selectedId={this.state.selectedId}
										onCLick={viewportUtils.scrollToContentTop}
						/>
						}
					</Navigation>

					<main className='main-page__content'>
						{content}
						<TravelCountdown additionalClasses={'main-page__countdown'}
										 startDate={this.props.journeyStart}/>
					</main>

					<Footer additionalClasses={'footer'}/>

					{(this.state.showReloadModal) &&
					<Modal
						additionalClasses={'main-page__reload-modal'}
						size={"auto"}
						isBlocking={false}
						theme={'dark-grey'}
						onClose={() => this.setState({showReloadModal: false})}>
						<div className={'main-page__reload-content'}>
							<div className={'h4 main-page__reload-headline'}>Es gibt Neuigkeiten</div>
							<Link additionalClasses={'main-page__reload-link'} icon={'transfer'} withoutAnchor={true}
								  label={'Seite neu laden'} onClick={this.reloadClickHandler}/>
						</div>
					</Modal>}

					{!StorageManager.getCookie('acceptedCookies') &&
					<Consent brandName={'CANUSA'}
									 brandUrl={'https://www.canusa.de'}
					/>
					}
				</div>
			)
		}

		return <Redirect to={{
			pathname: "/login",
			state: {from: this.props.location.pathname}
		}}/>;
	}
}

MainPagePortal.propTypes = {
	/**
	 * @example: '2019-06-29'
	 */
	journeyStart: PropTypes.string,

	/**
	 * @example: '2019-07-3'
	 */
	journeyEnd: PropTypes.string,

	/**
	 * offernumber e.g. HAM-a1b2c3d4-ABC
	 */
	offerNumber: PropTypes.string,

	/**
	 * is logged in successfully
	 */
	isAuthenticated: PropTypes.bool,

	/**
	 * has offer route hints
	 */
	hasRouteHints: PropTypes.bool,
	/**
	 * id of routehint
	 * @example: 6500
	 */
	hintId: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
	return {
		journeyStart: state.globalStateManagement.offer.journeyStart,
		journeyEnd: state.globalStateManagement.offer.journeyEnd,
		offerNumber: state.globalStateManagement.offer.offerNumber,
		isAuthenticated: state.globalStateManagement.isAuthenticated,
		hintId: state.routeHintManagement.hintId,
		hasRouteHints: state.routeHintManagement.hasRouteHints
	}
};

export default connect(mapStateToProps, {
	dispatchIsDesktop: dispatchIsDesktop,
	dispatchUpdateOffer: dispatchUpdateOffer,
	dispatchUpdateOfferList: dispatchUpdateOfferList,
	dispatchIsAuthenticated: dispatchIsAuthenticated,
	dispatchFetchData: dispatchFetchData,
	dispatchEnvironment: dispatchEnvironment
})(MainPagePortal)
