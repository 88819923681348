import React, {Component} from 'react';
import './Slider.scss'
import {Icon, ICON_SIZE} from "../icon/Icon";
import {viewportUtils} from "../../../vendor/utils/ViewportUtils";
import classNames from 'classnames';
import smoothscroll from '../../../vendor/polyfill/smoothscroll.js';
import PropTypes from "prop-types";
import {mod} from "../../../vendor/utils/Utils";

class Slider extends Component {
	constructor(props) {
		super(props);

		this.scrollRef = React.createRef();
		this.scrollContentRef = React.createRef();

		this.prevBtnRef = React.createRef();
		this.nextBtnRef = React.createRef();
		this.onResize = this.onResize.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onPrevClick = this.onPrevClick.bind(this);
		this.onNextClick = this.onNextClick.bind(this);

		smoothscroll.polyfill();

		// Set of timeout IDs
		this.timeouts = {
			resize: null,
			scroll: null,
			autoplay: null,
			uiUpdate: null
		};

		this.state = {
			showPrevBtn: false,
			showNextBtn: false,
			activeSlideIndex: props.startAt,
			isVisible: false
		};
	}

	/**
	 * Get number of slides
	 * @returns {number}
	 */
	getLength() {
		return this.getSlides().length;
	}

	/**
	 * Get currently visible slide
	 * @param    {string}    direction - valid values: 'prev', 'next'
	 * @returns {number}    bestIndex
	 */
	getIndex(direction = 'prev') {
		let bestPercent = 0;
		let bestIndex = 0;

		// HACK: a node list looks like an array but it isn't. So it must convert into an array to use array functions
		// like reverse()
		let slides = Array.from(this.getSlides());

		if (direction === 'next') slides = slides.reverse();

		for (let i = 0; i < slides.length; i++) {

			const slide = slides[i];

			const {
				percent,
				fullInViewport
			} = viewportUtils.percentInViewportX(slide, this.scrollContainer, this.getButtonOffset().totalWidth);

			if (fullInViewport) {
				bestIndex = i;
				break;
			}

			if (percent > bestPercent) {
				bestPercent = percent;
				bestIndex = i;
			}
		}

		if (direction === 'next') {
			bestIndex = (slides.length - 1) - bestIndex;
		}
		return bestIndex;
	}

	/**
	 * Get index in bounds of slides available
	 * @param index {number}
	 * @returns {number}
	 */
	getIndexInBounds(index) {

		if (this.props.loop) {
			// Keep in bounds
			// HACK: operator % in javascript is a remainder operator not a classic modulo operator, so it must be used a
			// self written mod function
			index = mod(index, this.getLength());
		} else {
			if (index < 0) {
				index = 0;
			} else if (index >= this.getLength()) {
				index = this.getLength() - 1;
			}
		}


		return index;
	}

	/**
	 * Get list of all slides
	 * @returns {NodeListOf<Element>}
	 */
	getSlides() {
		return this.scrollContentRef.current ? this.scrollContentRef.current.childNodes : [];
	}

	/**
	 * Get slide by index
	 * @param    {number}    index
	 * @returns {Node|Element}
	 */
	getSlide(index) {
		const slides = this.getSlides();
		return (slides.length > 0) && slides[index];
	}

	/**
	 * Check scroll content is scrollable
	 * @return {boolean}
	 */
	isScrollable() {
		const scrollWidth = this.scrollContainer.scrollWidth;
		const clientWidth = this.scrollContainer.offsetWidth;
		// Nothing to scroll or scroll width is smaller
		return this.getLength() > 1 && (scrollWidth > clientWidth);
	}


	/**
	 * Update active slide and navigation
	 * @param    {number}    index to set active
	 * @private
	 */
	updateSliderUI(index) {

		if (this.scrollRef.current) {

			// Show both
			let showPrevBtn = true;
			let showNextBtn = true;

			if (this.props.nav) {
				if (this.scrollRef.current && this.scrollContentRef.current) {
					const {nextBtnWidth} = this.getButtonOffset();

					const scrollLeft = this.scrollRef.current.scrollLeft;
					const scrollRight = this.scrollContentRef.current.offsetWidth - this.scrollRef.current.offsetWidth - this.scrollRef.current.scrollLeft + nextBtnWidth;

					if (!this.isScrollable()) {
						showPrevBtn = false;
						showNextBtn = false;
					} else if (scrollLeft !== 0 && scrollRight !== 0) {
						showPrevBtn = true;
						showNextBtn = true;
					} else if (scrollLeft === 0) {
						showPrevBtn = false;
						showNextBtn = true;
						index = 0;
					} else if (scrollRight === 0) {
						showPrevBtn = true;
						showNextBtn = false;
						index = this.getSlides().length - 1
					}
				}
			}


			this.setState({
				showPrevBtn: showPrevBtn,
				showNextBtn: showNextBtn,
				activeSlideIndex: index
			});
		}
	}

	/** Go to specific item
	 *
	 * @param    {number}    index
	 * @param    {boolean}    smoothScroll
	 */
	to(index, smoothScroll = true) {

		let indexNew = this.getIndexInBounds(index);

		// Get scroll offset
		const slide = this.getSlide(indexNew);

		// Left side of viewbox
		let scrollTarget = slide.offsetLeft - this.getButtonOffset().prevBtnWidth;

		if (this.props.centerMode) {
			const centerScrollTarget = scrollTarget - ((this.scrollContainer.clientWidth - slide.clientWidth) / 2);
			// Prevent negative scroll location
			scrollTarget = centerScrollTarget < 0 ? scrollTarget : centerScrollTarget;
		}

		this.scrollContainer.scrollTo({
			left: scrollTarget,
			behavior: smoothScroll ? 'smooth' : 'auto'
		});

		// Delay slider ui update
		this.timeouts.uiUpdate = setTimeout(() => {
			this.updateSliderUI(indexNew);
		}, 50);
	}

	/**
	 * Go to next slide
	 */
	next() {
		// Stop auto play on user interaction
		this.pause();
		const targetIndex = this.getIndex('next') + 1;

		this.to(targetIndex);
	}

	/**
	 * Go to prev slide
	 */
	prev() {
		// Stop auto play on user interaction
		this.pause();
		const targetIndex = this.getIndex() - 1;
		this.to(targetIndex);
	}

	/**
	 * Start auto slide playback
	 */
	play() {
		const callback = () => {
			this.to(this.state.activeSlideIndex + 1);
			this.timeouts.autoplay = setTimeout(callback.bind(this), this.props.autoplaySpeed);
		};
		this.timeouts.autoplay = setTimeout(callback.bind(this), this.props.autoplaySpeed);
	}

	/**
	 * Pause auto slide playback
	 */
	pause() {
		clearTimeout(this.timeouts.autoplay);
	}


	/**
	 * make sure that there is enough space. the buttons should not overlap the items
	 * used as inlineStyle on slideContainer
	 * @returns {{paddingRight: string, marginLeft: string}}
	 */

	/**
	 * make sure that there is enough space. the buttons should not overlap the items
	 * used as inlineStyle on slideContainer or a key/value object
	 *
	 * @param asInlineStyle
	 * @returns {{nextBtnWidth: number, prevBtnWidth: number, totalWidth: number}|{paddingRight: string, marginLeft: string}}
	 */
	getButtonOffset() {
		let prevBtnWidth = 0;
		let nextBtnWidth = 0;

		if (this.props.indentButtons) {
			if (this.prevBtnRef.current && this.nextBtnRef.current) {
				prevBtnWidth = this.prevBtnRef.current.offsetWidth;
				nextBtnWidth = this.nextBtnRef.current.offsetWidth;
			}
		}

		return {
			prevBtnWidth: prevBtnWidth,
			nextBtnWidth: nextBtnWidth,
			totalWidth: prevBtnWidth + nextBtnWidth
		}
	}

	getIndentInlineStyle() {

		const offsetObj = this.getButtonOffset();

		return {
			marginLeft: offsetObj.prevBtnWidth + 'px',
			paddingRight: offsetObj.nextBtnWidth + 'px',
			width: 'auto'
		}
	}


	/*****************************************************************************
	 ** EVENT HANDLERS
	 ****************************************************************************/

	/**
	 * Next button click event listener
	 */
	onNextClick() {
		this.next();
	}

	/**
	 * Prev button click event listener
	 */
	onPrevClick() {
		this.prev();
	}

	/**
	 * Slide click event listener
	 * @param {number}    index
	 */
	onSlideClick(index) {
		if (this.props.focusOnSelect && this.state.activeSlideIndex !== index) {
			this.to(index);
		}
	}

	/**
	 * Bullet click event listener
	 * @param {number} index
	 */
	onDotClick(index) {
		this.to(index);
	}

	/**
	 * Scroll event listener
	 */
	onScroll() {
		clearTimeout(this.timeouts.scroll);
		// Focus on slide after scroll complete
		this.timeouts.scroll = setTimeout(() => {
			this.onScrollComplete();
		}, 66);
	}

	/**
	 * Window resize event handler
	 */
	onResize() {
		this.timeouts.resize = setTimeout(() => {
			this.to(this.state.activeSlideIndex);
		}, 150);
	}

	/**
	 * User pressed a key on the keyboard
	 * @param    {Event}    e
	 */
	onKeyDown(e) {
		// left arrow key, show previous image
		if (e.which === 37) {
			this.onPrevClick();
		}
		// right arrow key, show next image
		else if (e.which === 39) {
			this.onNextClick();
		}
	}

	/**
	 * Scroll complete handler
	 */
	onScrollComplete() {
		const index = this.getIndex();
		// Stop auto play on user interaction
		this.pause();
		this.updateSliderUI(index);
	}


	componentDidMount() {
		this.awaitExpansionInterval = setInterval(() => {
			this.scrollContainer = this.scrollRef.current;

			if (this.scrollContainer.scrollWidth > 0) {
				this.to(this.state.activeSlideIndex, false);
				clearInterval(this.awaitExpansionInterval);
			}

			if (this.props.autoplay) {
				this.play();
			}

			this.setState({
				isVisible: true,
			});
		}, 150);

		window.addEventListener('resize', this.onResize);
		this.props.keyControl && window.addEventListener('keydown', this.onKeyDown);
	}

	componentWillUnmount() {
		// Remove all event listener
		window.removeEventListener('resize', this.onResize);
		window.removeEventListener('keydown', this.onKeyDown);
		// Clear all intervals and timeouts
		clearInterval(this.awaitExpansionInterval);
		clearTimeout(this.timeouts.scroll);
		clearTimeout(this.timeouts.autoplay);
		clearTimeout(this.timeouts.resize);
		clearTimeout(this.timeouts.uiUpdate);
	}


	render() {
		//HACK: ensure children convert to an array although is only one child pass through
		const children = React.Children.toArray(this.props.children);
		const classes = classNames('slider', this.props.additionalClasses, {'slider--hidden': !this.state.isVisible});

		//scrollPadding: '0 0 0 ' + offsetObj.prevBtnWidth + 'px'
		let wrapperStyle = {};

		if (this.props.indentButtons && this.props.snapSlides && !this.props.centerMode) {
			wrapperStyle = {scrollPadding: '0 0 0 ' + this.getButtonOffset().prevBtnWidth + 'px'};
		}
		const sliderWrapperClasses = classNames(
			'slider__wrapper',
			{
				' slider__wrapper--scrollsnap': (this.props.snapSlides)
			}
		);

		const contentStyle = this.props.indentButtons ? this.getIndentInlineStyle() : {};
		const sliderContentClasses = classNames(
			'slider__content',
			{
				'slider__content--snap-center': (this.props.centerMode && this.props.snapSlides),
				'slider__content--snap-left': (!this.props.centerMode && this.props.snapSlides)
			}
		);

		return (
			<div className={classes}>
				<div
					className={sliderWrapperClasses}
					ref={this.scrollRef}
					onScroll={(e) => this.onScroll(e)}
					style={wrapperStyle}
				>
					<div
						className={sliderContentClasses}
						ref={this.scrollContentRef}
						style={contentStyle}
					>
						{children.map((child, index) => {
							return (
								<div className={this.props.classes.slide} onClick={() => {
									this.onSlideClick(index)
								}} key={`slide-${index}`}>
									{child}
								</div>);
						})}
					</div>
				</div>
				{this.props.dots &&
				<div className={classNames('slider__dots', this.props.classes.dots)}>
					{children.map((child, index) => {
						return (
							<div
								className={classNames('slider__dot', this.props.classes.dot, {'slider__dot--active': index === this.state.activeSlideIndex})}
								onClick={() => this.onDotClick(index)} key={`dot-${index}`}/>)
					})}
				</div>}
				{this.props.nav &&
				<div className={classNames('slider__nav', this.props.classes.nav)}>
					<div className={classNames(
						'slider__nav-btn',
						{'slider__nav-btn--hidden': !this.state.showPrevBtn},
						'slider__nav-btn-prev ',
						this.props.classes.navBtn)}
							 ref={this.prevBtnRef}
							 onClick={this.onPrevClick}>
						{this.props.prevBtn || <Icon name={'arrow-dots-left'} size={ICON_SIZE.XL}/>}
					</div>
					<div className={classNames(
						'slider__nav-btn',
						{'slider__nav-btn--hidden': !this.state.showNextBtn},
						'slider__nav-btn-next',
						this.props.classes.navBtn)}
							 ref={this.nextBtnRef}
							 onClick={this.onNextClick}>
						{this.props.nextBtn || <Icon name={'arrow-dots-right'} size={ICON_SIZE.XL}/>}
					</div>
				</div>}
			</div>
		)
	}
}

Slider
	.propTypes = {
	/**
	 * index where the slider should start
	 * @default 0
	 */
	startAt: PropTypes.number,

	/**
	 * enable slider dots
	 * @default false
	 */
	dots: PropTypes.bool,

	/**
	 * enable slider navigation
	 * @default true
	 */
	nav: PropTypes.bool,

	/**
	 * focus to most visible slide
	 * @default true
	 */
	focusOnScroll: PropTypes.bool,

	/**
	 * use buttonIndent
	 * @default false
	 */
	indentButtons: PropTypes.bool,
	/**
	 * snap slide to center
	 * @default: false
	 */
	snapToCenter: PropTypes.bool,

	/**
	 * focus on slide on click
	 * @default true
	 */
	focusOnSelect: PropTypes.bool,

	/**
	 * center slide in slider if it is possible
	 * @default true
	 */
	centerMode: PropTypes.bool,

	/**
	 * loop slider
	 * @default false
	 */
	loop: PropTypes.bool,

	/**
	 * enable key control for navigation
	 * @default false
	 */
	keyControl: PropTypes.bool,

	/**
	 * enable autoplay
	 * @default false
	 */
	autoplay: PropTypes.bool,

	/**
	 * set autoplay speed of slides
	 * @default 3000
	 */
	autoplaySpeed: PropTypes.number,

	/**
	 * object of classes which can be set
	 */
	classes: PropTypes.shape({
		slide: PropTypes.string,
		nav: PropTypes.string,
		navBtn: PropTypes.string,
		dots: PropTypes.string,
		dot: PropTypes.string
	}),

	/**
	 * element to overwrite the default prev arrow
	 */
	prevBtn: PropTypes.element,

	/**
	 * element to overwrite the default prev arrow
	 */
	nextBtn: PropTypes.element
};

Slider
	.defaultProps = {
	startAt: 0,
	dots: false,
	nav: true,
	centerMode: true,
	focusOnScroll: true,
	snapToCenter: true,
	indentButtons: false,
	focusOnSelect: true,
	keyControl: false,
	loop: false,
	autoplay: false,
	autoplaySpeed: 3000,
	classes: {}
};

export {
	Slider
		as
			default
	,
	Slider
}
