import {saveOffer} from "./saveOfferAction";
import {deepCopyPureObject} from "../../../vendor/utils/Utils";

export const ADD_ITEM = 'ADD_ITEM';
export const ADD_ROUTE_ITEM = 'ADD_ROUTE_ITEM';
export const UPDATE_NOTICE = 'UPDATE_NOTICE';
export const UPDATE_ITEMMAP = 'UPDATE_ITEMMAP';

export const dispatchAddItems = (items) => ({
	type: ADD_ITEM,
	items,
});

export const dispatchAddRouteItems = (items) => ({
	type: ADD_ROUTE_ITEM,
	items,
});

export const dispatchUpdateItemMap = (data) => ({
	type:UPDATE_ITEMMAP,
	data
});

export const dispatchUpdateNotice = (id, noticeValue) => ({
	type: UPDATE_NOTICE,
	id,
	noticeValue
});

export const dispatchUpdateAddOn = (addonData) => {
	return (dispatch, getState) => {
		const state = getState();
		const itemMap = deepCopyPureObject(state.itemManagement.itemMap);
		const changedItemMap = updateAddOn(itemMap, addonData);
		const addOnItemIsActive = !!itemMap[addonData.itemId].active;

		if(state.globalStateManagement.permissions?.bookable && state.globalStateManagement.isMainClient && addOnItemIsActive) {
			return dispatch(saveOffer(changedItemMap));
		}

		return dispatch(dispatchUpdateItemMap(changedItemMap));
	}
};

export const dispatchActivateItem = (id) => {
	return (dispatch, getState) => {
		const state = getState();
		const itemMap = deepCopyPureObject(state.itemManagement.itemMap);
		const changedItemMap = activateItem(itemMap, id);

		return checkSaveOffer(dispatch,state,changedItemMap);
	}
};

export const dispatchUpdateOption = (isSelected, value) => {
	return async (dispatch, getState) => {
		const state = getState();
		const itemMap = deepCopyPureObject(state.itemManagement.itemMap);
		const changedItemMap =  updateOption(itemMap, isSelected, value.itemId, value);

		if(state.globalStateManagement.permissions?.bookable && state.globalStateManagement.isMainClient) {
			dispatch(saveOffer(changedItemMap));
			return;
		}
		return dispatch(dispatchUpdateItemMap(changedItemMap));
	}
};

const checkSaveOffer = (dispatch, state, itemMap) => {
	if(state.globalStateManagement.permissions?.bookable && state.globalStateManagement.isMainClient) {
		return dispatch(saveOffer(itemMap));
	}

	return dispatch(dispatchUpdateItemMap(itemMap));
}


const activateItem = (itemMap, id) => {
	const item = itemMap[id];
	if (item.alternatives) {
		item.alternatives.forEach(alternativeId => {
			const alternative = itemMap[alternativeId];
			alternative.active = alternative.id === id;
		})
	}
	Object.keys(itemMap).forEach(key => {
		const element = itemMap[key];
		if (element.parent_id) {
			element.active = itemMap[element.parent_id].active
		}
	})
	return itemMap;
}

const updateAddOn = (itemMap, data) => {
	const item = itemMap[data.itemId];
	const addonTab = item.tabs.find(tab => tab.type === 'addons');
	const addon = addonTab.content.find(addon => addon.id === data.addOnId);
	addon.selected = data.value;
	return itemMap;
}

const updateOption = (itemMap, selected, itemId, value) => {
	const item = itemMap[itemId];
	item.date = selected ? value.date : '' // overwrite selected date from optionList
	item.time = selected ? value.time : '' // overwrite selected time from optionList
	item.selected = selected;
	return itemMap;
}

