import React, {Component} from 'react';
import './MainPage.scss'
import TravelCountdown from "../components/travelcountdown/TravelCountdown";
import Footer from "../components/footer/Footer";
import Stage from "../components/stage/Stage";
import {Preloader} from "../components/preloader/Preloader";
import {Redirect} from "react-router-dom";
import {StorageManager, storageManager} from "../services/StorageManager";
import {Consent} from "../components/consent/Consent";
import classNames from "classnames";
import {BREAKPOINTS} from "../../vendor/utils/BreakpointUtils";
import {connect} from "react-redux";
import {dispatchIsDesktop, dispatchEnvironment} from "../redux/actions/globalStateManagementAction";
import {
	dispatchFetchData,
	FETCH_TYPE_DIGITAL_OFFER_CONTENT,
	FETCH_TYPE_DIGITAL_OFFER_PARTIAL,
} from "../redux/actions/fetchAction";
import DigitalOfferContent from "./digitaloffer/DigitalOfferContent";
import DigitalOfferOptionalsContent from "./digitalOfferOptionals/DigitalOfferOptionalsContent";
import HeaderDigitalOffer from "../components/header/digitalOffer/HeaderDigitalOffer";
import {NavigationItem} from "../components/navigation/NavigationItem";
import {Navigation} from "../components/navigation/Navigation";
import DigitalOfferRoutesContent from "./offerRoutes/RoutesContent";
import PropTypes from "prop-types";
import NotificationMessage from "../components/notification/NotificationMessage";
import {getDateByEnvironment} from "../redux/selectors";
import {dispatchSaveOffer} from "../redux/actions/saveOfferAction";
import {viewportUtils} from "../../vendor/utils/ViewportUtils";
import {isDevEnvironment} from "../../vendor/utils/Utils";
import DebugBar, {DEBUG} from "../debug/DebugBar";

class MainPageDigitalOffer extends Component {

	constructor(props) {
		super(props);
		this.widthChange = this.widthChange.bind(this);
		this.mediaQuery = window.matchMedia(`print, (min-width: ${BREAKPOINTS.desktop}px)`);
		this.mediaQuery.addListener(this.widthChange);
		props.dispatchIsDesktop(this.mediaQuery.matches);
		props.dispatchEnvironment('digitalOffer');

		this.state = {
			selectedId: (this.props.match.params && this.props.match.params.tab) || 'start',
		};

		/**
		 * setCookie with queryParam from CPV
		 */
		const token = new URL(window.location.href).searchParams.get("token");
		if (token) {
			storageManager.authToken = token;
			window.history.replaceState(null, null, window.location.pathname);
		}
	}

	/**
	 * handles widthChangeEvents
	 */
	widthChange() {
		this.props.dispatchIsDesktop(this.mediaQuery.matches);
	}

	/**
	 * check if routeChange should cause content change
	 * @param prevProps {object}
	 * @param prevState {object}
	 * @param snapshot
	 */
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.isAuthenticated !== prevProps.isAuthenticated && this.props.loadingComplete) {
			//get the fetchTypes to call the correct endpoint
			this.props.dispatchFetchPartialData();
			this.props.dispatchSaveOffer(this.props.itemMap);
		}

		if (this.props.hintId && (prevProps.hintId !== this.props.hintId)) {
			this.updateHistory(this.props.offerToken, this.props.hintId);
		}

		if (this.props.revision.offerNumber !== prevProps.revision.offerNumber) {
			storageManager.offerNumber = this.props.revision.offerNumber;
			storageManager.offerToken = this.props.revision.offerToken;
			this.props.dispatchFetchData();
			this.updateHistory(this.props.revision.offerToken);
		}

		const tab = this.props.match?.params?.tab;

		if (tab && (prevState.selectedId !== tab)) {
			this.setState({
				selectedId: tab,
			});
		}
	}

	componentDidMount() {
		const initialOfferNumber = this.props.match?.params?.offertoken;
		storageManager.offerToken = initialOfferNumber;
		this.props.dispatchFetchData();
	}




	/**
	 * update history
	 * @param offerToken
	 * @param hintId
	 */
	updateHistory(offerToken = this.props.offerToken, hintId = this.props.match.params.hintId) {
		const path = this.props.match.path.split(':')[0];
		if (this.props.hasRouteHints) {
			this.props.history.push(`${path}${offerToken}/${(this.props.match.params && this.props.match.params.tab)}/${(hintId || '')}`);
		} else {
			this.props.history.push(`${path}${offerToken}/${(this.props.match.params && this.props.match.params.tab)}`);
		}
	}

	/**
	 * append content to radioGroupContent
	 * @param uid {string} name of tabcontent
	 * @param updateId {string}    id to destinguish if content needs to fetch new data
	 * @return {*}
	 */
	contentFactory(uid) {

		let content = null;
		switch (uid) {
			case 'start':
				content = <DigitalOfferContent/>;
				break;
			case 'tips':
				content = <DigitalOfferOptionalsContent/>;
				break;
			case 'routes':
				content = <DigitalOfferRoutesContent match={this.props.match}/>;
				break;
			default:
				break;
		}

		return content;
	}


	render() {

		if (!this.props.loadingComplete) {
			return <Preloader type={'full-screen'}/>;
		}

		const content = this.contentFactory(this.state.selectedId);

		if (!content) {
			return <Redirect to={{pathname: "/404 "}}/>
		}

		if (!this.props.offerNumber) {
			return <Redirect to='/keine-reisen'/>
		}

		if(!this.props.match?.params?.tab){
			return <Redirect to={`${this.props.match.url}/start`}/>
		}

		// if routeHint is false and tab is "routes" fallback to "start"
		if (!this.props.hasRouteHints && this.props.match.params.tab === 'routes') {
			return <Redirect to={`/digitaloffer/${this.props.offerToken}/start`}/>
		}

		const classes = classNames(
			'main-page',
			this.props.additionalClasses
		);

		const showDebugBar = isDevEnvironment() && process.env.REACT_APP_GLOBAL_DEBUG === "true" && process.env.REACT_APP_GLOBAL_DISABLE_MOCK === "false";

		return (
			<div className={classes}>
				<div className={'main-page__header-wrapper'}>
					{showDebugBar && <DebugBar show={[DEBUG.TRACKING]}/>}
					<HeaderDigitalOffer isAuthenticated={this.props.isAuthenticated}
										stickToTop={false}
					/>

				</div>
				<Stage withContent={true} join={false} image={this.props.stageImage}/>

				{(this.props.hasOptionals || this.props.hasRouteHints) && <Navigation>
					<NavigationItem uid='start'
									linkPath={`/digitaloffer/${storageManager.offerToken}/start`}
									label='Ihr Digitales Angebot'
									selectedId={this.state.selectedId}
									onCLick={viewportUtils.scrollToContentTop}
					/>

					{this.props.hasOptionals && <NavigationItem uid='tips'
																linkPath={`/digitaloffer/${storageManager.offerToken}/tips`}
																label='Ausflüge'
																selectedId={this.state.selectedId}
																onCLick={viewportUtils.scrollToContentTop}
					/>}
					{this.props.hasRouteHints &&
					<NavigationItem uid='routes'
									linkPath={`/digitaloffer/${storageManager.offerToken}/routes`}
									label='Routenvorschläge'
									selectedId={this.state.selectedId}
									onCLick={viewportUtils.scrollToContentTop}
					/>
					}
				</Navigation>}

				<main className='main-page__content'>
					{content}

					<NotificationMessage/>

					{this.props.isBookable && <TravelCountdown additionalClasses={'main-page__countdown'}
															   startDate={this.props.journeyStart}/>
					}
				</main>

				<Footer/>

				{!StorageManager.getCookie('acceptedCookies') &&
				<Consent brandName={'CANUSA'}
						 brandUrl={'https://www.canusa.de'}
				/>
				}
			</div>
		)
	}
}

MainPageDigitalOffer.propTypes = {
	/**
	 * @example: '2019-06-29'
	 */
	journeyStart: PropTypes.string,

	/**
	 * @example: '2019-07-3'
	 */
	journeyEnd: PropTypes.string,

	/**
	 * offernumber e.g. HAM-a1b2c3d4-ABC
	 */
	offerNumber: PropTypes.string,
	/**
	 * @example: 'MTRQTHAM-1339657.3-BSC'
	 */
	offerToken: PropTypes.string,

	/**
	 * is logged in successfully
	 */
	isAuthenticated: PropTypes.bool,

	/**
	 * List of revisions
	 */
	revisionList: PropTypes.arrayOf(PropTypes.shape({

		/**
		 * @example: 'HAM-1339657-BSC'
		 */
		offerNumber: PropTypes.string,

		/**
		 * @example: '2019-06-26'
		 */
		bookingDate: PropTypes.string,

		/**
		 * @example: '2019-06-29'
		 */
		journeyStart: PropTypes.string,

		/**
		 * @example: '2019-07-3'
		 */
		journeyEnd: PropTypes.string,

	})),
	/**
	 * revision Number
	 */
	revision: PropTypes.object,
	/**
	 * has offer route hints
	 */
	hasRouteHints: PropTypes.bool,
	/**
	 * id of routehint
	 * @example: 6500
	 */
	hintId: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
	return {
		journeyStart: getDateByEnvironment(state.globalStateManagement, 'journeyStart'),
		journeyEnd: getDateByEnvironment(state.globalStateManagement, 'journeyStart'),
		stageImage: state.globalStateManagement.stageImage,
		isMainClient: state.globalStateManagement.isMainClient,
		offerNumber: state.globalStateManagement.offer.offerNumber,
		offerToken: state.globalStateManagement.offer.offerToken,
		isAuthenticated: state.globalStateManagement.isAuthenticated,
		isBookable: state.globalStateManagement.permissions?.bookable,
		revisionList: state.revisionListManagement.revisionList,
		revision: state.globalStateManagement.revision,
		hasRouteHints: state.routeHintManagement.hasRouteHints,
		hintId: state.routeHintManagement.hintId,
		loadingComplete: state.offerContentManagement.loadingComplete,
		hasOptionals: state.optionalsManagement.optionalItems && state.optionalsManagement.optionalItems.length > 0,
		itemMap: state.itemManagement.itemMap,
		itemMapJson: JSON.stringify(state.itemManagement.itemMap),
	}
};

export default connect(mapStateToProps, {
	dispatchIsDesktop,
	dispatchFetchData: () => dispatchFetchData(FETCH_TYPE_DIGITAL_OFFER_CONTENT),
	dispatchFetchPartialData: () => dispatchFetchData(FETCH_TYPE_DIGITAL_OFFER_PARTIAL),
	dispatchSaveOffer: (itemMap) => dispatchSaveOffer(itemMap),
	dispatchEnvironment
})(MainPageDigitalOffer)
