import React, {Component} from 'react';
import './ErrorPage.scss'
import withDataProvider from "../../hoc/WithDataprovider";
import Stage from "../../components/stage/Stage";
import Footer from "../../components/footer/Footer";
import {Header} from "../../components/header/Header";
import {ErrorMessage} from "../../components/error/ErrorMessage"
import classNames from "classnames";

const StageWithDataProvider = withDataProvider(Stage, {useMock: false, endpoint: 'portal/stage', value: {topic: 'not_found'}});

class ErrorPage extends Component {

	componentDidMount() {
		document.title = 'CANUSA Kundenportal - 404';
	}

	render() {

		const classes = classNames(
			'error-page',
			'page-full-height'
		);


		return (
			<div className={classes}>

				<Header />

				<StageWithDataProvider
					withContent={false}
					join={true}
				/>

				<ErrorMessage additionalClasses={'centered-content error-page__message'} type={'404'}/>

				<Footer/>
			</div>
		);
	}
}

export {
	ErrorPage as default,
	ErrorPage
}
