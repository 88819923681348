import React, {useEffect, useState} from 'react';
import './CheckoutContact.scss';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormValidator} from "../../form/FormValidator";
import {createRenter} from "../../../../vendor/utils/ParticipantsUtils";
import CheckoutNavigation from "../CheckoutNavigation";
import Icon from "../../icon/Icon";
import Select from "../../select/Select";
import {formErrorMessages} from "../../login/FormUtils";
import {dispatchFetchData, FETCH_TYPE_DIGITAL_OFFER_RENTERS} from "../../../redux/actions/fetchAction";
import {typeToIconMap} from "../../../../vendor/utils/Mappings";
import {toGermanDateStr} from "../../../../vendor/utils/DateUtils";
import {dispatchUpdateUserProfile} from "../../../redux/actions/userProfileAction";
import {viewportUtils} from "../../../../vendor/utils/ViewportUtils";
import ErrorMessage from "../../error/ErrorMessage";
import ContactBoxContainer from "../../contactbox/ContactBoxContainer";

function CheckoutContact(props) {

  const [showFormErrors, setShowFormErrors] = useState(false);

  const {dispatchFetchData} = props;
  useEffect(() => {
    dispatchFetchData();
  }, [dispatchFetchData]);

  function handleSubmit({form, isValid, firstErrorSelector}) {
    if (isValid) {
      props.dispatchUpdateUserProfile(form)
    } else {
      viewportUtils.scrollIntoView(firstErrorSelector, '.participants-form__content', '#checkout-modal');
      setShowFormErrors(true);
    }
  }

  function formatRenterslistForSelect(list) {
    return list.map((item) => {
      return {value: item.id, label: item.firstname + ' ' + item.lastname}
    })
  }

  function renderRentersItem(key, icon, date, label, rentersList, selectedRenterId) {
    return (
      <div className="renters__item" key={key}>

        <div className="renters__title">
          <div className="renters__date-wrapper">
            <Icon name={'car'}/>
            <div className="renters__date">{date}</div>
          </div>
          <div className="renters__label">{label}</div>
        </div>

        <div className="renters__select">
          <Select label={'Fahrer'}
                  options={formatRenterslistForSelect(rentersList)}
                  name={`offerContact[rented-item-${key}]`}
                  fullWidth={true}
                  required={true}
                  showError={showFormErrors}
                  initialValue={selectedRenterId}
                  onChange={() => {
                  }}
                  errorMessage={formErrorMessages['genderError']}
                  tooltipDirection={'top'}
                  parentContainerSelector={'#' + props.parentId}
                  additionalClasses={'renters__select'}
          />
        </div>
      </div>
    )
  }

  function createRentersList(rentersList) {
    return (
      <div className={'renters__box'}>
        {rentersList.map((element, index) => {
          return renderRentersItem(
            element.item.id,
            typeToIconMap[element.item.type],
            toGermanDateStr(element.item.date),
            element.item.displayTitle,
            element.participants,
            element.renter.id
          )
        })}
      </div>
    );
  }

  function createRenterContent(rentersList) {
    if (!rentersList) {
      return null;
    }

    return (<div className="renters">
      <div className="renters__header">
        <div className="h4">Zuweisung Mieter/Kreditkarteninhaber</div>
        <p>Bitte wählen Sie hier den Mieter- und Kreditkarteninhaber aus, der das Fahrzeug
          übernehmen wird.</p>
      </div>
      {createRentersList(rentersList)}
    </div>);
  }


  return (
    <>
      <h1 className="tt-none ts-bold">Adressdaten des Buchenden</h1>
      <p>Bitte geben Sie hier die Adressdaten der buchenden Person an.</p>
      <FormValidator additionalClasses="checkout-contact-wrapper__form"
                     onSubmit={handleSubmit}
                     render={({changeHandler, formIsValid}) => (
                       <>
                         <div className={'participants-form__content'}>
                           {props.participantsList && createRenter(props.offerContact, changeHandler, props.parentId, showFormErrors)}
                         </div>

                         {createRenterContent(props.rentersList)}

                         {props.hasLoadingError && <ErrorMessage>
                             <h2 className={'error-message__h2'}><Icon name={'warning'} additionalClasses={'error-message__icon'}/>Es ist ein Fehler aufgetreten</h2>
                             <div className={'error-message__text-block'}>
                               <p>Leider haben wir gerade mit technischen Schwierigkeiten zu kämpfen und können Ihnen
                                 unseren Service derzeit nicht zur Verfügung stellen.</p>
                               <p>Wir arbeiten bereits an einer Lösung.
                                 Sollte das Problem weiterhin bestehen, rufen Sie mich bitte an.</p>
                               <ContactBoxContainer appearance={'modal'}/>
                             </div>
                         </ErrorMessage>}

                         <CheckoutNavigation checkoutStep={props.checkoutStep} isLoading={props.isLoading}/>
                       </>
                     )}
      />
    </>
  )
}

CheckoutContact.propTypes = {
  /**
   * OPTIONAL: add additional classes for this component here.
   * Use this prop for special css-classes, which are not defined by default.*
   */
  additionalClasses: PropTypes.string
};

CheckoutContact.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
  return {
    participantsList: state.participantManagement.list,
    offerContact: state.userProfileManagement,
    isLoading: state.userProfileManagement.isLoading,
    hasLoadingError: state.userProfileManagement.hasLoadingError,
    rentersList: state.rentersManagement.list,
  }
};

export default connect(mapStateToProps, {
  dispatchFetchData: () => dispatchFetchData(FETCH_TYPE_DIGITAL_OFFER_RENTERS),
  dispatchUpdateUserProfile: (form, isCheckout) => dispatchUpdateUserProfile(form, true)
})(CheckoutContact);
