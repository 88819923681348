import React, {Component} from 'react';
import './OfferPrintSelect.scss'
import {BUTTON_APPEARANCE, BUTTON_WIDTH, Button} from "../button/Button";
import {Checkbox} from "../checkbox/Checkbox";
import {Modal} from "../modal/Modal";
import PropTypes from "prop-types";
import {
  checkIfCurrentRevision,
  getConvertedItem,
  getNumbersInLetters
} from "../../../vendor/utils/DigitalOfferContentUtils";
import {connect} from "react-redux";
import Icon from "../icon/Icon";

class OfferPrintSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showOfferModal: false
    };
    this.onClickPrint = this.onClickPrint.bind(this);
    this.showOfferModal = this.showOfferModal.bind(this);

  }

  /**
   * toggle Modal visibility
   * @param show
   */
  showOfferModal(show) {
    this.setState({
      showOfferModal: show
    });

    this.isCurrentRevision = checkIfCurrentRevision(this.props.offerNumber, this.props.revisionList)

    document.getElementsByTagName('html')[0].classList.remove('hide-print-modal');
    document.getElementsByTagName('html')[0].classList.remove('print-content');
    document.getElementsByTagName('html')[0].classList.remove('print-routes');
  }

  /**
   * open printDialog
   * @param modalCloseHandler
   */
  onClickPrint(modalCloseHandler) {
    document.getElementsByTagName('html')[0].classList.add('hide-print-modal');
    modalCloseHandler();
    window.print();
  }

  /**
   * toggles visibility of content for print view
   * @param isVisible
   */
  contentChangeHandler(isVisible) {
    if (isVisible) {
      document.getElementsByTagName('html')[0].classList.add('print-content');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('print-content');
    }
  }

  /**
   * toggles visibility of routes for print view
   * @param isVisible
   */
  routeDescriptionChangeHandler(isVisible) {
    if (isVisible) {
      document.getElementsByTagName('html')[0].classList.add('print-routes');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('print-routes');
    }
  }

  /**
   * create map for different contentType in offerItems
   * @param offerItems
   *
   * @returns {Object}
   */
  getContentTypeMap(offerItems, itemMap) {

    let checkList = {};
    offerItems.forEach((contentItem) => {
      if(!Array.isArray(contentItem.items))return null;
      contentItem.items.forEach((tempItem) => {
        const {item} = getConvertedItem(itemMap, tempItem);
        if (item?.contentType) {
          if (!checkList[item.contentType]) {
            checkList[item.contentType] = true;
          }
        }
      })
    });
    return checkList;
  }

  /**
   * checks for the presence of main, extra & route-description
   * @param offerItems
   * @param contentTypeMap
   * @returns {*}
   */
  parseContentInfo(offerItems, contentTypeMap) {
    return (
      <>
        {(contentTypeMap['main'] || contentTypeMap['extra']) &&
        <span className={'offer-print-select__checkbox'}>
					<Checkbox name={'toggle-content'}
                    uid={'toggle-content'}
                    checked={false}
                    changeHandler={this.contentChangeHandler}
          />
					<label htmlFor={'toggle-content'}>Leistungsbeschreibungen</label>
				</span>
        }
        {(contentTypeMap['route-description']) &&
        <span className={'offer-print-select__checkbox'}>
					<Checkbox name={'toggle-route-description'}
                    uid={'toggle-route-description'}
                    checked={false}
                    changeHandler={this.routeDescriptionChangeHandler}
          />
					<label htmlFor={'toggle-route-description'}>Routenbeschreibungen</label>
				</span>
        }
      </>
    )
  }


  renderOfferRevisionHint(revisionList, offerNumber) {

    const currentIndex = revisionList.findIndex((revision) => {
      return revision.offerNumber === offerNumber;
    })

    const deltaToLatestRevision = (revisionList.length - currentIndex) - 1;

    const contentString = deltaToLatestRevision > 1 ?
      `Es gibt bereits ${getNumbersInLetters(deltaToLatestRevision)} aktuellere Versionen dieses Angebots.`
      :
      `Es gibt bereits eine aktuellere Version dieses Angebots.`;


    return (
      <div className={'offer-print-select__offernumber-hint'}>
        <Icon name={'circle-info'} size={'xs'} additionalClasses={'offer-print-select__offernumber-hint-icon'}/>
        <div><b>{contentString}</b></div>
      </div>
    );
  }


  render() {
    if (!this.props.offerItems) {
      return null;
    }

    const contentTypeMap = this.getContentTypeMap(this.props.offerItems, this.props.itemMap);
    const showModal = !(this.props.disableModal && this.props.disableModal);

    return (<>

        <Button onClick={() => this.showOfferModal(showModal)}
                label={this.props.buttonLabel}
                appearance={this.props.buttonAppearance}
                width={this.props.buttonWidth}
                icon={'file-pdf'}
                additionalClasses={'no-print'}
        />

        {this.state.showOfferModal &&
        <Modal onClose={() => this.showOfferModal(false)}
               size={"medium"}
               additionalClasses={'no-print'}
               render={({modalCloseHandler}) => (
                 <div className={'offer-print-select'}>
                   <h2 className={'offer-print-select__headline'}>Angebot konfigurieren</h2>
                   <span className={'offer-print-select__copy'}><p>Drucken Sie sich hier die gewünschten Informationen zu Ihrer Reise aus.
								   Reichen Ihnen die Titel der gebuchten Leistungen, so brauchen Sie nichts weiter auswählen.</p>
								   <p>Möchten Sie auch die Beschreibungen der Leistungen ausdrucken, dann setzen Sie einfach einen Haken bei <i>„Leistungsbeschreibungen“</i>.
                     {contentTypeMap['route-description'] &&
                     <span> Für Tipps auf den Fahrtstrecken wählen Sie den Punkt <i>„Routenbeschreibungen“</i>.</span>}</p>
							   </span>
                   <div className={'offer-print-select__divider dotted'}></div>
                   <div className={'offer-print-select'}>
                     {this.parseContentInfo(this.props.offerItems, contentTypeMap)}
                   </div>
                   <div className={'offer-print-select__divider dotted'}></div>

                   <div className={'offer-print-select__offernumber'}>
                     <div>
                       <b>Buchungsnummer:</b>
                       <div>{this.props.offerNumber}</div>
                     </div>
                     <div>
                       {!this.isCurrentRevision && this.renderOfferRevisionHint(this.props.revisionList, this.props.offerNumber)}
                     </div>
                   </div>
                   <Button additionalClasses={'offer-print-select__submit'}
                           onClick={() => {
                             this.onClickPrint(modalCloseHandler)
                           }}
                           label={'Angebot drucken'}
                           appearance={BUTTON_APPEARANCE.SECONDARY}
                           width={BUTTON_WIDTH.FULL_WIDTH}
                           icon={'file-pdf'}
                   />
                 </div>
               )}
        />
        }
      </>
    )
  }
}

OfferPrintSelect.defaultProps = {
  buttonLabel: 'Angebot drucken',
  buttonAppearance: 'ghost',
  buttonWidth: 'fixed'
};

OfferPrintSelect.propTypes = {

  /**
   * Method to store new offerNumber in localStorage
   * located in MainPage.js
   *
   */
  offerItems: PropTypes.array.isRequired,

  /**
   * Button-Label
   * @default 'Angebot drucken'
   */
  buttonLabel: PropTypes.string,

  /**
   * Button-Width
   * @default 'fixed'
   */
  buttonWidth: PropTypes.oneOf(['fullwidth', 'fit', 'fixed']),

  /**
   * Button-Appearance
   * @default 'ghost'
   */
  buttonAppearance: PropTypes.oneOf(['primary', 'secondary', 'important', 'main-navigation', 'ghost']),

};

OfferPrintSelect.propTypes = {
  offerNumber: PropTypes.string,
  offerItems: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  return {
    offerItems: state.offerContentManagement.offerItems,
    itemMap: state.itemManagement.itemMap,
    offerNumber: state.globalStateManagement.offer.offerNumber,
    revisionList: state.revisionListManagement.revisionList,

  }
};

export default connect(mapStateToProps)(OfferPrintSelect)
