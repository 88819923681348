import React, {useEffect} from 'react';
import LinkPdf from "../link/LinkPdf";
import './Invoice.scss'
import {Preloader} from "../preloader/Preloader";
import {Badge} from "../badge/Badge";
import classNames from 'classnames';
import PropTypes from "prop-types";
import ContactBoxContainer from "../contactbox/ContactBoxContainer";
import {ButtonAnchor} from "../button/ButtonAnchor";
import {removeUnusedStyles} from "../../../vendor/utils/HTMLUtils";
import {ErrorMessage} from "../error/ErrorMessage";
import {connect} from "react-redux";
import {dispatchFetchData, FETCH_TYPE_INVOICE} from "../../redux/actions/fetchAction";
import OfferInfo from "../offerInfo/OfferInfo";

function Invoice ({dispatchFetchData,...props}){



	useEffect(() => {

		dispatchFetchData();

	}, [dispatchFetchData, props.offerNumber]);

	const classes = classNames('invoice-common', 'box', props.additionalClasses);

	if(props.hasLoadingError){
		return (
			<ErrorMessage type={'loadingError'}/>
		)
	} else if (!props.loadingComplete) {
		return (
			<Preloader type={'text'} additionalClasses={classes}/>
		);
	}

	return (
		<>
		<div className={classes}>
			<div className={'invoice-common__state'}>
				<Badge label={props.state.label} type={props.state.level}/>
			</div>
			<div className={'invoice-common__headline h2 ts-handwriting'}>Ihre Rechnung</div>
			<div className='invoice-common__text' dangerouslySetInnerHTML={{__html: removeUnusedStyles(props.text)}}></div>
			<div className={'invoice-common__interaction'}>
				{props.pdfLink &&
				<div className='invoice-common__link'>
					<LinkPdf
						url={props.pdfLink.url}
						label={props.pdfLink.label}
						icon={'file-pdf'}
						download={props.pdfLink.downloadName}
					/>
					{props.pdfLink.state &&
						<Badge type={props.pdfLink.state.level}
							   label={props.pdfLink.state.label}
							   additionalClasses={'invoice-common__link-badge'}
						/>
					}
				</div>
				}
				{props.state.value !== 'fully_paid' && props.paymentPortalLink &&
				<div className={'invoice-common__cta'}>

					<ButtonAnchor target={'_blank'}
								  label={props.paymentPortalLink.label}
								  uid='payment-button'
								  additionalClasses='important fit'
								  href={props.paymentPortalLink.url}
					/>
				</div>
				}
			</div>
			<OfferInfo offerNumber={props.offerNumber}/>
		</div>
		<ContactBoxContainer/>
		</>
	)
}

Invoice.defaultProps = {
	text: ''
};


Invoice.propTypes = {
	/**
	 * copy text
	 * @example: 'Sehr geehrter Herr Dr. Testkunde\n\naktuell ist noch keine Rechnung vorhanden.\n\nMit freundlichen Grüßen\nAnn-Christin Rabe\n'
	 */
	text: PropTypes.string,

	/**
	 * PDF-Link as JSON-formatted object
	 *
	 * @example: { "url":"/api/document/download/invoice?id=12345678",
	 * 				"label": "Rechnung jetzt herunterladen"
	 * }
	 */

	pdfLink: PropTypes.shape({
		/**
		 * @example: '/api/document/download/invoice?id=12345678'
		 */
		url: PropTypes.string.isRequired,
		/**
		 * @example: 'Rechnung jetzt herunterladen'
		 */
		label: PropTypes.string.isRequired,

		downloadName: PropTypes.string
	}),

	/**
	 * Checkout-Link as JSON-formatted object
	 *
	 * @example: { "url":"https://zahlung.canusa.de/XXXXX",
	 * 				"label": "Rechnung online bezahlen"
	 * }
	 */
	paymentPortalLink: PropTypes.shape(
		{
			/**
			 * @example: 'https://zahlung.canusa.de/XXXXX'
			 */
			url: PropTypes.string.isRequired,
			/**
			 * @example: 'Rechnung online bezahlen'
			 */
			label: PropTypes.string.isRequired,
		}
	),
	/**
	 * state of invoice
	 */
	state: PropTypes.shape({
		/**
		 * @example: 'Keine Rechnung vorhanden.'
		 */
		label: PropTypes.string.isRequired,
		/**
		 * @default: "default"
		 */
		level: PropTypes.oneOf([
			'default'
		]).isRequired,
		/**
		 * @example: "not_paid"
		 *
		 */
		value: PropTypes.oneOf([
			'none',
			'not_paid',
			'partly_paid',
			'fully_paid'
		]).isRequired,
	}),
	/**
	 * show preloader
	 */
	isLoading: PropTypes.bool,

	/**
	 * flag in case of local error handling
	 */
	hasLoadingError:PropTypes.bool,

	/**
	 * offerNumber as dependency for useEffect hook
	 */
	offerNumber:PropTypes.string
};

const mapStateToProps = (state, ownProps) => {

	return {
		offerNumber: state.globalStateManagement.offer.offerNumber,
		...state.invoiceManagement
	}
};

export default connect(mapStateToProps, {dispatchFetchData: ()=>dispatchFetchData(FETCH_TYPE_INVOICE)})(Invoice)
