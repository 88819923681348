import React, {useEffect} from 'react';
import {Collapsible} from "../collapsible/Collapsible";
import './Faq.scss';
import {Preloader} from "../preloader/Preloader";
import classNames from "classnames";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {dispatchFetchFAQData} from "../../redux/actions/faqManagementAction";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";

function Faq({dispatchFetchFAQData, ...props}) {

  useEffect(() => {
    dispatchFetchFAQData();
  }, [dispatchFetchFAQData]);

  function parseData(data) {
    // store items in object/array: sort by type
    let contentList = {};
    for (let i = 0, len = data.length; i < len; i++) {
      let item = data[i];

      if (!contentList[item.section]) {
        contentList[item.section] = [];
      }
      contentList[item.section].push(createFAQEntry(item));
    }

    return Object.keys(contentList).map(key =>
      createSection(key, contentList[key])
    );
  }

  function createSection(key, obj) {
    return (
      <section key={key}>
        <h2>{key}</h2>
        {obj}
      </section>
    );
  }

  function createFAQEntry(dataItem) {
    return <Collapsible
      key={dataItem.id}
      id={dataItem.id}
      additionalClasses='faq__entry'
      headContent={<h3 key={'headline-' + dataItem.id}>{dataItem.question}</h3>}
      hasShadowOnHover={false}
      forceToggleArrow={true}
    >
      <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>
        {dataItem.answer}
      </ReactMarkdown>
    </Collapsible>
  }

  const classes = classNames(
    'faq',
    props.additionalClasses
  );

  if (props.isLoading || !props.loadingComplete) {
    return <Preloader type={'logo'}
                      additionalClasses={'faq box faq__preloader'}
    />;
  }

  const items = parseData(props.list);
  return (
    <div className={classes}>
      <h1>Häufig gestellte Fragen</h1>
      {items}
    </div>
  )
}

Faq.propTypes = {
  /**
   * OPTIONAL: add additional classes for this component here.
   * Use this prop for special css-classes, which are not defined by default.*
   */
  additionalClasses: PropTypes.string,

  /**
   * OPTIONAL: show Preloader
   */
  isLoading: PropTypes.bool,

  /**
   * **REQUIRED**: json-formatted array of FAQ-entries:
   *
   * @example: [{ "id":int,
   *   "section":"string",
   *   "question":"string",
   *   "answer":"string",
   *   "keywords":["string"]
   *  }]
   */
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    section: PropTypes.string,
    question: PropTypes.string,
    answer: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
  }))
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.faqManagement
  }
};

export default connect(mapStateToProps, {dispatchFetchFAQData: () => dispatchFetchFAQData()})(Faq);
