import axios from 'axios';
import { storageManager } from "./StorageManager";
import {isValidJSON} from "../../vendor/utils/HTMLUtils";

const CancelToken = axios.CancelToken;

class DataProviderService {

  /**
   *
   * @param endpoint
   * @param useCache
   * @param useMock
   * @param useLegacyAPI
   */
  constructor ({ endpoint, useCache = false, useMock = false, useLegacyAPI = false }) {

    this.endpoint = endpoint;
    this.useCache = useCache;
    this.useLegacyAPI = useLegacyAPI;
    this.sendRequest = this.sendRequest.bind ( this );

    if (process.env.REACT_APP_GLOBAL_FORCE_MOCK === "true") {
      useMock = true;
    }

    if (process.env.REACT_APP_GLOBAL_DISABLE_MOCK === "true" || process.env.NODE_ENV === 'production') {
      useMock = false;
    }

    this.baseUrl = useMock ? '/api-mock/' : '/restapi/';

    if (useLegacyAPI) {
      this.baseUrl = '/api/'
    }
    this.baseUrl ='/api/';
    this.storageManager = storageManager;

  }

  /**
   * cancel request on navigation
   */
  cancelRequest () {
    if (this.source) {
      this.source.cancel ( this.endpoint + ' request canceled on route has changed' )
    }
  }

  /**
   * get data from API
   * @param value
   * @returns {*}
   */
  getData (value = null) {
    let configVO = this.configVOGetFactory ( this.endpoint, value );

    if (this.useLegacyAPI) {
      configVO = this.configVOPostFactory ( this.endpoint, value );
    }
    return this.sendRequest ( configVO );
  }

  /**
   * set data to API
   * @param value
   * @returns {*}
   */
  setData (value) {
    let configVO = this.configVOSetFactory ( this.endpoint, value );

    if (this.useLegacyAPI) {
      configVO = this.configVOPostFactory ( this.endpoint, value );
    }
    return this.sendRequest ( configVO );
  }

  getPdfBlob () {
    let configVO = new RestConfVO ();
    configVO.apiUrl = this.endpoint;
    configVO.method = 'get';
    configVO.headers = { "Content-Type": "application/pdf" };//new Headers({"Content-Type": "application/pdf",'Accept': 'application/pdf'});
    configVO.addOfferNumberAsUrlParam = false;
    configVO.responseType = 'blob';

    return this.sendRequest ( configVO );
  }

  /**
   * prepare RestConfigVO for API-Call (GET)
   * @param endpoint
   * @param value for get request params
   * @returns {RestConfVO}
   */
  configVOGetFactory (endpoint, value) {
    const vo = new RestConfVO ();
    vo.apiUrl = this.baseUrl + endpoint;
    vo.method = 'get';
    vo.endpoint = endpoint;
    vo.urlParams = value || {};

    return vo;
  }

  configVOPostFactory (endpoint, value) {
    const vo = new RestConfVO ();
    vo.apiUrl = this.baseUrl + endpoint;
    vo.method = 'post';
    vo.endpoint = endpoint;

    let bodyFormData = new FormData ();
    if (endpoint === '/offer.php/portal-canusa/updateTraveller' ||
      endpoint === '/offer.php/portal-canusa/updateContact' ||
      endpoint === 'offer.php/portal-canusa/setItemNote' ||
      endpoint === 'offer.php/portal-canusa/bookOffer' ||
      endpoint === 'offer.php/portal-canusa/saveOffer' ||
      endpoint === 'offer.php/portal-canusa/getRenter'
    ) {
      bodyFormData.set ( 'offer', storageManager.realOfferToken );
    } else {

      bodyFormData.set ( 'offer', storageManager.offerToken );
    }

    if (value) {
      if (value instanceof FormData) {
        value.forEach ( (value, key) => {

          if (key.indexOf ( '[birthdate]' ) !== -1) {
            bodyFormData.set ( key, value.split ( '-' ).reverse ().join ( '.' ) );
          } else {
            bodyFormData.set ( key, value );
          }
        } )
      } else {
        for (let key in value) {
          bodyFormData.set ( key, value[ key ] );
        }
      }
    }

    if (storageManager.authToken) {
      bodyFormData.set ( 'token', storageManager.authToken );
      bodyFormData.set ( 'securityToken', "" );
    }

    vo.data = bodyFormData;
    return vo;
  }

  /**
   * prepare RestConfigVO for API-Call (SET)
   * @param endpoint
   * @param value
   * @returns {RestConfVO}
   */
  configVOSetFactory (endpoint, value) {
    const vo = new RestConfVO ();
    vo.apiUrl = this.baseUrl + endpoint;
    vo.endpoint = endpoint;
    vo.method = 'put';

    if (endpoint === 'portal/notifications') {
      vo.addTokenAsUrlParam = true;
      vo.addOfferNumberAsUrlParam = false;
      vo.data = {
        "offerNumber": storageManager.offerNumber,
        "notificationIds": value,
        "returnRemainingData": true
      }
    }
    return vo;
  }

  /**
   * sending request to API
   * @param configVO
   * @returns {Promise}
   */
  sendRequest (configVO) {

    let headers = configVO.headers || new Headers (
      {
        "Content-type": "application/json",
        'Accept': 'application/json',
      }
    );

    if (configVO.method === 'get' || configVO.method === 'put') {
      if (this.storageManager.authToken && configVO.addTokenAsUrlParam) {
        configVO.urlParams.token = this.storageManager.authToken;
        if (this.storageManager.offerNumber && configVO.addOfferNumberAsUrlParam) {
          configVO.urlParams.offerNumber = this.storageManager.offerNumber;
        }
      }
    }

    // create cancelToken for this request
    this.source = CancelToken.source ();
    // get new data if props.useCache is false an the data isn't already stored or forced to refresh
    const cachedPromise = this.storageManager.getCacheValue ( configVO.endpoint );

    if (!this.useCache || !cachedPromise) {

      const requestObject = {
        url: configVO.apiUrl,
        cancelToken: this.source.token,
        method: configVO.method,
        headers: headers,
        params: configVO.urlParams,
        responseType: configVO.responseType
      }

      requestObject.data = configVO.data;

      const promise = axios ( requestObject )
      .then ( (response) => {
        this.source = null;

        if(!isValidJSON(response)) {
          throw new DataProviderError("invalidJSON", DataProviderErrorType.INVALID_JSON);
        }

        if(response.error || response.err) {

          throw new DataProviderError(`Server error: ${response.error || response.err}`, DataProviderErrorType.SERVER_ERROR);
        }

        // clear responseCacheMap if cache is forced to clear
        if (!this.useCache) {
          this.storageManager.setCacheValue ( configVO.endpoint, null );
        }
        return response.data;
      } )
      .catch ( (error) => {
        // prepare ErrorMessage for use in WithDataProvider
        let errorCopy = {};

        if (error.message) {
          errorCopy.message = error.message
        }

        if (axios.isCancel ( error )) {
          errorCopy.internalType = "isCanceled";
        } else if (error.response && error.response.status) {
          errorCopy.internalType = error.response.status;
        } else if (error.errorType) {
          errorCopy.internalType = error.errorType;
        } else {
          errorCopy.internalType = "unknown";
        }

        return errorCopy;
      } );

      if (this.useCache) {
        this.storageManager.setCacheValue ( configVO.endpoint, promise );
      }

      return promise;
    } else {
      return cachedPromise;
    }
  }
}

/**
 * ValueObject to store data for API-Request
 */
class RestConfVO {

  constructor () {
    this._apiUrl = null;
    this._method = 'get';
    this._endpoint = null;
    this._data = {};
    this._headers = {
      "Content-type": "application/json",
      'Accept': 'application/json',
    };
    this._urlParams = {};
    this._addTokenAsUrlParam = true;
    this._addOfferNumberAsUrlParam = true;
    this._responseType = null;
  }

  /**
   * Get responseType for request.
   * @return responseType
   */
  get responseType () {
    return this._responseType;
  }

  /**
   * Set responseType for request.
   * @param  responseType
   */
  set responseType (value) {
    this._responseType = value;
  }

  /**
   * Flag for setting token automatically as an url param. default: true
   * @return {boolean}
   */
  get addTokenAsUrlParam () {
    return this._addTokenAsUrlParam;
  }

  /**
   * Flag for setting token automatically as an url param. default: true
   * @return {boolean}
   */
  set addTokenAsUrlParam (value) {
    this._addTokenAsUrlParam = value;
  }

  /**
   * Flag for setting offerNumber automatically as an url param. default: true
   * @return {boolean}
   */
  get addOfferNumberAsUrlParam () {
    return this._addOfferNumberAsUrlParam;
  }

  /**
   * Flag for setting offerNumber automatically as an url param. default: true
   * @return {boolean}
   */
  set addOfferNumberAsUrlParam (value) {
    this._addOfferNumberAsUrlParam = value;
  }

  /**
   * Get URL Parameters.
   * @return urlParams
   */
  get urlParams () {
    return this._urlParams;
  }

  /**
   * Set URL Parameters.
   * @param URL Parameters urlParams
   */
  set urlParams (value) {
    this._urlParams = value;
  }

  /**
   * Get Header.
   * @return header {Header}
   */
  get headers () {
    return this._headers;
  }

  /**
   * Set Header.
   * @param  header {Header}
   */
  set headers (value) {
    this._headers = value;
  }

  /**
   * Get data for request.
   * @return {object}
   */
  get data () {
    return this._data;
  }

  /**
   * Set data for request.
   * @param data {object} for get request params
   */
  set data (value) {
    this._data = value;
  }

  /**
   * get endpoint
   * @returns {string}
   */
  get endpoint () {
    return this._endpoint;
  }

  /**
   * set endpoint
   * @param value
   */
  set endpoint (value) {
    this._endpoint = value;
  }

  /**
   * get ApiUrl
   * @returns {string}
   */
  get apiUrl () {
    return this._apiUrl;
  }

  /**
   * set apiUrl
   * @param value
   */
  set apiUrl (value) {
    this._apiUrl = value;
  }

  /**
   * get method
   * @returns {string}
   */
  get method () {
    return this._method;
  }

  /**
   * set method (get, put, delete, post, options)
   * @param value
   */
  set method (value) {
    this._method = value;
  }
}

export {
  DataProviderService as default,
  DataProviderService,
  RestConfVO,
}

export class DataProviderError extends Error {
  constructor(message,errorType) {
    super(message);
    this.errorType = errorType;
  }
}

export const DataProviderErrorType = {
  INVALID_JSON:"INVALID_JSON",
  SERVER_ERROR:"SERVER_ERROR"
}
