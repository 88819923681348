import React, {Fragment} from 'react';
import './DigitalOfferOptionalsContent.scss'
import ItemGroup from "../../components/itemGroup/ItemGroup";
import {ItemGroupHeader} from "../../components/itemGroup/itemGroupHeader/ItemGroupHeader";
import {ErrorMessage} from "../../components/error/ErrorMessage";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Preloader} from "../../components/preloader/Preloader";
import {PreloaderWrapper} from "../../components/preloader-wrapper/PreloaderWrapper";
import {PriceGuarantee} from "../../components/priceGuarantee/PriceGuarantee";
import {BookingBox} from "../../components/bookingBox/BookingBox";
import {ButtonInternal} from "../../components/button/ButtonInternal";
import CurrentOffer from "../../components/currentOffer/CurrentOffer";
import {getPriceOfActiveItems, getPriceOfGroups, getPriceOfOptionals} from "../../redux/selectors";
import {BUTTON_APPEARANCE, BUTTON_WIDTH} from "../../components/button/Button";

function DigitalOfferOptionalsContent({dispatchSaveOffer, ...props}) {

  function renderItems(items) {
    if (!items) {
      return null;
    }

    return (

      items.map((group, index) => {
        return (
          <Fragment key={`group-${index}`}>
            <ItemGroup group={group} key={`group-${index}`} id={`group-${index}`} context={'optionals'}
                       itemMap={props.itemMap}
                       readOnly={group.readOnly}/>
          </Fragment>
        );
      })
    );
  }

  /**
   * renders Optional depending on journeyStartDate
   * @returns {*}
   */
  function renderOptionalsBlock() {

    if (!props.optionalItems) {
      return null;
    }
    return (
      <div className={'digital-offer-optionals-content'}>

        <div className='digital-offer-optionals-content__main'>
          <ItemGroupHeader label={'Zusatzleistungen'} withBackground={true} additionalClasses={'h3'}
                           price={props.optionalPrices}/>
          {renderItems(props.optionalItems)}

          <div className={'digital-offer-optionals-content__summary'}>

            <PriceGuarantee/>

            <BookingBox totalPrice={props.totalPrice} optionalPrice={props.optionalPrices}>

              <ButtonInternal linkPath={`/digitaloffer/${props.offerToken}/start`}
                              label={'Zurück zum Reiseverlauf'}
                              appearance={BUTTON_APPEARANCE.SECONDARY}
                              width={BUTTON_WIDTH.FULL_WIDTH}
              />

              <CurrentOffer hasCheckoutButton={true}/>
            </BookingBox>
          </div>
        </div>
      </div>
    );
  }

  if (props.hasLoadingError) {
    return <ErrorMessage type={'loadingError'}/>
  } else if (props.isLoading && !props.loadingComplete) {
    return <>
      <div className={'digital-offer-optionals-content'}>
        <div className='digital-offer-optionals-content__main'>
          <PreloaderWrapper>
            <Preloader type={'logo'}/>
          </PreloaderWrapper>
        </div>
      </div>
    </>
  }
  return renderOptionalsBlock()
}

DigitalOfferOptionalsContent.propTypes = {

  loadingComplete: PropTypes.bool,
  /**
   * list of optionalItems
   */
  optionalItems: PropTypes.array,
  /**
   * show preloader
   */
  isLoading: PropTypes.bool,

  /**
   * flag in case of local error handling
   */
  hasLoadingError: PropTypes.bool,

  offerNumber: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return {
    journeyStart: state.globalStateManagement.offer.journeyStart,
    journeyEnd: state.globalStateManagement.offer.journeyEnd,
    offerNumber: state.globalStateManagement.offer.offerNumber,
    offerToken: state.globalStateManagement.offer.offerToken,
    isAuthenticated: state.globalStateManagement.isAuthenticated,
    totalPrice: getPriceOfGroups(state.offerContentManagement.offerItems) + getPriceOfActiveItems(state.itemManagement.itemMap),
    optionalPrices: getPriceOfOptionals(state.itemManagement.itemMap, ownProps.optionalItems),
    isMainClient: state.globalStateManagement.isMainClient,
    itemMap: state.itemManagement.itemMap,
    ...state.optionalsManagement
  }
};

export default connect(mapStateToProps)(DigitalOfferOptionalsContent);
