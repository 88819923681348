import {storageManager} from "../../services/StorageManager";
import {
	dispatchIsAuthenticated, dispatchShowNotificationMessage,
} from "./globalStateManagementAction";
import {getErrorText} from "../../../vendor/utils/Mappings";
import {OfferService} from "../../services/OfferService";
import {dispatchFetchData, FETCH_TYPE_DIGITAL_OFFER_PARTIAL} from "./fetchAction";
import {
	dispatchBookingError,
	dispatchBookingSuccess,
	dispatchIsBooking,
	dispatchIsSaving
} from "./checkoutManagementAction";
import {dispatchUpdateItemMap} from "./itemManagementAction";
import {getPriceOfActiveItems, getPriceOfGroups} from "../selectors";
let isSaving = false;

export const saveOffer = (itemMap) => {
	if(isSaving) {
		return dispatch => dispatch(dispatchShowNotificationMessage({message: 'Dieses Angebot kann gerade nicht geändert werden. Bitte warten Sie einen Augenblick.', type: 'error'}));
	}

	return (dispatch, getState) => {

		const state = getState();
		const totalPrice = getPriceOfActiveItems(itemMap) + getPriceOfGroups(state.offerContentManagement.offerItems);

		if(totalPrice<=0) return;

		const dataProvider = new OfferService({
			endpoint: 'offer.php/portal-canusa/saveOffer',
			useCache: false,
			useMock: false,
			useLegacyAPI: true
		});
		isSaving = true;
		dispatch(dispatchIsSaving(true));
		return dataProvider.setOffer(itemMap,totalPrice).then(response => {
			isSaving = false;
			dispatch(dispatchIsSaving(false));
			if (response.error || response.err) {
				dispatch(dispatchShowNotificationMessage({message: response.error || response.err, type: 'error'}));
				return false;
			} else if (!response.success) {
				if (response.invalidate_session) {
					storageManager.authToken = null;
					dispatch(dispatchIsAuthenticated(false));
				}
				dispatch(dispatchShowNotificationMessage(getErrorText('invalidate')));
				return false;
			} else if (response.internalType) {
				dispatch(dispatchShowNotificationMessage(getErrorText(response.internalType)));
				return false;
			}

			if(response.offerToken !== storageManager.realOfferToken){
				storageManager.realOfferToken = response.offerToken;
				storageManager.offerNumber = response.offerNumber;
				dispatch(dispatchFetchData(FETCH_TYPE_DIGITAL_OFFER_PARTIAL));
			}
			dispatch(dispatchUpdateItemMap(itemMap));
			dispatch(dispatchShowNotificationMessage({message: 'Ihre Änderungen wurden gespeichert', type: 'info'}));
			return true;

		});

	}
};

const bookOffer = (itemMap, totalPrice, form) => {
	return dispatch => {

		dispatch(dispatchIsBooking())
		const dataProvider = new OfferService({
			endpoint: 'offer.php/portal-canusa/bookOffer',
			useCache: false,
			useMock: false,
			useLegacyAPI: true
		});

		dataProvider.setOffer(itemMap,totalPrice, form).then(response => {
			if(response.status) {
				dispatch(dispatchBookingSuccess());
				dispatch(dispatchFetchData(FETCH_TYPE_DIGITAL_OFFER_PARTIAL));
			} else {
				if (response.invalidate_session) {
					storageManager.authToken = null;
					dispatch(dispatchIsAuthenticated(false));
				}
				dispatch(dispatchBookingError('Es gab einen Fehler bei Ihrer Buchung.'))
			}


		});

	}
};

export const dispatchSaveOffer = (itemMap) => {
	return (dispatch) => {
		return dispatch(saveOffer(itemMap))
	}
};

export const dispatchBookOffer = (itemMap, totalPrice, form) => {
	return (dispatch) => {
		return dispatch(bookOffer(itemMap, totalPrice, form))
	}
};
