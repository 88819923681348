import './ResetPasswordMask.scss';
import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {Input} from "../input/Input";
import {Button} from "../button/Button";
import {FormValidator} from "../form/FormValidator";
import {Tooltip} from "../tooltip/Tooltip";
import PropTypes from 'prop-types';
import {formErrorMessages} from "./FormUtils";

class ResetPasswordMask extends Component {

	constructor(props) {
		super(props);

		this.state = {
			errorFromResponse: null,
			firstTimeError: false,
			errorMessage: null,
			loggedIn: false,
			isLoading: false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataProvider = this.props.dataProvider;
		this.resetHeadline = "Passwort zurücksetzen";
	}

	/**
	 * submit handler of Form
	 * send Form and set state depending on response
	 * if form was send for the first time then errors will be shown. not before
	 * @param form
	 * @param isValid
	 */
	handleSubmit({form, isValid}) {
		//the isValid prop is overwritten because is not used in this mask
		isValid=true;
		this.setState({
			isLoading: true,
		});

		if (isValid) {
			this.dataProvider.setData(form).then(response => {

				if (response.err) {
					this.setState({
						errorFromResponse: response,
						loggedIn: false,
						isLoading: false
					})
				} else {
					this.setState({
						loggedIn: true,
						errorMessage: null,
						isLoading: false,
						firstTimeError: false,
						errorFromResponse: null
					})
				}
			});
		} else {
			this.setState(
				{
					firstTimeError: true,
					isLoading: false,
					errorMessage: formErrorMessages['comparePass']
				});
		}
	}

	/**
	 * Gets the error message according to the property "name" of the input field
	 * @param inputName
	 * @returns {null|*}
	 */
	getErrorMessage(inputName) {

		if(!this.state.errorFromResponse) {
			return null;
		}

		if(inputName === "reason") {
			return  this.state.errorFromResponse[inputName];
		}

		if(this.state.errorFromResponse.err.hasOwnProperty(inputName)){
			return  this.state.errorFromResponse.err[inputName] && this.state.errorFromResponse.err[inputName][0];
		}

		return null;
	}

	render() {

		if (this.state.loggedIn) return <Redirect to={this.props.redirectTo}/>;

		return (
			<>
				<div className={"login_wrapper__mask-content"}>
					<h2 className='login_wrapper__headline'>{this.resetHeadline}</h2>

					<FormValidator additionalClasses="login_wrapper__form"
								   onSubmit={this.handleSubmit}
								   render={({changeHandler, formIsValid}) => (
									   <>
										   <div className="login_wrapper__inputs">

											   <Input name="password_new"
													  label="neues Passwort"
													  type="password"
													  required={true}
													  onChange={changeHandler}
													  showError={!!this.getErrorMessage("password_new")}
													  fullWidth={true}
													  errorMessage={this.getErrorMessage("password_new")}
													  tooltipDirection={this.props.isDesktop ? 'left' : 'top'}
											   />

											   <Input name="password_new2"
													  label="neues Passwort wiederholen"
													  type="password"
													  required={true}
													  onChange={changeHandler}
													  showError={!!this.getErrorMessage("password_new2")}
													  fullWidth={true}
													  errorMessage={this.getErrorMessage("password_new2")}
													  tooltipDirection={this.props.isDesktop ? 'left' : 'top'}
											   />

											   <Input name="reset_id"
													  type="hidden"
													  label=""
													  initialValue={this.props.resetId}
													  onChange={changeHandler}
											   />
										   </div>
										   <Tooltip
											   content={this.getErrorMessage('reason')}
											   showDefault={false}
											   direction={this.props.isDesktop ? 'left' : 'top'}
											   inactive={!this.getErrorMessage('reason')}
											   color={'important'}>
											   <Button type="submit"
													   label="Passwort bestätigen"
													   additionalClasses="important login_wrapper__submit"
													   isLoading={this.state.isLoading}
													   disabled={this.state.firstTimeError && !formIsValid}/>
										   </Tooltip>
									   </>
								   )}/>
				</div>
				<div className={'login_wrapper__divider dotted'}/>
				<div className='login_wrapper__text'>
					<div className='h2 login_wrapper__headline login_wrapper__headline--visible-on-desktop'>
						{this.resetHeadline}
					</div>
					Bitte wählen Sie ein sicheres Passwort, welches Sie sich gut merken können. Tragen Sie dieses zunächst in das Feld „neues Passwort“ ein. Zur Verifizierung des Passworts wiederholen Sie das neue Passwort bitte im unteren Feld.
					Nach dem erfolgreichen Ändern des Passworts erhalten Sie eine entsprechende Meldung.
					Sollten Sie diese Meldung nicht erhalten, versuchen Sie es bitte erneut. Vielleicht hat sich beim ersten Versuch ein Tippfehler eingeschlichen.
				</div>
			</>
		)
	}
}

ResetPasswordMask.propTypes = {
	/**
	 * id reset api needs
	 */
	resetId: PropTypes.number,
	/**
	 * instance of LoginService
	 */
	dataProvider: PropTypes.object,
	/**
	 * add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,
	/**
	 * opens the Tooltip into the specified direction, if there is enough space in the viewer
	 */
	tooltipDirection: PropTypes.oneOf(['top', 'top-right', 'top-left', 'bottom', 'bottom-right', 'bottom-left', 'right', 'left']),
};




export {
	ResetPasswordMask
}
