import React, {useState} from 'react';
import {Button} from "../components/button/Button";
import TagManager from "react-gtm-module";
import {tagManagerArgs} from "../../vendor/utils/Utils";

function EnableTrackingButton(props) {

	const [isTracking, setTracking] = useState(false);

	function clickHandler() {
		setTracking(true);
		TagManager.initialize(tagManagerArgs);
	}

	return (
		<>
			<Button label={"Tracking einschalten"} onClick={clickHandler} disabled={isTracking} width={'fit'}/>
		</>
	);

}
export {
	EnableTrackingButton as default,
	EnableTrackingButton
}
