//import 'core-js/es'; // Not sure if still needed
import 'react-app-polyfill/stable';
import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import TagManager from "react-gtm-module";
import {isDevEnvironment, tagManagerArgs} from "./vendor/utils/Utils";

if(!isDevEnvironment()) {
    TagManager.initialize(tagManagerArgs)
}

const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
/*
root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
);
*/

root.render(
        <App />
);
