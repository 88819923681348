const getLinksByType = (list, type) => {
  const linkList = [];

  if (list) {
    list.forEach(group => {
      if (group.type === type) {
        group.items.forEach(item => {
          if (item.link) {
            linkList.push(item.link);
          }
        })
      }
    });
  }

  return linkList;
};


export const getDateByEnvironment = (stateManagement, dateType) => {
  if (stateManagement.environment === "portal") {
    return stateManagement.offer[dateType];
  }
  return stateManagement[dateType];
}

export const getAddonContentById = (itemMap, itemId) => {
  if (itemMap[itemId].tabs) {
    const addonTab = itemMap[itemId].tabs.find(tab => tab.type === "addOn");
    if (addonTab) {
      return addonTab.content;
    }
  }
  return null;
}

export const getActiveItem = (itemMap, subSet) => {
  const activeItemId = subSet.find(itemId => {
    const item = itemMap[itemId];
    return item && !!item.active;
  })

  return itemMap[activeItemId];
}

export const getAllActiveItem = (itemMap) => {
  const list = Object.keys(itemMap).map(itemId => {
    const item = itemMap[itemId];
    return !!item.active && item;
  })
  return list.length > 0 ? JSON.stringify(list) : null;
}

export const getSelectedAddonPrices = (item) => {
  let price = 0;
  if (item.type === 'camper' && item.tabs) {
    const addOnTab = item.tabs.find(tab => tab.type === 'addons');
    if (addOnTab) {
      addOnTab.content.forEach(addon => {
        price += parseInt(addon.price && (addon.price[addon.selected] || 0));
      })
    }
  }
  return price;
}

export const getPriceOfActiveAlternatives = (itemMap, subSet) => {
  let price = 0;

  if (!subSet) {
    throw new Error("subSet is mandatory");
  }
  subSet.forEach((itemId, index) => {
    let item = itemMap[itemId];

    if (item) {
      if (item.alternatives) {
        price += getPriceOfActiveItems(itemMap, item.alternatives)
      } else {
        price += getSelectedAddonPrices(item);
      }
    }

    if (item && item.contentType === 'optional' && item.selected) {
      price += item.price;
    }

  })

  return price;
}

export const getPriceOfOptionals = (itemMap, subSet) => {
  let price = 0;
  if (!subSet && itemMap) {
    subSet = Object.keys(itemMap);
  }
  subSet.forEach((itemId, index) => {
    let item = itemMap[itemId];
    if (item?.contentType === 'optional' && item?.selected) {
      price += item.price;
    }
  });

  return price;
}

export const getPriceOfActiveItems = (itemMap, subSet) => {
  let price = 0;
  if (!subSet && itemMap) {
    subSet = Object.keys(itemMap);
  }
  subSet.forEach((itemId, index) => {
    let item = itemMap[itemId];
    if (item && item.active) {
      price += item.priceDiff ? item.priceDiff : 0;
      price += getSelectedAddonPrices(item);
    }

    if (item && item.contentType === 'optional' && item.selected) {
      price += item.price;
    }
  })

  return price;
}

export const getPriceOfGroups = (groups) => {
  let price = 0;
  if (groups) {
    groups.forEach(group => {
      if (!!group.price) {
        price += group.price;
      }
    })
  }
  return price;
}

export const getNoticeByID = (id, itemMap) => {
  return itemMap[id].notice;
};

export const getExtraLinks = list => getLinksByType(list, 'extras');

export const getFlightLinks = list => getLinksByType(list, 'flight');

export const getItemById = (itemList, id) => {
  let selectedItem = null;
  if (itemList && id) {
    itemList.forEach(groupList => {
      groupList.items.find(item => {
        if (item.id === id) {
          selectedItem = item;
          return item.id === id;
        }
        return null;
      })
    });
  }
  return selectedItem;
};
