import {NoticeService} from "../../services/NoticeService";
import {dispatchUpdateNotice} from "./itemManagementAction";

export const NOTICE_FAILURE = 'NOTICE_FAILURE';
export const NOTICE_SAVING = 'NOTICE_SAVING';
export const NOTICE_SAVED = 'NOTICE_SAVED';
export const NOTICE_CLEAR = 'NOTICE_CLEAR';


export const dispatchClearNotice = () => ({
	type: NOTICE_CLEAR,
});

const dispatchSavingNotice = (id) => ({
	type: NOTICE_SAVING,
	noticeId: id
});

const dispatchSavedNotice = (id) => ({
	type: NOTICE_SAVED,
	noticeId: id
});

const dispatchFailedNotice = (id) => ({
	type: NOTICE_FAILURE,
	noticeId: id
});

const putNotice = (id, value) => {
	const dataProvider = new NoticeService({
		endpoint: 'offer.php/portal-canusa/setItemNote',
		useMock: false,
		useLegacyAPI: true
	});
	return dispatch => {
		dispatch(dispatchSavingNotice(id));
		return dataProvider.setNoticeById(id, value)
			.then((response) => {

				if (response.status) {
					dispatch(dispatchSavedNotice(id));
					dispatch(dispatchUpdateNotice(id, value));
				} else {
					dispatch(dispatchFailedNotice(id));
				}
			})
			.catch(error => {
				console.warn("Error on saving notice: ", error);
			})
	}
};

export const putNoticeById = (id, value) => {
	return (dispatch) => {
		return dispatch(putNotice(id, value))
	}
};
