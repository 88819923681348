import React, {Component} from 'react';
import './LoginMask.scss';
import {Input} from "../input/Input";
import {Button, BUTTON_APPEARANCE} from "../button/Button";
import {LinkInternal} from "../link/LinkInternal";
import {FormValidator} from "../form/FormValidator";
import {Tooltip} from "../tooltip/Tooltip";
import {Redirect} from "react-router-dom";
import PropTypes from 'prop-types';
import {formConfigMap} from "./FormUtils";
import {HtmlText} from "../htmlText/HtmlText";

class LoginMask extends Component {

    constructor(props) {
        super(props);

        this.state = {
            firstTimeError: false,
            errorMessage: null,
            loggedIn: false,
            isLoading: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.dataProvider = this.props.dataProvider;
    }

    /**
     * submit handler of Form
     * send Form and set state depending on response
     * if form was send for the first time then errors will be shown. not before
     * @param form
     * @param isValid
     */
    handleSubmit({form, isValid}) {
        //the isValid prop is overwritten because is not used in this mask
        isValid = true;

        this.setState({
            isLoading: true,
            errorMessage: null
        });

        if (isValid) {

            this.dataProvider.setData(form).then(response => {

                if (response.err) {

                    this.setState({
                        loggedIn: false,
                        isLoading: false,
                        errorMessage: response.err
                    })
                } else {
                    this.setState({
                        loggedIn: true,
                        isLoading: false,
                    })
                }
            });
        }
    }

    changeHandler(formIsValid) {
        formIsValid && this.setState({errorMessage: false})
    }

    render() {

        if (this.state.loggedIn) return <Redirect to={this.props.redirectTo}/>;

        return (
            <>
                <div className={"login_wrapper__mask-content"}>
                    <h2 className='login_wrapper__headline'>Herzlich willkommen im CANUSA-Kundenportal!</h2>
                    {/* changeHandler are passed to inputs, that form registers changes. Submit Button will be disabled if form has been submitted and is not valid*/}
                    <FormValidator additionalClasses="login_wrapper__form"
                                   onSubmit={this.handleSubmit}
                                   onChange={this.changeHandler}
                                   render={({changeHandler}) => (
                                       <>
                                           <div className="login_wrapper__inputs">

                                               <Input name="email"
                                                      label="E-Mail"
                                                      type="email"
                                                      placeholder={formConfigMap['loginMask'].email.placeholder}
                                                      required={true}
                                                      onChange={changeHandler}
                                                      showError={!!this.state.errorMessage}
                                                      fullWidth={true}
                                                      initialValue={formConfigMap['loginMask'].email.initialValue}
                                                      validator={formConfigMap['loginMask'].email.validationPattern}
                                                      autoFocus={false}
                                               />

                                               <Input name="password"
                                                      label="Passwort"
                                                      type="password"
                                                      required={true}
                                                      onChange={changeHandler}
                                                      showError={!!this.state.errorMessage}
                                                      fullWidth={true}
                                               />
                                           </div>
                                           <Tooltip
                                               content={this.state.errorMessage}
                                               inactive={!this.state.errorMessage}
                                               direction={this.props.isDesktop ? 'left' : 'top'}
                                               color={'important'}>

                                               <Button type="submit"
                                                       label="Einloggen"
                                                       additionalClasses="important login_wrapper__submit"
                                                       appearance={BUTTON_APPEARANCE.SECONDARY}
                                                       isLoading={this.state.isLoading}
                                                       disabled={this.state.firstTimeError}
                                               />
                                           </Tooltip>
                                       </>
                                   )}/>
                    <LinkInternal label='Passwort vergessen'
                                  linkPath='/forgot'
                                  additionalClasses="login_wrapper__link"
                    />
                </div>
                <div className={'login_wrapper__divider dotted'}/>
                <div className='login_wrapper__text'>
                    <div
                        className='h2 login_wrapper__headline login_wrapper__headline--visible-on-desktop'><HtmlText>Herzlich
                        Willkommen im CANUSA&#8209Kundenportal!</HtmlText></div>
                    In unserem Portal finden Sie alles, was für Ihre Reise wichtig ist: Ihre Buchungen, Rechnungen,
                    Reiseunterlagen und wichtige Informationen für Ihre Reise.
                    <div className={'login_wrapper__headline--linebreak'}></div>
                    Die Zugangsdaten erhalten Sie zusammen mit Ihrem persönlichen Reiseangebot.
                    <div className={'login_wrapper__headline--linebreak'}></div>
                    Sie haben Ihre Zugangsdaten vergessen? Dann klicken Sie auf den Link „Passwort vergessen“, damit Sie
                    auch
                    zukünftig alle Vorteile des CANUSA-Kundenportals nutzen können.

                    <div className={'login_wrapper__gender-sensitive-disclaimer ts-copy-small'}>
                        Die männliche Schreibform dient allein der Vereinfachung und steht für die geschlechtsneutrale
                        Bezeichnung.
                    </div>
                </div>
            </>
        )
    }
}

LoginMask.propTypes = {
    /**
     * instance of LoginService
     */
    dataProvider: PropTypes.object.isRequired,
    /**
     * redirection Url after login
     */
    redirectTo: PropTypes.oneOfType([
        PropTypes.shape({
            pathname: PropTypes.string
        }),
        PropTypes.string,
    ])
};

export {
    LoginMask
}
