import React, {Component} from 'react';
import './Consent.scss'
import classNames from "classnames";
import {BUTTON_APPEARANCE, Button} from "../button/Button";
import {StorageManager} from "../../services/StorageManager";
import {Link} from "../link/Link";
import PropTypes from "prop-types";
import ConsentCheckBox from "./ConsentCheckbox";

class Consent extends Component {

	constructor(props) {
		super(props);

		const cookie = StorageManager.getCookie('acceptedCookies');
		const decodedCookie = cookie && decodeURIComponent(cookie);
		this.state = {
			showConsent: false,
			checkboxStats: decodedCookie ? JSON.parse(decodedCookie).stats : false,
			checkboxPersonal: decodedCookie ? JSON.parse(decodedCookie).personal : false,
			statisticsEnabled: decodedCookie ? JSON.parse(decodedCookie).stats : false,
			personalEnabled: decodedCookie ? JSON.parse(decodedCookie).personal : false,
		};

		this.consentClickHandler = this.consentClickHandler.bind(this);
		this.showMoreHandler = this.showMoreHandler.bind(this);
		this.saveSelectionHandler = this.saveSelectionHandler.bind(this);
	}

	componentDidMount() {
		this.timeOut = setTimeout(() => {
			this.setState({
				showConsent: true
			})
		}, 1000)
	}

	componentWillUnmount() {
		clearTimeout(this.timeOut);
	}

	saveSelectionHandler() {
		const settings = {
			necessary: true,
			stats: Boolean(this.state.checkboxStats),
			personal: Boolean(this.state.checkboxPersonal),
		};

		this.storeCookie(settings);
	}

	/**
	 * handle click & set flag in localStorage
	 */
	consentClickHandler() {
		const settings = {
			necessary: true,
			stats: true,
			personal: true,
		};
		this.storeCookie(settings);
	}


	storeCookie(settings) {
		const keys = Object.keys(settings);
		// check if all checkboxes are set to true
		const allTrue = keys.every((key) => {
			return settings[key];
		});

		const expireDate = allTrue ? 30 : 1;

		StorageManager.setCookie('acceptedCookies', JSON.stringify(settings), expireDate);

		this.setState({
			showConsent: false
		});

		window.location.reload();
	}


	showMoreHandler() {
		this.setState(
			{
				expanded: true
			}
		)
	}

	render() {

		const classes = classNames(
			'cookie-consent',
			{'show-consent': this.state.showConsent},
			this.props.additionalClasses
		);

		return (
			<div className={classes}>

				<div className="cookie-consent__content-wrapper">
					<div className="cookie-consent__scroll-wrapper">
						<h2 className="cookie-consent__headline">DATENSCHUTZ-EINSTELLUNGEN</h2>
						<p>Wir verwenden Cookies zur Personalisierung von Inhalten, um Ihnen das bestmögliche Website-Erlebnis zu
							bieten und zur Analyse anonymer nicht personenbezogener Daten. Mit Klick auf „Allem zustimmen“ geben Sie
							uns
							dafür Ihr Einverständnis. Sie können unter „Speichern“ eine eingeschränkte Auswahl treffen. Bitte
							beachten
							Sie, dass abhängig von den von Ihnen gewählten Einstellungen, möglicherweise nicht mehr alle Funktionen
							der
							Website verfügbar sind. Weitere Informationen finden Sie in unserer <Link
								url={`${this.props.brandUrl}/service/datenschutz`} label='Datenschutzerklärung' target='_blank'/>.
						</p>

						<div className={'cookie-consent__checkbox-wrapper'}>

							<ConsentCheckBox name={'mandatory'}
											 checked={true}
											 disabled={true}
											 label={'Notwendig'}
											 description={'Diese Stufe ist erforderlich für die grundlegenden Funktionen der CANUSA-Webseiten sowie deren einwandfreier Darstellung.'}/>
							<ConsentCheckBox name={'statistics'}
											 checked={Boolean(this.state.statisticsEnabled)}
											 onChange={(value) => {
												 this.setState({checkboxStats: value})
											 }}
											 label={'Statistiken'}
											 description={'Damit helfen Sie uns dabei, unsere CANUSA-Webseiten mittels anonymisierter Daten zu analysieren und zu verbessern.'}/>
							<ConsentCheckBox name={'personal'}
											 checked={Boolean(this.state.personalEnabled)}
											 onChange={(value) => {
												 this.setState({checkboxPersonal: value})
											 }}
											 label={'Personalisierung'}
											 description={'Diese Cookies werden verwendet, um personalisierte Inhalte anzuzeigen, die Ihren Interessen entsprechen.'}/>
						</div>
					</div>


					<div className="cookie-consent__bottom-wrapper">
						<Link url={`${this.props.brandUrl}/service/impressum`} label='Impressum' target='_blank'/>

						<div className={"cookie-consent__button-wrapper"}>
							<Button label="Auswahl speichern"
									appearance={BUTTON_APPEARANCE.GHOST}
									width={'fit'}
									onClick={this.saveSelectionHandler}
									additionalClasses={'consent__more'}
							/>
							<Button label="Allem Zustimmen"
									appearance={BUTTON_APPEARANCE.SECONDARY}
									width={'fit'}
									onClick={this.consentClickHandler}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

Consent.propTypes = {
	/**
	 * **REQUIRED** brandName of the current website
	 */
	brandName: PropTypes.string.isRequired,

	/**
	 * **REQUIRED** absolute URL to legal notice (e.g.: https://absolute.url/legal-notice)
	 */
	brandUrl: PropTypes.string.isRequired,
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string
};

export {
	Consent as default,
	Consent
}
