import React, {Component} from 'react';
import './NewPasswordMask.scss';
import {Input} from "../input/Input";
import {Button} from "../button/Button";
import {LinkInternal} from "../link/LinkInternal";
import {FormValidator} from "../form/FormValidator";
import {Tooltip} from "../tooltip/Tooltip";
import PropTypes from 'prop-types';
import {Redirect} from "react-router-dom";
import {formErrorMessages} from "./FormUtils";

class NewPasswordMask extends Component {

	constructor(props) {
		super(props);

		this.state = {
			errorFromResponse: {},
			firstTimeError: false,
			errorMessage: null,
			loggedIn: false,
			requireSecurityToken: false,
			isLoading: false
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataProvider = this.props.dataProvider;
		this.newPasswordHeadline = "Wählen Sie Ihr neues Passwort";
	}

	/**
	 * submit handler of Form
	 * send Form and set state depending on response
	 * if form was send for the first time then errors will be shown. not before
	 * @param form
	 * @param isValid
	 */
	handleSubmit({form, isValid}) {
		//the isValid prop is overwritten because is not used in this mask
		isValid=true;
		this.setState({
			isLoading: true
		});

		if (isValid) {

			this.dataProvider.setData(form).then(response => {
				if (response.err) {

					this.setState({
						errorFromResponse: response.err,
						loggedIn: false,
						errorMessage: response.err,
						requireSecurityToken: response.require_security_session,
						isLoading: false
					})

				} else if (response.success === 1) {
					this.setState({
						loggedIn: true,
						errorMessage: null,
						isLoading: false,
						firstTimeError: false,
						errorFromResponse: {}
					});
				} else {
					this.setState({
						loggedIn: false,
						errorMessage: formErrorMessages['unknownError'],
						isLoading: false
					})
				}
			});
		}
	}

	/**
	 * Gets the error message according to the property "name" of the input field
	 * @param inputName
	 * @returns {null|*}
	 */
	getErrorMessage(inputName) {
		return  this.state.errorFromResponse[inputName] && this.state.errorFromResponse[inputName][0]
	}

	render() {

		if (this.state.loggedIn) return <Redirect to={this.props.redirectTo}/>;

		return (
			<>
				<div className={"login_wrapper__mask-content"}>
					<h2 className='login_wrapper__headline'>{this.newPasswordHeadline}</h2>
					<FormValidator additionalClasses="login_wrapper__form"
								   onSubmit={this.handleSubmit}
								   render={({changeHandler, formIsValid}) => (
									   <>
										   <div className="login_wrapper__inputs">

											   <Input name="password_old"
													  label="altes Passwort"
													  type="password"
													  required={true}
													  onChange={changeHandler}
													  showError={!!this.getErrorMessage("password_old")}
													  fullWidth={true}
													  errorMessage={this.getErrorMessage("password_old")}
											   />

											   <Input name="password_new"
													  label="neues Passwort"
													  type="password"
													  required={true}
													  onChange={changeHandler}
													  showError={!!this.getErrorMessage("password_new")}
													  fullWidth={true}
													  compareId={'new-password'}
													  errorMessage={this.getErrorMessage("password_new")}
											   />

											   <Input name="password_new2"
													  label="neues Passwort wiederholen"
													  type="password"
													  required={true}
													  onChange={changeHandler}
													  showError={!!this.getErrorMessage("password_new2")}
													  fullWidth={true}
													  compareId={'new-password'}
													  errorMessage={this.getErrorMessage("password_new2")}
											   />
										   </div>
										   <Tooltip
											   content={this.getErrorMessage("reason")}
											   showDefault={false}
											   inactive={!this.getErrorMessage("reason")}
											   direction={this.props.isDesktop ? 'left' : 'top'}
											   color={'important'}>
											   <Button type="submit"
													   label="Passwort bestätigen"
													   additionalClasses="important login_wrapper__submit"
													   isLoading={this.state.isLoading}
													   disabled={this.state.firstTimeError && !formIsValid}/>
										   </Tooltip>
									   </>
								   )}/>
					<LinkInternal label='Passwort vergessen'
												linkPath={{pathname: '/forgot', state: {offerToken: this.props.offerToken}}}
												additionalClasses="login_wrapper__link"
					/>
				</div>
				<div className={'login_wrapper__divider dotted'}/>
				<div className='login_wrapper__text'>
					<div
						className='h2 login_wrapper__headline login_wrapper__headline--visible-on-desktop'>{this.newPasswordHeadline}</div>
					Tragen Sie zunächst Ihr aktuelles Passwort in das Feld „altes Passwort“ ein.
					Wählen Sie dann ein sicheres Passwort, welches Sie sich gut merken können. Tragen Sie dieses zunächst in das Feld „neues Passwort“ ein. Zur Verifizierung des Passworts wiederholen Sie das neue Passwort bitte im unteren Feld.
					Nach dem erfolgreichen Ändern des Passworts erhalten Sie eine entsprechende Meldung.
					Sollten Sie diese Meldung nicht erhalten, versuchen Sie es bitte erneut. Vielleicht hat sich beim ersten Versuch ein Tippfehler eingeschlichen.
				</div>
			</>
		)
	}
}

NewPasswordMask.propTypes = {
	/**
	 * instance of LoginService
	 */
	dataProvider: PropTypes.object
};

export {
	NewPasswordMask
}
