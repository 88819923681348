import { combineReducers } from "redux";
import noticeManagementReducer from './reducersHelpers/noticeManagementReducer.js';
import globalStateManagementReducer from './reducersHelpers/globalStateManagementReducer.js';
import {
  DATA_UPDATE,
  FETCH_TYPE_CONTACT,
  FETCH_TYPE_DIGITAL_OFFER_CONTENT,
  FETCH_TYPE_DIGITAL_OFFER_OPTIONALS,
  FETCH_TYPE_DIGITAL_OFFER_ROUTES,
  FETCH_TYPE_DIGITAL_OFFER_REVISION_LIST,
  FETCH_TYPE_DOCUMENTS_COMMON,
  FETCH_TYPE_DOCUMENTS_ITEMS,
  FETCH_TYPE_FOOTER,
  FETCH_TYPE_INVOICE,
  FETCH_TYPE_OFFER_CONTENT,
  FETCH_TYPE_OPTIONALS,
  FETCH_TYPE_PARTICIPANTS,
  FETCH_TYPE_USER_PROFILE,
  FETCH_TYPE_DIGITAL_OFFER_RENTERS,
} from "./actions/fetchAction";
import { STORE_CLEAR } from "./actions/globalStateManagementAction";
import { faqManagementReducer } from "./reducersHelpers/faqManagementReducer";
import routeHintManagementReducer from "./reducersHelpers/routeHintManagementReducer";
import itemManagementReducer from "./reducersHelpers/itemsManagementReducer";
import { checkoutStateManagementReducer } from "./reducersHelpers/checkoutStateManagementReducer";
import mapManagementReducer from "./reducersHelpers/mapManagementReducer";
import {CHECKOUT_RESET} from "./actions/checkoutManagementAction";

/**
 * generic reducer. handles action type DATA_UPDATE and STORE_CLEAR
 *
 * @param state
 * @param action
 * @return {{}|*}
 */
const saveFetchedData = (state = {}, action) => {
  switch (action.type) {

    case DATA_UPDATE:
      return {
        ...state,
        ...action.data,
      };

    case CHECKOUT_RESET:
      return {
        ...state,
        isLoading: false,
        hasLoadingError:false,
      }

    case STORE_CLEAR:
      return {};

    default:
      return state;
  }
};

/**
 * createFilteredReducer
 *
 * @param reducerFunction
 * @param reducerPredicate
 * @return {function(*=, *=): *}
 */
function createFilteredReducer(reducerFunction, reducerPredicate) {
  return (state, action) => {
    const isInitializationCall = state === undefined;
    const shouldRunWrappedReducer = reducerPredicate(action) || isInitializationCall;
    return shouldRunWrappedReducer ? reducerFunction(state, action) : state;
  }
}

const rootReducer = combineReducers({
  // common
  noticeManagement: noticeManagementReducer,
  faqManagement: faqManagementReducer,
  globalStateManagement: globalStateManagementReducer,
  routeHintManagement: routeHintManagementReducer,
  itemManagement: itemManagementReducer,
  checkoutManagement: checkoutStateManagementReducer,
  mapManagement: mapManagementReducer,

  footerManagement: createFilteredReducer(saveFetchedData, action => {
    return (action.type === STORE_CLEAR || action.fetchType === FETCH_TYPE_FOOTER)
  }),

  // portal endpoint
  userProfileManagement: createFilteredReducer(saveFetchedData, action => {
    return (action.type === STORE_CLEAR || action.fetchType === FETCH_TYPE_USER_PROFILE || action.type === CHECKOUT_RESET)
  }),
  documentsCommonManagement: createFilteredReducer(saveFetchedData, action => {
    return (action.type === STORE_CLEAR || action.fetchType === FETCH_TYPE_DOCUMENTS_COMMON)
  }),
  documentsItemsManagement: createFilteredReducer(saveFetchedData, action => {
    return (action.type === STORE_CLEAR || action.fetchType === FETCH_TYPE_DOCUMENTS_ITEMS)
  }),
  invoiceManagement: createFilteredReducer(saveFetchedData, action => {
    return (action.type === STORE_CLEAR || action.fetchType === FETCH_TYPE_INVOICE)
  }),

  offerContentManagement: createFilteredReducer(saveFetchedData, action => {
    return (action.type === STORE_CLEAR || action.fetchType === FETCH_TYPE_OFFER_CONTENT || action.fetchType === FETCH_TYPE_DIGITAL_OFFER_CONTENT)
  }),
  optionalsManagement: createFilteredReducer(saveFetchedData, action => {
    return (action.type === STORE_CLEAR || action.fetchType === FETCH_TYPE_OPTIONALS || action.fetchType === FETCH_TYPE_DIGITAL_OFFER_OPTIONALS)
  }),
  offerRoutesManagement: createFilteredReducer(saveFetchedData, action => {
    return (action.type === STORE_CLEAR || action.fetchType === FETCH_TYPE_DIGITAL_OFFER_ROUTES)
  }),

  contactManagement: createFilteredReducer(saveFetchedData, action => {
    return (action.type === STORE_CLEAR || action.fetchType === FETCH_TYPE_CONTACT)
  }),
  revisionListManagement: createFilteredReducer(saveFetchedData, action => {
    return (action.type === STORE_CLEAR || action.fetchType === FETCH_TYPE_DIGITAL_OFFER_REVISION_LIST)
  }),
  participantManagement: createFilteredReducer(saveFetchedData, action => {
    return (action.type === STORE_CLEAR || action.fetchType === FETCH_TYPE_PARTICIPANTS || action.type === CHECKOUT_RESET)
  }),

  rentersManagement: createFilteredReducer(saveFetchedData, action => {
    return (action.type === STORE_CLEAR || action.fetchType === FETCH_TYPE_DIGITAL_OFFER_RENTERS || action.type === CHECKOUT_RESET)
  }),

});

export default rootReducer;
