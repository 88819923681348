import { DataProviderService } from "../../services/DataProviderService";
import {
  dispatchIsAuthenticated,
} from "./globalStateManagementAction";
import {
  dispatchUpdateData, FETCH_TYPE_PARTICIPANTS, FETCH_TYPE_USER_PROFILE
} from "./fetchAction";
import {
  getParsedParticipants,
} from "../../../vendor/utils/DigitalOfferContentUtils";
import { dispatchCheckoutForward } from "./checkoutManagementAction";

/**
 * do something specific for different kinds of errors
 * @param errorStatus
 */
const handleError = (dispatch, errorStatus, fetchType) => {

  switch (errorStatus) {
    case 401:
      dispatch(dispatchIsAuthenticated(false));
      break;

    case "isCanceled":
      //do nothing here
      break;

    default:
      console.warn("error with code ", errorStatus);
      dispatch(dispatchUpdateData({hasLoadingError: true, errorMessage: errorStatus}, fetchType));
  }
};

const updateData = (form, isCheckout) => {
  return dispatch => {
    dispatch(dispatchUpdateData({isLoading: true}, FETCH_TYPE_USER_PROFILE));
    const dataProvider = new DataProviderService({
      endpoint: '/offer.php/portal-canusa/updateContact',
      useCache: false,
      useMock: false,
      useLegacyAPI: true
    });
    dataProvider.setData(form).then(response => {
      if (response.internalType) {
        dispatch(dispatchUpdateData({isLoading: false}, FETCH_TYPE_USER_PROFILE));
        handleError(dispatch, response.internalType, FETCH_TYPE_USER_PROFILE);
      } else {
        dispatch(dispatchUpdateData({...getParsedParticipants(response), updateComplete: true}, FETCH_TYPE_PARTICIPANTS));
        dispatch(dispatchUpdateData({...response.offerContact}, FETCH_TYPE_USER_PROFILE));
        dispatch(dispatchUpdateData({isLoading: false}, FETCH_TYPE_USER_PROFILE));
        if (isCheckout) {
          dispatch(dispatchCheckoutForward());
        }

      }
    });
  }
};

/**
 * generic fetchData action
 *
 * @param fetchType| one of the defined fetchType constants
 * @param silent | set to true if isLoading should not be set in the data while loading
 * @return {function(*): *}
 */
export const dispatchUpdateUserProfile = (form, isCheckout) => {
  return (dispatch) => {
    return dispatch(updateData(form, isCheckout))
  }
};
