import React, {Component} from 'react';
import {Slider} from "../../slider/Slider";
import {Image} from "../../image/Image";
import './GallerySlider.scss'
import PropTypes from "prop-types";

class GallerySlider extends Component {
    render() {
        return (
            <Slider startAt={this.props.startAt}
                    keyControl={true}
                    additionalClasses={'gallery-slider'}
                    classes={{
                        slide: 'gallery-slider__slide',
                        navBtn: 'gallery-slider__btn'
                    }}>
                {this.props.images.map((image, index) => {
                    return (
                        <Image url={image.url} alt={image.alt} key={`gallery-slider-img-${index}`}/>
                    )
                })}
            </Slider>
        )
    }
}

GallerySlider.propTypes = {
    /**
     * array of imageVO
     */
    images: PropTypes.array,
    /**
     * index of start slide
     */
    startAt: PropTypes.number
};

export {
    GallerySlider as default,
    GallerySlider
}
