import React from 'react';
import { Input } from "../../portal/components/input/Input";
import Select from "../../portal/components/select/Select";
import { dateValidationPattern, formErrorMessages } from "../../portal/components/login/FormUtils";
import { toGermanDateStr } from "./DateUtils";


/**
 * Helper function for creating form field names
 * @param name
 * @param index
 * @return {string}
 */
function getInputName(name, index, nameSpace, useIndex = true) {
  return useIndex ? `${nameSpace}[${index}][${name}]` : `${nameSpace}[${name}]`;
}


/**
 * reformats dd.mm.yyyy into yyyy-mm-dd until backend serves dates in ISO format
 * @param type
 * @param value
 * @return {*}
 */
function reformatValue(type, value) {
  if (type === 'date') {
    const tempValue = value ? value.split('.') : [];
    return value ? tempValue[2] + '-' + tempValue[1] + '-' + tempValue[0] : null;
  } else {
    return value
  }
}

/**
 * generates Input Component
 * @param name
 * @param label
 * @param index
 * @param changeHandler
 * @param value
 * @param type
 * @param required
 * @param validator
 * @return {*}
 */
function createInput({
                       name,
                       label,
                       index,
                       changeHandler,
                       value,
                       type = 'text',
                       required,
                       parentId,
                       showFormErrors,
                       additionalClasses,
                       nameSpace = 'offerParticipants',
                       disabled = null,
                       validator = null
                     }) {


  const useIndex = !(nameSpace === 'offerContact')
  return <Input label={required ? label + ' *' : label}
                value={value}
                validator={validator}
                type={type}
                initialValue={reformatValue(type, value)}
                fullWidth={true}
                required={required}
                disabled={disabled}
                showError={showFormErrors}
                onChange={changeHandler}
                additionalClasses={additionalClasses ? additionalClasses : 'participants-form__' + name}
                name={getInputName(name, index, nameSpace, useIndex)}
                errorMessage={formErrorMessages[name + 'Error']}
                tooltipDirection={'top'}
                parentContainerSelector={'#' + parentId}
  />
}


/**
 * creates form for each participant
 *
 * @param changeHandler
 * @return {*}
 */
export const createParticipants = (participantsList, changeHandler, parentId, showFormErrors) => {

  return participantsList.map((participant, index) => {

    return <div className={'participants-form__participant'} key={participant.id}>

      <div className={'participants-form__participant-content'}>

        <div className={'h3 tt-none'}>Reiseteilnehmer {index + 1}</div>

        <input type={'hidden'} name={'offerParticipants[' + index + '][id]'} value={participant.id}/>

        <div className={'participants-form__wrapper'}>
          <span className={'participants-form--halfwidth'}>
            <Select label={'Anrede *'}
                    options={[
                      {value: 'm', label: 'Herr'},
                      {value: 'w', label: 'Frau'},
                    ]}
                    name={getInputName('gender', index, 'offerParticipants', true)}
                    fullWidth={true}
                    required={true}
                    showError={showFormErrors}
                    onChange={changeHandler}
                    errorMessage={formErrorMessages['genderError']}
                    tooltipDirection={'top'}
                    parentContainerSelector={'#' + parentId}
                    initialValue={participant.gender ? participant.gender : 'm'}
                    additionalClasses={'participants-form__gender'}
            />
            </span>

          <span className={'participants-form--halfwidth'}>
            {createInput({
              name: 'birthdate',
              label: 'Geburtstag',
              index: index,
              changeHandler: changeHandler,
              value: participant.birthdate,
              type: 'date',
              required: true,
              parentId: parentId,
              showFormErrors: showFormErrors,
              validator: dateValidationPattern
            })}
          </span>
        </div>

        <div className={'participants-form__wrapper'}>
          <span className={'participants-form--halfwidth'}>
            {createInput({
              name: 'firstname',
              label: 'Vorname',
              index: index,
              changeHandler: changeHandler,
              value: participant.firstname,
              type: 'text',
              required: true,
              parentId: parentId,
              showFormErrors: showFormErrors,
            })}
          </span>

          <span className={'participants-form--halfwidth'}>
            {createInput({
              name: 'middlenames',
              label: 'Zweiter Vorname',
              index: index,
              changeHandler: changeHandler,
              value: participant.middlenames,
              type: 'text',
              required: false,
              parentId: parentId,
              showFormErrors: showFormErrors,
            })}
          </span>
        </div>

        <div className={'participants-form__wrapper'}>
          <span className={'participants-form--fullwidth'}>
            {createInput({
              name: 'lastname',
              label: 'Nachname',
              index: index,
              changeHandler: changeHandler,
              value: participant.lastname,
              type: 'text',
              required: true,
              parentId: parentId,
              showFormErrors: showFormErrors,
            })}
          </span>
        </div>
      </div>
    </div>
  })
}


/**
 * creates form for each participant
 *
 * @param changeHandler
 * @return {*}
 */
export const createRenter = (participant, changeHandler, parentId, showFormErrors) => {

  const index = 0;
  return <div className={'participants-form__renter'} key={participant.id}>
    <div className={'participants-form__participant-content'}>
      <div className={'participants-form__wrapper'}>
        <span className={'participants-form--thirdwidth'}>
          <div className={'participants-form__item-wrapper'}>
            <Select label={'Anrede'}
                    options={[
                      {value: 'm', label: 'Herr'},
                      {value: 'w', label: 'Frau'},
                    ]}
                    name={getInputName('gender', index, 'offerContact', false)}
                    fullWidth={true}
                    required={true}
                    showError={showFormErrors}
                    onChange={changeHandler}
                    errorMessage={formErrorMessages['genderError']}
                    tooltipDirection={'top'}
                    parentContainerSelector={'#' + parentId}
                    initialValue={participant.gender ? participant.gender : ''}
                    additionalClasses={'checkout-contact__gender'}
            />
            <Select label={'Titel'}
                    options={[
                      {value: 'Dr.', label: 'Dr.'},
                      {value: 'Prof.', label: 'Prof.'},
                      {value: 'Prof. Dr.', label: 'Prof. Dr.'},
                      {value: 'Prof. Dr. Dr.', label: 'Prof. Dr. Dr.'},
                      {value: '', label: 'Titel'},
                    ]}
                    name={getInputName('title', index, 'offerContact', false)}
                    fullWidth={true}
                    required={false}
                    showError={showFormErrors}
                    onChange={changeHandler}
                    errorMessage={formErrorMessages['titleError']}
                    tooltipDirection={'top'}
                    parentContainerSelector={'#' + parentId}
                    initialValue={participant.title ? participant.title : ''}
                    additionalClasses={'checkout-contact__title'}
            />
          </div>
        </span>
      </div>

      <div className={'participants-form__wrapper'}>
        <span className={'participants-form--thirdwidth'}>
          {createInput({
            name: 'firstname',
            label: 'Vorname',
            index: index,
            changeHandler: changeHandler,
            value: participant.firstname,
            type: 'text',
            required: true,
            parentId: parentId,
            showFormErrors: showFormErrors,
            additionalClasses: 'checkout-contact__firstname',
            nameSpace: 'offerContact'
          })}
        </span>

        <span className={'participants-form--thirdwidth'}>
          {createInput({
            name: 'middlenames',
            label: 'Zweiter Vorname',
            index: index,
            changeHandler: changeHandler,
            value: participant.middlenames,
            type: 'text',
            required: false,
            parentId: parentId,
            showFormErrors: showFormErrors,
            additionalClasses: 'checkout-contact__middlenames',
            nameSpace: 'offerContact'
          })}
        </span>

        <span className={'participants-form--thirdwidth'}>
          {createInput({
            name: 'lastname',
            label: 'Nachname',
            index: index,
            changeHandler: changeHandler,
            value: participant.lastname,
            type: 'text',
            required: true,
            parentId: parentId,
            showFormErrors: showFormErrors,
            additionalClasses: 'checkout-contact__lastname',
            nameSpace: 'offerContact'
          })}
        </span>
      </div>

      <div className={'participants-form__wrapper'}>
        <span className={'participants-form--thirdwidth'}>
          {createInput({
            name: 'street',
            label: 'Strasse, Hausnummer',
            index: index,
            changeHandler: changeHandler,
            value: participant.street,
            type: 'text',
            required: true,
            parentId: parentId,
            showFormErrors: showFormErrors,
            additionalClasses: 'checkout-contact__street',
            nameSpace: 'offerContact'
          })}
        </span>

        <span className={'participants-form--thirdwidth'}>
          <div className={'participants-form__item-wrapper'}>
            {createInput({
              name: 'zip',
              label: 'PLZ',
              index: index,
              changeHandler: changeHandler,
              value: participant.zip,
              type: 'tel',
              required: true,
              parentId: parentId,
              showFormErrors: showFormErrors,
              additionalClasses: 'checkout-contact__zip',
              nameSpace: 'offerContact'
            })}
            {createInput({
              name: 'city',
              label: 'Stadt',
              index: index,
              changeHandler: changeHandler,
              value: participant.city,
              type: 'text',
              required: true,
              parentId: parentId,
              showFormErrors: showFormErrors,
              additionalClasses: 'checkout-contact__city',
              nameSpace: 'offerContact'
            })}
          </div>
        </span>
        <span className={'participants-form--thirdwidth'}>
          <Select label={'Land'}
                  options={[
                    {value: 'Deutschland', label: 'Deutschland'},
                    {value: 'Österreich', label: 'Österreich'},
                    {value: 'Schweiz', label: 'Schweiz'},
                  ]}
                  name={getInputName('country', index, 'offerContact', false)}
                  fullWidth={true}
                  required={true}
                  showError={showFormErrors}
                  onChange={changeHandler}
                  errorMessage={formErrorMessages['countryError']}
                  tooltipDirection={'top'}
                  parentContainerSelector={'#' + parentId}
                  initialValue={participant.country ? participant.country : ''}
                  additionalClasses={'checkout-contact__country'}
          />
        </span>
      </div>

      <div className={'participants-form__wrapper'}>
        <span className={'participants-form--halfwidth'}>
          <input type={'hidden'} name={getInputName('email', index, 'offerContact', false)} value={participant.email}/>
          {createInput({
            name: 'email',
            label: 'E-Mail',
            index: index,
            changeHandler: changeHandler,
            value: participant.email,
            type: 'text',
            required: true,
            parentId: parentId,
            showFormErrors: showFormErrors,
            disabled: true,
            additionalClasses: 'checkout-contact__email',
            nameSpace: 'offerContact'
          })}
        </span>

        <span className={'participants-form--halfwidth'}>
          {createInput({
            name: 'phone',
            label: 'Telefon',
            index: index,
            changeHandler: changeHandler,
            value: participant.phone,
            type: 'tel',
            required: false,
            parentId: parentId,
            showFormErrors: showFormErrors,
            additionalClasses: 'checkout-contact__phone',
            nameSpace: 'offerContact'
          })}
        </span>
      </div>
    </div>
    <input type={'hidden'} name={'partialUpdate'} value={false}/>
  </div>
};


/**
 * Create participant item
 * @param participants
 * @returns {*}
 */
export const createTravellerItemsList = (participants, blockName = 'participant') => {
  return participants.map(function (participant, index) {
    const wellFormatedDate = participant.birthdate && participant.birthdate.split('.').reverse().join('-');
    const participantDate = participant.birthdate && toGermanDateStr(wellFormatedDate, false);
    return (
      <div className={blockName} key={participant.id}>
        <div className={`${blockName}__name`}>
          <span className={'ts-bold'}>{index + 1}.</span><span
          className={`${blockName}__name--inline`}>{participant.firstname} {participant.middlenames} {participant.lastname}</span>
        </div>
        <div className={`${blockName}__birthdate`}>{participantDate || 'xx.xx.xxxx'}</div>
      </div>
    )
  })
}
