import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import FAQPage from "./portal/pages/faq/FAQPage";
import MainPagePortal from "./portal/pages/MainPagePortal";
import MainPageDigitalOffer from "./portal/pages/MainPageDigitalOffer";
import {
  ForgotScreen,
  LoginScreen,
  LogoutScreen,
  NewPasswordScreen,
  ResetScreen,
  VerificationScreen
} from "./portal/pages/login/LoginPage";
import "./App.scss";
import {ErrorPage} from "./portal/pages/error/ErrorPage";
import {NoOfferPage} from "./portal/pages/no-offer/NoOfferPage";
import Provider from "react-redux/es/components/Provider";
import store from "./portal/redux/store";
import withTitleChange from "./portal/hoc/WithTitleChange";
import {ErrorHandling} from "./report/ErrorHandling"
import {TimingHandling} from "./report/TimingHandling";
import {LegacyRouteTabRedirect} from "./portal/components/redirects/LegacyRouteTabRedirect";
import {AUTH_TYPES} from "./portal/components/login/LoginWrapper";

const mainPagePortal = withTitleChange(MainPagePortal);
const mainPageDigitalOffer = withTitleChange(MainPageDigitalOffer);
const faqPage = withTitleChange(FAQPage);
const loginPage = withTitleChange(LoginScreen);
const logoutPage = withTitleChange(LogoutScreen);
const resetPage = withTitleChange(ResetScreen);
const newPasswordPage = withTitleChange(NewPasswordScreen);
const verificationPage = withTitleChange(VerificationScreen);
const forgotPage = withTitleChange(ForgotScreen);
const noOfferPage = withTitleChange(NoOfferPage);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/faq/" component={faqPage}/>
          <Route path={`/${AUTH_TYPES.LOGIN}`} component={loginPage}/>
          <Route path={`/${AUTH_TYPES.LOGOUT}`} component={logoutPage}/>
          <Route path={`/${AUTH_TYPES.RESET}`} component={resetPage}/>
          <Route path={`/${AUTH_TYPES.NEW_PASSWORD}`} component={newPasswordPage}/>
          <Route path={`/${AUTH_TYPES.FORGOT}`} component={forgotPage}/>
          <Route path={`/${AUTH_TYPES.VERIFICATION}`} component={verificationPage}/>
          <Route path="/keine-reisen/" component={noOfferPage}/>
          <Route exact path={["/digitaloffer/routes/:offertoken/:routeId"]} component={LegacyRouteTabRedirect}/>
          <Route path={["/digitaloffer/:offertoken/:tab?/:hintId?"]} component={mainPageDigitalOffer}/>
          <Route exact path={["/", "/:offer/:tab/:hintId?"]} component={mainPagePortal}/>
          <Route component={ErrorPage}/>
        </Switch>
      </Router>
    </Provider>
  );
}

let siteBrand = 'portal-canusa';

if (window.config) {
  siteBrand = window.config.site + '-' + window.config.brand;
}

// Initialize error handling
window.errorHandling = new ErrorHandling({
  reportUrl: '/api/report.php/' + siteBrand + '/error'
});

window.timingHandling = new TimingHandling({
  reportUrl: '/api/report.php/' + siteBrand + '/timing'
});

export default App;
