import React, {Component} from 'react';
import './NoOfferPage.scss'
import withDataProvider from "../../hoc/WithDataprovider";
import Stage from "../../components/stage/Stage";
import Footer from "../../components/footer/Footer";
import classNames from "classnames";
import {Header} from "../../components/header/Header";
import {Link} from "../../components/link/Link";
import {PreloaderWrapper} from "../../components/preloader-wrapper/PreloaderWrapper";
import {Preloader} from "../../components/preloader/Preloader";

const StageWithDataProvider = withDataProvider(Stage, {
	useMock: true,
	endpoint: 'portal/stage',
	value: {topic: 'no_offer'}
});

class NoOfferPage extends Component {

	render() {

		if (this.props.isLoading) {
			return (
				<PreloaderWrapper classes={'optionals-content'}>
					<Preloader/>
				</PreloaderWrapper>
			);
		}

		const classes = classNames(
			'no-offer-page',
			'page-full-height'
		);

		return (
			<div className={classes}>
				<Header logoVariant={'portal'}/>
				<StageWithDataProvider withContent={false} join={true}/>
				<div className={'centered-content'}>
					<div className='box no-offer-page__box'>
						<h2 className={'no-offer-page__h1'}>Schön, dass Sie sich im CANUSA-Kundenportal angemeldet haben!</h2>

						<div className={'no-offer-page__text-block'}>
							<p>Damit wir es schon bald mit Leben füllen können, freuen wir uns über Ihre Anfrage.</p>
							<p>Auf <a href={'https://www.canusa.de'} target={'_blank'} className={'dotted'} rel="noopener noreferrer">www.canusa.de</a> finden Sie zahlreiche Reiseideen, die wir Ihnen gern nach Ihren Wünschen zusammen stellen und daraus Ihre persönliche Traumreise entstehen lassen.</p>
							<br/>
							<p>
								<strong>Sollten Sie noch weitere Fragen zu unserem Angebot haben, setzen Sie sich bitte direkt mit uns in Verbindung.</strong>
							</p>
						</div>

						<div className={'dotted'}></div>
						<br/>
						<h2 className={'no-offer-page__h2 ts-handwriting'}>CANUSA-Büro Hamburg</h2>

						<div className={'no-offer-page__column-wrapper'}>

							<div className={'no-offer-page__column'}>
								<div className={'no-offer-page__text-block'}>
									<h3>Öffnungszeiten</h3><br/>
									<p>
										Für eine Beratung vereinbaren Sie bitte vorab einen Termin.<br/>
										Alternativ 24 Stunden über das&nbsp;<Link
										href={'https://www.canusa.de/service/angebotsformular'}
										label={'Online-Formular'} target={'_blank'}/>.</p><br/>

									<p>Montag bis Freitag von 9:00 Uhr bis 18:00 Uhr</p><br/>
									<p><strong>28.10.2019 bis 28.03.2020</strong><br/>
										Montag&nbsp;von 9:00 Uhr bis 19:00 Uhr<br/>
										Dienstag bis Freitag von 10:00 Uhr bis 19:00 Uhr
									</p><br/>
									<p><strong>Gesonderte Öffnungszeiten am Samstag:</strong><br/>
										Oktober, November, Dezember und März von 10:00 bis 14:00 Uhr<br/>
										Januar und Februar von 9:00 Uhr bis 14:00 Uhr<br/><br/>
										Am 24. und 31.12.2019 sowie am 08.02.2020 bleibt unser Büro geschlossen.
									</p>
								</div>
							</div>

							<div className={'no-offer-page__column'}>
								<div className={'no-offer-page__text-block'}>
									<h3>Kontakt</h3><br/>

									<p><strong>CANUSA TOURISTIK GmbH & Co. KG</strong><br/>
										Nebendahlstr. 16<br/>
										22041 Hamburg</p><br/>

									<p>Telefon: <Link href={'tel:040 22 72 53 0'} label={'(040) 22 72 53 0'}
													  underline={false}/><br/>
										Fax: (040) 22 72 53 53<br/>
										E-Mail: <Link href={'mailto:ham@canusa.de'} label={'ham@canusa.de'}
													  underline={false}/><br/>
									</p>
								</div>
							</div>

						</div>
					</div>
				</div>

				<Footer/>

			</div>
		);
	}
}

export {
	NoOfferPage as default,
	NoOfferPage
}
