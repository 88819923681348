import React, {Component, Fragment} from 'react';
import './ItemSelect.scss'
import PropTypes from "prop-types";
// HELPER
import {getSmallPaddedFormatDate, getWeekday} from "../../../../vendor/utils/DateUtils";
import classNames from "classnames";
// COMPONENTS
import {Badge} from "../../badge/Badge";
import ContactBoxContainer from "../../contactbox/ContactBoxContainer";
import {ItemSelectOptionDA as ItemSelectOption} from "./ItemSelectOption";
import OfferInfo from "../../offerInfo/OfferInfo";

class ItemSelect extends Component {

	buildDisclaimerText(permissions) {
		return permissions ? permissions.bookableReason : 'Bitte kontaktieren Sie Ihren Reiseberater, um Ihren gewünschten Ausflug zu buchen.';
	}

	/**
	 * Return amount of event in all options
	 *
	 * @param 	{Object}	options
	 * @return 	{number} 	counter
	 */
	getEventCount(options) {
		let counter = 0;

		for (let option of options) {
			if (option.times) {
				counter += option.times.length;
			} else {
				counter += 1;
			}
		}

		return counter;
	}

	/**
	 * Return JSX for one option entry
	 *
	 * @param {string}    event
	 * @param {number}    price
	 *
	 * @return {*}    JSX
	 */
	renderEvent(price, date, time, parentId) {

		const optionId = (time ? date + 'T' + time : date).split(':').join('');
		const {id:itemId, selected:itemSelected, date:itemDate, time:itemTime} = this.props.item;

		const selected = itemSelected && itemDate === date && itemTime === time;

		return <Fragment key={optionId}>
			<ItemSelectOption optionId={optionId}
							  selected={selected}
												itemId={itemId}
												price={price}
												date={date}
												time={time}
												readOnly={this.props.readOnly}
												parentId={parentId}/>
		</Fragment>
	}

	/**
	 * Difference between multiple and single select options
	 *
	 * @param option
	 * @return {*}
	 */
	renderEvents(option, parentId) {

		if (option.times) {
			return (
				option.times.map((opt) => {

					let timeString = opt.time === "unknown" ? getSmallPaddedFormatDate(option.date) : opt.time;
					// TODO: mit den Daten des alten Endpoints, wird das Datum doppelt angezeigt. Hier könnte ein Text "Vor Ort erfragen" helfen
					return this.renderEvent(opt.price, option.date, timeString, parentId);

				})
			)
		} else {
			return this.renderEvent(option.price, getSmallPaddedFormatDate(option.date), option.date, parentId)
		}
	}

	render() {

		const classes = classNames('item-select', this.props.additionalClasses);
		return (
			<div className={classes}>
				<div className={'item-select__head'}>
					<div className={'item-select__label h2 tt-none'}>{this.props.item.label}</div>
					<div className={'item-select__avail ts-uppercase'}>{this.getEventCount(this.props.options)} Termine verfügbar
					</div>
					<div className={'item-select__disclaimer'}>
						{(this.props.readOnly || !(this.props.permissions && this.props.permissions.bookable)) &&
						<div className={'item-select__disclaimer-wrapper'}>
							<Badge label={'Wichtiger Hinweis'} type={"signal"} inverted={true} icon={'circle-info'} additionalClasses={'item-select__disclaimer-icon'}/>
							<span className="item-select__disclaimer-text">{this.buildDisclaimerText(this.props.permissions)}</span>
						</div>
						}
						<div className={'item-select__contact-wrapper'}>
							<ContactBoxContainer appearance={'modal'}
																	 additionalClasses={'item-select__contact'}
							/>
						</div>
						<OfferInfo offerNumber={this.props.offerNumber} hidePrintButton={true}/>
					</div>
				</div>

				<div className={'item-select__dates'}>
					{this.props.options && this.props.options.map((option, index) => {
						const key = `option-${index}`;
						const day = option.date.length === 10 ? getWeekday(option.date) + '., ' : '';
						const formattedDate = option.date.length === 10 ? getSmallPaddedFormatDate(option.date) : option.date;
						return (
							<div className={'item-select__options-section'} key={key}>
								<div className={'item-select__date h4 tt-none'}>{`${day}${formattedDate}`}</div>
								<div className={'item-select__options'}>
									{this.renderEvents(option, this.props.parentId)}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}

ItemSelect.propTypes = {


	item: PropTypes.object,

	/**
	 * REQUIRED: an array with options inside
	 *
	 * @example:
	 *       [
	 *       	{date: "2019-08-29"
	 *			times: [
	 *				{price: 118, time: "14:00-15:30"}
	 *				{price: 118, time: "15:00-16:30"}
	 * 				{price: 118, time: "16:30-18:00"}
	 *				{price: 118, time: "20:30-22:00"}]},
	 *        	{date: "2019-08-30"
	 *			times: [
	 *				{price: 118, time: "14:00-15:30"}
	 *				{price: 118, time: "15:00-16:30"}
	 *				{price: 118, time: "16:30-18:00"}
	 *				{price: 118, time: "20:30-22:00"}]}
	 *		]
	 *
	 */
	options: PropTypes.array.isRequired,
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,
	/**
	 * Shows disclaimer only in digitalOffer.
	 */
	showDisclaimer: PropTypes.bool,
	offerNumber: PropTypes.string,
};

export {
	ItemSelect as default,
	ItemSelect
}
