import {
	ADD_ITEM,
	ADD_ROUTE_ITEM,
	UPDATE_ITEMMAP,
	UPDATE_NOTICE
} from "../actions/itemManagementAction";
import {STORE_CLEAR} from "../actions/globalStateManagementAction";


const initialManagementState = {
	itemMap: {},
};

const extractItems = (items) => {
	let allItems = {};
	items.forEach(item => {
		allItems[item.id] = item;
	})
	return allItems;
}

const updateNotice = (itemMap, itemId, noticeValue) => {
	const item = itemMap[itemId];
	item.notice = noticeValue;
	return itemMap;
}

const itemManagementReducer = (state = initialManagementState, action) => {
	switch (action.type) {

		case ADD_ITEM:
			return {
				...state,
				itemMap: extractItems(action.items)
			};

		case ADD_ROUTE_ITEM:
			return {
				...state,
				routeItemMap: extractItems(action.items)
			};

		case UPDATE_ITEMMAP:
			return {
				...state,
				itemMap: action.data
			}

		case UPDATE_NOTICE:
			return {
				...state,
				itemMap: updateNotice({...state.itemMap}, action.id, action.noticeValue)
			}

		case STORE_CLEAR:
			return initialManagementState;

		default:
			return state
	}
};

export default itemManagementReducer;
