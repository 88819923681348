import React, {Component} from 'react';
import './FAQPage.scss'
import withDataProvider from "../../hoc/WithDataprovider";
import Stage from "../../components/stage/Stage";
import Faq from "../../components/faq/Faq";
import Footer from "../../components/footer/Footer";
import {Header} from "../../components/header/Header";
import classNames from "classnames";

const StageWithDataProvider = withDataProvider(Stage, {
	useMock: false,
	endpoint: 'stage',
	value: {topic: 'faq'}
});

class FAQPage extends Component {
	render() {
		const classes = classNames(
			'faq-page',
			'page-full-height'
		);

		return (
			<div className={classes}>
				<Header/>
				<StageWithDataProvider
					withContent={false}
					join={true}
					imageOptions={{direction: 'South'}} // TODO: remove after stage images have been updated in DB
				/>
				<div className={'centered-content'}>
					<Faq additionalClasses={'box'}/>
				</div>
				<Footer/>
			</div>
		);
	}
}

export {
	FAQPage as default,
	FAQPage
}
