import React from 'react';
import './ContactBox.scss';
import {Image} from "../image/Image";
import {Preloader} from "../preloader/Preloader";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Link} from "../link/Link";

function ContactBox(props) {
	/**
	 * Return signature with full name and label and open hours if template is detail
	 *
	 * @return {*} JSX
	 */
	function renderSignature() {

		const classes = classNames(
			'contact-box__name',
			{'h4': props.appearance === 'horizontal'},
			{'h2 ts-handwriting': (props.appearance === 'portrait')},
			{'h2 ts-handwriting': (props.appearance === 'modal')}
		);

		const fullName = props.fullName && <div className={classes}>{props.fullName}</div>;

		const description = props.label &&
			<div className="contact-box__description" key={"label"}>{props.label}</div>;
		const openHours = props.openHours &&
			<div className="contact-box__hours" key={"hours"}>{props.openHours}</div>;

		const detail = [description, openHours];

		return (
			<div className="contact-box__signature">
				{fullName}
				{detail}
			</div>
		);
	}

	/**
	 * Return contact options email and phone
	 *
	 * @return {*}
	 */
	function renderOptions() {
		return (
			<div className="contact-box__options">
				{props.phone &&
				<div className="contact-box__option">
					<Link url={'tel:' + props.phone.replace(new RegExp('[(|)|/|-]'), '')} icon={'phone'}
						  label={props.phone}/>
				</div>}
				{props.email &&
				<div className="contact-box__option">
					<Link url={'mailto:' + props.email} icon={'mail'} label={props.email}/>
				</div>}
			</div>
		)
	}

	/**
	 * Return contact image in a circle
	 *
	 * @return {*}
	 */
	function renderImage() {
		if (props.image) {
			return (
				<div className="contact-box__photo img-shadow">
					<Image url={props.image.url} alt={props.image.alt} ratio={{width: 1, height: 1}}
						   sizes={{default: '4rem'}} keepCropValue={true}/>
				</div>
			);
		}
	}

	const classes = classNames(
		'contact-box',
		{'contact-box--preloading': props.isLoading},
		`contact-box--${props.appearance}`,
		props.additionalClasses
	);
	
	if (props.isLoading) {
		return <Preloader type={'contactbox'}/>
	}
	
	return (
		<div className={classes}>
			{renderImage()}
			{renderSignature()}
			{renderOptions()}
		</div>
	)
}

ContactBox.propTypes = {
	/**
	 * **REQUIRED**: fullname of contact
	 *
	 * @example: 'Rebecca Musterfrau'
	 */
	fullName: PropTypes.string,

	/**
	 * OPTIONAL: job description of contact
	 *
	 * @example: 'Ihre persönliche Reiseberaterin'
	 */
	label: PropTypes.string,

	/**
	 * OPTIONAL: open hours of contact
	 *
	 * @example: 'Mo-Fr: 09:00 - 18:00'
	 */
	openHours: PropTypes.string,

	/**
	 * **REQUIRED**:  email of contact
	 *
	 * @example: 'rebecca.musterfrau@canusa.de
	 */
	email: PropTypes.string,

	/**
	 * **REQUIRED**:  phone of contact
	 *
	 * @example: '(08000) 22 68 72'
	 */
	phone: PropTypes.string,

	/**
	 * OPTIONAL: image object
	 *
	 * @example: {
	 * 	alt: "Ihre persönliche Reiseberaterin Rebecca Musterfrau"
	 * 	url: "https://images.canusa.de/img/allgemein/mitarbeiter/ham/staff-timmo-peggys-cove.cr805x1023-380x67.gray.jpg"+
	 *}
	 */
	image: PropTypes.shape({
		alt: PropTypes.string, // alt: "Ihre persönliche Reiseberaterin Rebecca Musterfrau"
		url: PropTypes.string // url: "https://images.canusa.de/img/allgemein/mitarbeiter/ham/staff-timmo-peggys-cove.cr805x1023-380x67.gray.jpg"
	}),

	/**
	 * OPTIONAL: define look of contactbox
	 * @default: 'portrait'
	 */
	appearance: PropTypes.oneOf(['portrait', 'horizontal', 'modal']),

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,
};

ContactBox.defaultProps = {
	appearance: 'portrait',
};

export default ContactBox;
