/**
 * compare two jsonStrings
 */
const jsonEqual = (a, b) => {
	return JSON.stringify(a) === JSON.stringify(b);
};

/**
 * deep copying of pure objects
 * use only with objects that don't have references to other instances!
 */
const deepCopyPureObject = (value) => {
	return JSON.parse(JSON.stringify(value));
};

/**
 * appending currency to price value
 * Format: € 122.334,-
 * @param value
 * @returns {string}
 */
const currencyFormat = (value) => {

	const formatter = new Intl.NumberFormat('de-DE', {
		style: 'currency',
		currency: 'EUR',
		minimumFractionDigits: 0
	});

	let format = formatter.format(value).replace('€', '');
	// remove &nbsp;
	format = format.trim() + '€';
	return format;

};

const getQueryVariable = (queryString, variable) => {

	let vars = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split('=');
		if (decodeURIComponent(pair[0]) === variable) {
			return decodeURIComponent(pair[1]);
		}
	}
};

const getFullName = (firstName, middleNames, lastName) => {
	return [firstName, middleNames, lastName].join(' ');
};

const checkTypeObject = (value) => {
	return (
		typeof value === 'object' &&
		!Array.isArray(value) &&
		value !== null
	)
}

const chunkArray = (arr, chunkLength) => {
	const chunks = [];
	let chunk = [];

	while (arr.length > 0) {
		const item = arr.shift()
		if (chunk.length < chunkLength - 1) {
			chunk.push(item);
		} else {
			chunk.push(item);
			chunks.push([...chunk]);
			chunk = []; // create nuw chunk
		}
		// make sure to push chunk if not complete
		if (arr.length === 0) {
			chunks.push([...chunk]);
		}
	}
	return chunks
}

const transparentPNG_1x1 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

const isEmpty = (object) => {
	return Object.keys(object).length === 0;
};

const mod = (n, m) => {
	return ((n % m) + m) % m;
};

const MOBILE_RATIO = {width: 16, height: 13}
const DESKTOP_RATIO = {width: 21, height: 8}

const tagManagerArgs = {
	gtmId: 'GTM-5TDVPWC'
}

const isDevEnvironment = () => (new RegExp('(localdev|lxdev|stage|test|dev|localhost)', 'gi').test(window.location.hostname));


const getUrlByEnvironment = () => {

	const subdomain = window.location.hostname.split('.')[0];
	const labelConfig = {
		portalLabel: 'mein.canusa.de',
		daLabel: 'angebot.canusa.de'
	};

	let config = {}
	switch (subdomain) {
		case 'meintest':
		case 'angebottest':
			config = {
				portalUrl: 'https://meintest.canusa.de',
				daUrl: 'https://angebottest.canusa.de'
			};
			break;
		case 'meindev':
		case 'angebotdev':
			config = {
				portalUrl: 'https://meindev.canusa.de',
				daUrl: 'https://angebotdev.canusa.de'
			}
			break;
		case 'meinstage':
		case 'angebotstage':
			config = {
				portalUrl: 'https://meinstage.canusa.de',
				daUrl: 'https://angebotstage.canusa.de'
			};
			break;
		case 'localhost':
			config = {
				portalUrl: '//localhost:3000',
				daUrl: '//localhost:3000'
			}
			break;
		default:
			config = {
				portalUrl: 'https://mein.canusa.de',
				daUrl: 'https://angebot.canusa.de'
			}
	}
	return Object.assign(config, labelConfig);
}

export {
	jsonEqual,
	deepCopyPureObject,
	currencyFormat,
	transparentPNG_1x1,
	getQueryVariable,
	getFullName,
	isEmpty,
	mod,
	checkTypeObject,
	chunkArray,
	MOBILE_RATIO,
	DESKTOP_RATIO,
	tagManagerArgs,
	getUrlByEnvironment,
	isDevEnvironment
}
