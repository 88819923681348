import React, {useState} from 'react';
import './CheckoutSummary.scss';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {createTravellerItemsList} from "../../../../vendor/utils/ParticipantsUtils";
import CheckoutNavigation from "../CheckoutNavigation";
import {getFullName} from "../../../../vendor/utils/Utils";
import {diffDateDays, getUtcSafeDate, toGermanDateStr} from "../../../../vendor/utils/DateUtils";
import {dispatchCheckoutGotoIndex} from "../../../redux/actions/checkoutManagementAction";
import {Icon} from "../../icon/Icon";
import Link from "../../link/Link";
import {FormValidator} from "../../form/FormValidator";
import {CHECKOUT_STEP_CONTACT, CHECKOUT_STEP_PARTICIPANTS} from "../Checkout";
import {getPriceOfActiveItems, getPriceOfGroups} from "../../../redux/selectors";
import {dispatchBookOffer} from "../../../redux/actions/saveOfferAction";
import ErrorMessage from "../../error/ErrorMessage";
import ContactBoxContainer from "../../contactbox/ContactBoxContainer";
import CheckoutPriceTable from "../CheckoutPriceTable";

function CheckoutSummary(props) {

	const [showFormErrors, setShowFormErrors] = useState(false);

	function handleSubmit({form, isValid}) {
		if (isValid) {
			setShowFormErrors(false);
			props.dispatchBookOffer(props.itemMap, props.totalPrice, form);
		} else {
			setShowFormErrors(true);
		}
	}

	function createRenterList(renterList) {
		return renterList.map((element) => {
			return (<div className={'checkout-summary__renter-item'} key={element.item.id}>
				<div className={'checkout-summary__renter-name'}>{element.renter.firstname} {element.renter.lastname}</div>
				<div className={'checkout-summary__vehicle'}>{element.item.displayTitle} ({toGermanDateStr(element.item.date)})
				</div>
			</div>)
		})
	}

	function createEditLink(checkoutStep) {
		return (
			<div className={'checkout-summary__edit'} onClick={() => {
				props.dispatchCheckoutGotoIndex(checkoutStep);
			}}>
				<Icon name={'pen'}/>
			</div>
		)
	}

	if(props.bookingComplete) {

		const leftDays = diffDateDays(getUtcSafeDate(props.journeyStart), getUtcSafeDate(new Date().getTime()));
		return (
			<div className='checkout-summary'>
				<div className='checkout-summary__booking-status'>
						<h1 className="tt-none ts-bold">Vielen Dank für Ihren Buchungsauftrag!</h1>
						<h3>Ihre Buchungsnummer lautet: {props.offerNumber}</h3>
						<p className="h4 tt-none">Wir haben Ihnen zusätzlich per Mail eine Eingangsbestätigung zu Ihrem
							Buchungsauftrag zukommen lassen.</p>
						<p>Ihre Daten werden jetzt final von mir überprüft. Ich werde Sie umgehend informieren, sobald
							das
							Angebot abschließend gebucht wurde.
							Sollten Sie in der Zwischenzeit noch Fragen haben oder weitere Anregungen benötigen, kontaktieren
							Sie
							mich gerne!</p>
				</div>
				<div className="checkout-summary__days-until h2 ts-handwriting">
					Übrigens: nur noch {leftDays} Tage bis zu Ihrer Traumreise!
				</div>
			</div>
		)
	}

	return (
		<div className={'checkout-summary'}>
			<h1 className="ts-bold tt-none">Buchung abschließen</h1>

			<div className={'checkout-summary__participants checkout-summary__box'}>
				{createEditLink(CHECKOUT_STEP_PARTICIPANTS)}
				<div className='checkout-summary__head'>
					<div className="checkout-summary__label h4 tt-none">Reiseteilnehmer</div>
					<div
						className="checkout-summary-participants__birthdate-label checkout-summary__label h4 tt-none">Geburtsdatum
					</div>
				</div>
				<div className='checkout-summary__body'>
					<div className={'checkout-summary-participants__list'}>
						{props.participantsList && createTravellerItemsList(props.participantsList, 'checkout-summary-participants')}
					</div>
				</div>

				<div className="checkout-summary-participants__disclaimer ts-bold">
					Bitte überprüfen Sie die oben angegebenen Namen aller Reiseteilnehmer auf Richtigkeit und
					Vollständigkeit. Es müssen alle Namen laut Reisepass sowie das Geburtsdatum angegeben
					werden.
				</div>
			</div>

			<div className={'checkout-summary__contact-info checkout-summary__box'}>
				{createEditLink(CHECKOUT_STEP_CONTACT)}
				<div className='checkout-summary__body'>
					<div className={'checkout-summary__contact'}>
						<div className="checkout-summary__contact-label checkout-summary__label h4 tt-none">Kontaktdaten des
							Buchenden
						</div>
						<div className={'checkout-summary__fullname'}>
							<strong>Name: </strong> {getFullName(props.offerContact.firstname, props.offerContact.middlenames, props.offerContact.lastname)}
						</div>
						<div className={'checkout-summary__email'}>
							<strong>E-Mail: </strong> {props.offerContact.email}
						</div>
						{props.offerContact.phone &&
						<div className={'checkout-summary__phone'}>
							<strong>Telefon: </strong> {props.offerContact.phone}
						</div>
						}
					</div>
					<div className={'checkout-summary__address'}>
						<div className="checkout-summary__address-label checkout-summary__label h4 tt-none">Adresse</div>
						<div className={'checkout-summary__street'}>{props.offerContact.street}</div>
						<div
							className={'checkout-summary__city'}>{props.offerContact.zip} {props.offerContact.city}</div>
						<div className={'checkout-summary__country'}>{props.offerContact.country}</div>
					</div>
				</div>

				<div className='checkout-summary__renter-info'>
					<div className='checkout-summary__head'>
						<div className="checkout-summary__renter-label checkout-summary__label ts-bold">Mieter/Kreditkarteninhaber
						</div>
						<div className="checkout-summary__vehicle-label checkout-summary__label ts-bold">Mietfahrzeug</div>
					</div>
					<div className={'checkout-summary__body'}>
						<div className={'checkout-summary__renter-list'}>
							{createRenterList(props.rentersList)}
						</div>
					</div>
				</div>
			</div>
				<CheckoutPriceTable/>
			<FormValidator additionalClasses="checkout-contact-wrapper__form"
										 onSubmit={handleSubmit}
										 render={({changeHandler, formIsValid}) => (
											 <>
												 {props.bookingError && <ErrorMessage>
														 <h2 className={'error-message__h2'}><Icon name={'warning'} additionalClasses={'error-message__icon'}/>Es ist ein Fehler aufgetreten.</h2>
														 <div className={'error-message__text-block'}>
															 <p>Leider haben wir gerade mit technischen Schwierigkeiten zu kämpfen und können Ihnen
																 unseren Service derzeit nicht zur Verfügung stellen.</p>
															 <p>Wir arbeiten bereits an einer Lösung.
																 Sollte das Problem weiterhin bestehen, rufen Sie mich bitte an.</p>
															 <ContactBoxContainer appearance={'modal'}/>
														 </div>
												 </ErrorMessage>}
												 <CheckoutNavigation checkoutStep={props.checkoutStep}
																						 onChange={changeHandler}
																						 formHasError={showFormErrors}
																						 isLoading={props.bookingIsLoading}/>
											 </>
										 )}
			/>

			<div className="booking-immigration-info">
				Hier finden Sie die aktuellen Einreisebestimmungen für <Link
				url={'https://www.canusa.de/service/einreisebestimmungen-kanada'} target={'_blank'}
				additionalClasses={'ts-bold'} label={'Kanada'}/> und die <Link
				url={'https://www.canusa.de/service/einreiseformulare-usa'} target={'_blank'}
				additionalClasses={'ts-bold'}
				label={'USA'}/>.
			</div>
		</div>
	);
}

CheckoutSummary.propTypes = {
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string
};

CheckoutSummary.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
	return {
		participantsList: state.participantManagement.list,
		offerContact: state.userProfileManagement,
		rentersList: state.rentersManagement.list,
		itemMap: state.itemManagement.itemMap,
		totalPrice: getPriceOfGroups(state.offerContentManagement.offerItems) + getPriceOfActiveItems(state.itemManagement.itemMap),
		offerNumber: state.globalStateManagement.offer.offerNumber,
		journeyStart: state.globalStateManagement.journeyStart,
		bookingComplete: state.checkoutManagement.bookingSuccess,
		bookingIsLoading: state.checkoutManagement.bookingIsLoading,
		bookingError: state.checkoutManagement.bookingError,
	}
};

export default connect(mapStateToProps, {
	dispatchCheckoutGotoIndex: dispatchCheckoutGotoIndex,
	dispatchBookOffer: dispatchBookOffer
})(CheckoutSummary);
