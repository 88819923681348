import React, {Component, Fragment} from 'react';
import './ItemDetails.scss'
import {Gallery} from "../../gallery/Gallery";
import {HtmlText} from "../../htmlText/HtmlText";
import {TabNavigation} from "../../tabnavigation/TabNavigation";
import {Tab} from "../../tabnavigation/tab/Tab";
import {Modal} from "../../modal/Modal";
import PropTypes from "prop-types";
import {InfoHeader} from "./info-header/InfoHeader";
import {ReactComponent as FallbackImage} from '../../../../assets/static/map_dots_modal-default.svg'
import classNames from "classnames";
import {deepCopyPureObject} from "../../../../vendor/utils/Utils";
import Alternatives from "../../alternatives/Alternatives";
import {StaticMap} from "./static-map/StaticMap";
import {Button, BUTTON_WIDTH, BUTTON_APPEARANCE} from "../../button/Button";
import AddonTab from "./tabs/AddonTab";
import {getActiveItem} from "../../../redux/selectors";

class ItemDetails extends Component {

  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSelectedItemChange = this.onSelectedItemChange.bind(this);

    this.state = {
      selectedTabId: this.getTabId(props.item.tabs[0]),
      selectedTabsList: props.item.tabs,
      tabIdMap: this.createTabIdMap(props.item.tabs)
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (prevProps.item.id !== this.props.item.id) {
      const selectedTab = this.getTabId(this.props.item.tabs[0]);
      const tabIdMap = this.createTabIdMap(this.props.item.tabs);

      this.setState({
        selectedTabId: tabIdMap[selectedTab.type] || this.getTabId(this.props.item.tabs[0]), // get current tabId if available
        selectedTabsList: this.props.item.tabs,
        tabIdMap: tabIdMap
      })
    }

    if (prevProps.item.tabs !== this.props.item.tabs) {
      this.setState({
        selectedTabsList: this.props.item.tabs
      })
    }
  }

  /**
   * updating the radioGroupContent
   * @param id
   */
  onChangeHandler(id) {
    this.setState({
      selectedTabId: id
    })
  }


  /**
   * create map with tabType & tabId
   * @param tabs
   * @returns {{}}
   */
  createTabIdMap(tabs) {
    const tabIdMap = {}
    tabs.forEach((tab) => {
      tabIdMap[tab.type] = this.getTabId(tab);
    })

    return tabIdMap;
  }

  /**
   * create tab identifier
   * @param tab
   * @returns {string}
   */
  getTabId(tab) {
    return `${this.props.parentId}-${tab.label}`
  }

  /**
   * Filter tabs by selectedTabId and return selected tab object
   *
   * @param selectedTabId
   * @param tabs
   * @return {*}
   */
  getSelectedTab(selectedTabId, tabs) {
    return tabs.filter((tab) => {
      return this.getTabId(tab) === selectedTabId;
    })[0];
  }

  checkAlternatives(item) {
    if (this.props.itemMap) {
      return getActiveItem(this.props.itemMap, this.props.alternativeList || []) || item;
    }
    return item;
  }

  /**
   * render Content in Modal instead of Tabs
   * @returns {null}
   */
  renderMobileDetail() {
    let mobileDetail = null;
    const item = this.checkAlternatives(this.props.item);
    if (!this.props.isDesktop) {

      if (item.location?.label && item.contentType !== 'extra') {
        mobileDetail = <>
          {(item.location.lat && item.location.lng) && <StaticMap item={item}/>}
          <InfoHeader item={item} type={this.props.type}/>
          {this.props.alternativeList && <Alternatives alternativeList={this.props.alternativeList}
                                                       isExpanded={true}
                                                       sliderId={'alternatives-' + this.props.parentId}
                                                       onChange={(id) => {
                                                         this.onSelectedItemChange(id, this.props.itemMap)
                                                       }}
          />}
        </>

      } else {
        mobileDetail = <div className={'item-details__fallback-image'}>
          <FallbackImage/>
        </div>
      }
    }
    return mobileDetail
  }

  /**
   * find matching tabListById
   * @param id
   */
  onSelectedItemChange(id, itemMap) {
    this.props.alternativeList.forEach(itemId => {
      const item = itemMap[itemId]
      if (item.id === id) {
        this.setState({
          selectedTabsList: item.tabs
        })
      }
    })
  }

  renderPrintLabel(tab){
    return <div className={'item-details__print-label'} key={tab.label}>
      {tab.label}:
    </div>
  }

  /**
   * create content as Tab
   * @param id
   * @param tabs
   * @param hasSingleImage
   * @returns {string|*}
   */
  renderTabContentFactory(id, tabs, hasSingleImage, isPrintView) {
    const selectedTab = this.getSelectedTab(id, tabs);

    if (selectedTab) {
      switch (selectedTab.type) {
        case "html":
          return this.renderHTMLTab(selectedTab, hasSingleImage, isPrintView);
        case "itinerary":
          return this.renderItineraryTab(selectedTab, isPrintView);
        case "images":
          return this.renderImageTab(selectedTab, isPrintView);
        case "addons":
          return this.renderAddonsTab(selectedTab, isPrintView);
        default:
          return (`${selectedTab.type} are not defined as type in item-details`)
      }
    }
  }

  /**
   * Render Image Tab
   * @param tab
   * @returns {*}
   */
  renderImageTab(tab, isPrintView) {
    return <>
      {isPrintView && this.renderPrintLabel(tab)}
      <Gallery images={tab.images} isPrintView={isPrintView}/>
    </>
  }

  /**
   * Render HTML Tab
   * @param tab
   * @param hasSingleImage
   * @returns {*}
   */
  renderHTMLTab(tab, hasSingleImage, isPrintView) {
    const classes = classNames(
        'item-details__section',
        {'item-details__single-image-text': hasSingleImage && !this.props.hasAlternatives}
    );

    let content = [];
    let textContent = [];

    if (!tab.content) {
      return null
    }

    tab.content.forEach((section, index) => {
      if (section.type === 'singleImage') {
        content.push(
            <div className={'item-details__section item-details__single-image'}
                 key={section.type + '-' + index}>
              <Gallery images={section.images}/>
            </div>
        )
      } else {
        if (section.text) {



          textContent.push(
              // removing the given headline "Leistung" from details description
              <div className={classes} key={section.type + '-' + index}>
                {(section.type !== 'summary' && section.type !== 'leistung') &&
                    <div className={'ts-bold'}>{section.type !== 'summary' && section.label}:</div>
                }

                <HtmlText>{section.text}</HtmlText>
              </div>
          )
        }
      }
    });

    if (tab.hasOwnProperty('description')) {
      textContent.push(<div className={classes} key={'description'}><HtmlText>{tab.description}</HtmlText></div>)
    }

    if (this.props.isDigitalOffer && tab.hasOwnProperty('participants') && this.props.offerParticipants && (tab.participants.length !== this.props.offerParticipants.length)) {
      const reducedList = tab.participants.map((participantId) => {
        const person = [];
        const obj = this.props.offerParticipants.find((item) => item.id === participantId) || {};
        obj.firstname && person.push(obj.firstname);
        obj.middlenames && person.push(obj.middlenames);
        obj.lastname && person.push(obj.lastname);

        return person.join(' ');
      })

      textContent.push(
          <div key={'participants'} className={'item-details__participants-list'}>
            <b>Reiseteilnehmer:</b> {reducedList.join(', ')}
          </div>
      )
    }

    if (this.props.isDigitalOffer && this.state.tabIdMap['addons']) {
      textContent.push(
          <div key={'addon-tab-button'} className={'item-details__addon-tab-button'}>
            <Button width={BUTTON_WIDTH.FIT}
                    appearance={BUTTON_APPEARANCE.SECONDARY}
                    label={'Zusatzleistungen buchen'}
                    additionalClasses={'no-print'}
                    onClick={() => {
                      this.setState({
                        selectedTabId: this.state.tabIdMap['addons']
                      })
                    }
                    }/>

            <small className={'no-print'}>Buchen Sie hier zusätzliche Wohnmobil Leistungen.<br/>
              {this.getAvailableAddonsList(this.state.tabIdMap['addons'], this.state.selectedTabsList)}</small>
          </div>
      )
    }

    if (this.props.isDigitalOffer && this.state.tabIdMap['itinerary']) {
      // TODO: unterscheidung zwischen vorläufigem und finalem flugplan?
      const flightText = this.props.isDesktop ? 'Sehen Sie sich hier den vorläufigen Flugplan an' : 'Zum vorläufigen Flugplan';
      textContent.push(
          !isPrintView && <div key={'itinerary-tab-button'} className={'item-details__itinerary-tab-button'}>
            <Button width={BUTTON_WIDTH.FIT}
                    appearance={BUTTON_APPEARANCE.SECONDARY}
                    label={flightText}
                    onClick={() => {
                      this.setState({
                        selectedTabId: this.state.tabIdMap['itinerary']
                      })
                    }
                    }/>
          </div>
      )
    }
    if (hasSingleImage) {
      return (
          <div className={'item-details__single-image-wrapper'}>
            {content}
            <div className={'item-details__single-image-text-wrapper'}>
              {textContent}
            </div>
          </div>
      )
    } else {
      return textContent;
    }
  }

  renderItineraryTab(tab, isPrintView) {
    const classes = classNames(
        'item-details__section item-details__itinerary',
    );
    let textContent = [];

    if (!tab.content) {
      return null
    }

    isPrintView && textContent.push(this.renderPrintLabel(tab));
    tab.content.forEach((section, index) => {
      if (section.text) {
        textContent.push(
            <div className={classes} key={section.type + '-' + index}>
              <HtmlText>{section.text}</HtmlText>
            </div>
        )
      }
    });

    if (tab.hasOwnProperty('description')) {
      textContent.push(<div className={classes} key={'description'}><HtmlText>{tab.description}</HtmlText></div>)
    }

    return textContent;
  }

  /**
   * Render Addons Tab
   * @param tab
   * @returns {string}
   */
  renderAddonsTab(tab, isPrintView) {
    return (<>
      {isPrintView && this.renderPrintLabel(tab)}
      <AddonTab tab={tab}
                tabContent={JSON.stringify(tab.content)} //pseudo prop to force rerender because of nested data
                parentId={this.props.parentId}
                itemId={this.props.item.id}
                isDesktop={this.props.isDesktop}
                isDigitalOffer={this.props.isDigitalOffer}
      />
    </>)
  }

  /**
   * Render Print Item Details
   * @param tabs
   * @returns {*}
   */
  renderPrintItemDetails(tabs, hasSingleImage) {
    return (
        <div className={'item-details__content item-details__content--print'}>
          {tabs.map((tab, index) => {
            return (
                <Fragment key={tab.label + '-' + index}>
                  {this.renderTabContentFactory(this.getTabId(tab), tabs, hasSingleImage, true)}
                </Fragment>
            )
          })}
        </div>
    )
  }

  /**
   * return string with available addons
   * @param selectedTabId
   * @param tabs
   * @param maxLength
   * @returns {string|null}
   */
  getAvailableAddonsList(selectedTabId, tabs, maxLength = 3) {
    const list = [];
    const contentList = this.getSelectedTab(selectedTabId, tabs).content;
    contentList.forEach((item) => {
      if (item.selected === 0 && list.length < maxLength) {
        list.push(item.name)
      }
    })

    return (list.length > 0) ? `z.B.: ${list.join(', ')}` : null;
  }


  /**
   * summarize features & images if only one image is available
   * do not show single images if alternatives are available
   * @param tabs
   * @param hasAlternatives
   * @returns {{tabs: (*), hasSingleImage: boolean}}
   */
  summarizingTabContent(tabs, hasAlternatives) {
    let returnValue = null;

    // get single imageObject
    const singleImageTab = tabs.find((tab) => {
      return (tab.type === 'images' && tab.images.length === 1)
    });

    // get featureContent
    if (singleImageTab && !hasAlternatives) {

      let featureContentTab = deepCopyPureObject(tabs.find((tab) => {
        return (tab.type === 'html' && tab.content.length >= 1)
      }));

      const imageObj = {
        type: 'singleImage',
        images: [
          {
            alt: singleImageTab.images[0].alt,
            url: singleImageTab.images[0].url
          }
        ]
      };

      featureContentTab.content.unshift(imageObj);

      returnValue = tabs.filter((tab) => {
        return (tab.type !== 'html' && tab.type !== 'images')
      });

      returnValue.unshift(featureContentTab);
    }

    return {tabs: returnValue || tabs, hasSingleImage: !!singleImageTab}
  }

  /**
   * Render Item Content
   * @returns {*}
   */
  renderItemContent() {
    const tabsObject = this.summarizingTabContent(this.state.selectedTabsList, this.props.hasAlternatives);
    // render tabnavigation only if more than one tab is available
    if (tabsObject.tabs.length > 1) {

      return (
          <div className={'item-details'}>
            <TabNavigation additionalClasses='item-details__navigation'>


              {tabsObject.tabs.map((tab, index) => {
                const tabId = this.getTabId(tab);
                return (
                    <Tab id={tabId} key={this.getTabId(tab) + index}
                         name={'itemdetailtab-' + this.props.parentId}
                         isSelected={tabId === this.state.selectedTabId}
                         onChange={this.onChangeHandler}
                    >
                      <div className={'item-details__tab h5'}>{tab.label}</div>
                    </Tab>
                );
              })}
            </TabNavigation>

            <div className={'item-details__content'} key={this.state.selectedTabId}>
              {this.renderTabContentFactory(this.state.selectedTabId, tabsObject.tabs, tabsObject.hasSingleImage)}
            </div>

            {this.props.isDesktop && this.renderPrintItemDetails(tabsObject.tabs)}

          </div>);
    } else {
      return (
          <div className={'item-details'}>
            <div className={'item-details__content'} key={this.state.selectedTabId}>
              {this.renderTabContentFactory(this.state.selectedTabId, tabsObject.tabs, tabsObject.hasSingleImage)}
            </div>
            {this.props.isDesktop && this.renderPrintItemDetails(tabsObject.tabs, tabsObject.hasSingleImage)}
          </div>
      )
    }
  }

  /**
   * Render inner Content of Tab/Modal
   * @returns {null|*}
   */
  renderInnerContent() {
    if (!this.props.isDesktop) {
      if (this.props.isOpen) {
        return <Modal
            additionalClasses={'modal--item__detailview'}
            uid='modal'
            key='modal'
            size={'fullsize'}
            theme={'medium'}
            onClose={this.props.closeHandler}
            isOpen={this.props.isOpen}
        >
          <div className={'content'} id={'modal-addon-' + this.props.parentId}>
            {this.renderMobileDetail()}
            {this.renderItemContent()}
          </div>
        </Modal>
      } else {
        return null;
      }
    }

    return this.renderItemContent();
  }

  render() {
    return (
        this.renderInnerContent()
    )
  }
}

ItemDetails.defaultProps = {};

ItemDetails.propTypes = {
  /**
   * selector of the wrapping container: passthrough the selector to the tooltip
   * reason: when set tooltip position is fixed that it can be displayed inside of an overflow hidden container.
   */
  parentId: PropTypes.string.isRequired,
  /**
   * toggles the visibility of the detail
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * switch between tabs & modal
   */
  isDesktop: PropTypes.bool.isRequired,
  /**
   * closehandler for modal (mobile Viewport only)
   */
  closeHandler: PropTypes.func.isRequired,
  /**
   * content of ItemDetail.
   * see **Item** for further information
   */
  item: PropTypes.shape(),
  /**
   * render detailInformations depending on type:
   * the follwing types don't show detailInformationBoxes:
   * 'seat-reservation', 'insurance'
   */
  type: PropTypes.string
};

export {
  ItemDetails as default,
  ItemDetails
}

