import React, {Component} from 'react';
import './LogoutMask.scss';
import {LinkInternal} from "../link/LinkInternal";

import {Redirect} from "react-router-dom";
import {storageManager} from "../../services/StorageManager";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {dispatchClearStore} from "../../redux/actions/globalStateManagementAction";

class LogoutMask extends Component {

	constructor(props) {
		super(props);
		this.state = {errorMessage:null, loggedOut: false};
		this.dataProvider = this.props.dataProvider;
	}

	/**
	 * call logout service to logout
	 */
	componentDidMount() {
		this.dataProvider.setData(new FormData()).then(response => {
			if(response.ok === "ok") {
				storageManager.authToken = null;
				if(this.props.dispatchClearStore){
					this.props.dispatchClearStore();
				}
				this.setState({loggedOut:true});
			} else {
				this.setState({errorMessage:"Ein unbekannter Fehler ist aufgetreten"})
			}
		});
	}

	render() {

		if(this.state.loggedOut && this.props.redirectTo) return <Redirect to={this.props.redirectTo}/>;

		if(this.state.errorMessage) return (
			<div className="login_wrapper__mask-content">
				<h2 className='login_wrapper__headline'>Fehler</h2>

				<p className='login_wrapper__error_text'>{this.state.errorMessage}</p>

				<LinkInternal label='Im Portal anmelden' linkPath='/login' additionalClasses="login_wrapper__link"/>
			</div>
		);

		return (

			<div className="login_wrapper__mask-content">
				<h2 className='login_wrapper__headline'>Sie werden gerade abgemeldet</h2>

				<p className='login_wrapper__text'>Bitte haben sie einen Moment Geduld.</p>
			</div>
		)
	}
}
LogoutMask.propTypes = {
	/**
	 * instance of LoginService
	 */
	dataProvider:PropTypes.object
};

export default connect(null, {dispatchClearStore:dispatchClearStore})(LogoutMask);
