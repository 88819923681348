import React, {Component} from 'react';
import './ItemHeaderDocuments.scss'
import PropTypes from "prop-types";
import {
	renderBadge,
	renderDate,
	renderDownloadLink,
	renderDuration,
	renderHorizontalLine,
	renderTitle,
	renderTitleConfigVO,
	renderTypeIcon
} from "../ItemHeaderUtils";
import classNames from "classnames";

class ItemHeaderDocuments extends Component {

	constructor(props) {
		super(props);

		this.showSelectModal = this.showSelectModal.bind(this);

		this.state = {
			showSelectModal: false,
		}
	}

	/**
	 * set showSelectModal state to show or hide modal
	 *
	 * @param {boolean} show
	 */
	showSelectModal(show) {
		this.setState({
			showSelectModal: show
		})
	}

	renderItemHeader() {

		const configVO = new renderTitleConfigVO();
		configVO.label = this.props.item.label;
		configVO.location = this.props.item.location;
		configVO.rating = this.props.item.rating;
		configVO.type = this.props.item.type;

		const classes = classNames(
			'item-header__grid'
		);

		if (this.props.item.date) {
			return (
				<>
					{renderTypeIcon(this.props.item.type)}
					<div className={classes}>
						<label htmlFor={this.props.collapsibleId} className={'item-header__clickarea'}></label>
						<div className={'item-header__top'}>
							<div className={'item-header__top-left'}>
								{renderDate(this.props.item.date)}
								{this.props.isDesktop && renderDuration(this.props.item.duration)}
							</div>
							<div className={'item-header__top-right'}>
								{renderBadge(this.props.item.link?.state)}
								{this.props.isDesktop && renderDownloadLink(
									this.props.item.link,
									this.props.isDesktop,
									this.props.isDesktop ? 'left' : 'top-left'
								)}
							</div>
						</div>

						{renderHorizontalLine()}

						<div className={'item-header__middle'}>
							<div className={'item-header__middle-left'}>
								{renderTitle(configVO)}
							</div>
							<div className={'item-header__middle-right'}>
							</div>
						</div>

						{(!this.props.isDesktop) &&

						<div className={'item-header__bottom'}>
							<div className={'item-header__bottom-left'}>
								{renderDuration(this.props.item.duration)}
							</div>
							<div className={'item-header__bottom-right'}>
								{!this.props.isDesktop && renderDownloadLink(
									this.props.item.link,
									this.props.isDesktop,
									this.props.isDesktop ? 'left' : 'top-left'
								)}
							</div>
						</div>
						}
					</div>
				</>
			);
		} else {
			return (
				<>
					{renderTypeIcon(this.props.item.type)}
					<div className={classes}>

						<div className={'item-header__middle'}>
							<div className={'item-header__middle-left'}>
								{renderTitle(configVO)}
							</div>
							<div className={'item-header__middle-right'}>
								{renderBadge( this.props.item.link?.state)}
								{this.props.isDesktop && renderDownloadLink(
									this.props.item.link,
									this.props.isDesktop,
									this.props.isDesktop ? 'left' : 'top-left'
								)}
							</div>
						</div>

						{!this.props.isDesktop &&
						<div className={'item-header__bottom'}>
							<div className={'item-header__bottom-left'}>
							</div>
							<div className={'item-header__bottom-right'}>
								{renderDownloadLink(this.props.item.link,
									this.props.isDesktop,
									this.props.isDesktop ? 'left' : 'top-left'
								)}
							</div>
						</div>
						}
					</div>
				</>
			);
		}
	}

	render() {
		return (
			this.renderItemHeader()
		);
	}
}

ItemHeaderDocuments.defaultProps = {
	isCollapsible: false,
};

ItemHeaderDocuments.propTypes = {

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,

	/**
	 * defines if header has collapsible funtionality
	 *
	 * @default: false
	 */
	isCollapsible: PropTypes.bool,

	/**
	 * context of use has influence on itemHeaderTemplate
	 */
	context: PropTypes.oneOf(['documents', 'offer', 'optionals']),
	/**
	 *
	 * see **Item** for further information
	 */
	item: PropTypes.shape({
		/**
		 * template of ItemHeader
		 * @example: 'main'
		 *
		 */
		contentType: PropTypes.oneOf(['main', 'extra', 'optional']),
		/**
		 * label used in ItemHeader
		 * @example: 'Mariner King Inn'
		 */
		label: PropTypes.string,

		/**
		 * location.label used in ItemHeader
		 * @example: {label:'Lighthouse Trail, NS', lat:34.2618964, lng:-116.9194283}
		 */
		location: PropTypes.shape(
			{
				/**
				 * Location label
				 * @example: 'Lighthouse Trail, NS'
				 */
				label: PropTypes.string,

				/**
				 * Latitude of location
				 * @example: 34.2618964
				 */
				lat: PropTypes.number,

				/**
				 * Longitude of location
				 * @example: -116.9194283
				 */
				lng: PropTypes.number,
			}
		),

		/**
		 * OPTIONAL: an array with options inside
		 *
		 * @example:
		 *       [
		 *        {date: "2019-08-29",
		 *			times: [
		 *				{price: 118, time: "14:00-15:30"}
		 *				{price: 118, time: "15:00-16:30"}
		 * 				{price: 118, time: "16:30-18:00"}
		 *				{price: 118, time: "20:30-22:00"}]},
		 *            {date: "2019-08-30"
		 *			times: [
		 *				{price: 118, time: "14:00-15:30"}
		 *				{price: 118, time: "15:00-16:30"}
		 *				{price: 118, time: "16:30-18:00"}
		 *				{price: 118, time: "20:30-22:00"}]}
		 *        ]
		 *
		 */
		options: PropTypes.array,
	})
};

export {
	ItemHeaderDocuments as default,
	ItemHeaderDocuments
}
