import React from 'react';
import './Link.scss'
import {Icon, ICON_SIZE} from "../icon/Icon";
import classNames from "classnames";
import PropTypes from "prop-types";

function Link (props){

	const classes = classNames(
		'link',
		`link--${props.size}`,
		{'disabled': (props.disabled || props.isLoading)},
		props.additionalClasses,
	);

	const icon = props.icon && <Icon additionalClasses={'link__icon'} name={props.icon} size={ICON_SIZE.M} />;
	const label = props.label &&
		<span className={classNames('link__label', {'dotted': props.underline})}>{props.label}</span>;

	//backward compatibility for components with prop linkPath and href
	const url = props.linkPath || props.href || props.url;
	return (
		<>
			{props.withoutAnchor
				?
				<span className={classes} onClick={props.onClick}>{icon}{label}</span>
				:
				<a className={classes}
				   href={url}
				   title={props.title}
				   download={props.download}
				   target={props.target}
				   onClick={props.onClick}
				>
					{icon}{label}
				</a>
			}
		</>
	);
}

Link.propTypes = {
	/**
	 * Link URL
	 *
	 * @example: 'https://foo@bar.de' || '/foo/bar'
	 */
	url: PropTypes.string,

	/**
	 * Link Label
	 *
	 * @example: Link Label
	 */
	label: PropTypes.string,

	/**
	 * Link Icon
	 *
	 * Visit http://localhost:6060/#icon for more infos.
	 *
	 * @example: 'pdf'
	 */
	icon: PropTypes.string,

	/**
	 * Size of the link
	 * @default: default
	 */
	size:PropTypes.oneOf(['default', 'small']),

	/**
	 * Link Title (leads to browser specific tooltip)
	 */
	title: PropTypes.string,

	/**
	 * @default '_self'
	 */
	target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),

	/**
	 * Link with (dotted) underline
	 *
	 * @default: true
	 */
	underline: PropTypes.bool,

	/**
	 * Name of downloaded file, forces file to download if set
	 */
	download: PropTypes.string,

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,

	/**
	 * disabled
	 * @default: false
	 */
	disabled: PropTypes.bool,

	/**
	 * isLoading
	 * adds an icon with loading animation and set link to disabled
	 * @default: false
	 */
	isLoading: PropTypes.bool,

	/**
	 * To pass only the styling properties of Link
	 * @default: false
	 */
	withoutAnchor: PropTypes.bool
};

Link.defaultProps = {
	underline: true,
	target: '_self',
	disabled: false,
	isLoading: false,
	withoutAnchor: false,
	size: 'default'
};

export {
	Link as default,
	Link,
}
