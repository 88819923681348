import React, {useState} from 'react';
import {BUTTON_APPEARANCE, BUTTON_WIDTH, Button} from "../../button/Button";
import Userprofile from "../../userprofile/Userprofile";
import './HeaderDigitalOffer.scss';
import PropTypes from "prop-types";
import {Header} from "../Header";
import RevisionSelect from "../../revisionSelect/RevisionSelect";
import LoginModal from "../../loginModal/LoginModal";
import {connect} from "react-redux";
import OfferPrintSelect from "../../offerDownloadConfigurator/OfferPrintSelect";
import ButtonAnchor from "../../button/ButtonAnchor";
import {Tooltip} from "../../tooltip/Tooltip";

function HeaderDigitalOffer(props) {

  const [profileIsVisible, showUserProfile] = useState(false);
  const [loginIsVisible, showLogin] = useState(false);

  function renderButtons() {

    if (props.isAuthenticated) {
      return <Button label='Ihr Konto'
                     icon='circle-user' additionalClasses={'header__button'}
                     appearance={BUTTON_APPEARANCE.SECONDARY}
                     width={BUTTON_WIDTH.FIT}
                     onClick={() => showUserProfile(true)}/>
    } else {
      return <Button label={'Anmelden'}
                     icon={'login'}
                     additionalClasses={'header__button no-print'}
                     appearance={BUTTON_APPEARANCE.SECONDARY}
                     width={BUTTON_WIDTH.FIT}
                     onClick={() => showLogin(true)}/>
    }
  }

  return (
    <Header stickToTop={props.stickToTop} additionalClasses={props.additionalClasses} logoVariant={'digitalOffer'}>
      <div className="header__interaction">
        <div className='header__offer'>
          <Tooltip
              showDefault={true}
              content='Antworten auf Ihre Fragen'
              direction={'bottom'}
            >
            <ButtonAnchor label='FAQ'
                          href='/faq'
                          target='_blank'
                          width={BUTTON_WIDTH.FIT}
                          appearance={BUTTON_APPEARANCE.QUATERNARY}
                          icon='circle-question'
                          additionalClasses={'header__button'}
            />
          </Tooltip>
          <RevisionSelect/>
        </div>

        {(props.isDesktop) && <OfferPrintSelect appearance={BUTTON_APPEARANCE.GHOST}
                                                buttonWidth={BUTTON_WIDTH.FIT}
                                                buttonLabel={'Druckversion'}
        />}

        {renderButtons()}
      </div>
      {profileIsVisible &&
      <Userprofile onClose={() => showUserProfile(false)}/>
      }

      {loginIsVisible &&
      <LoginModal onClose={() => showLogin(false)}/>
      }

    </Header>
  )
}

HeaderDigitalOffer.propTypes = {
  /**
   * set Header sticky
   *
   * @default: true
   */
  stickToTop: PropTypes.bool,
  /**
   * OPTIONAL: add additional classes for this component here.
   * Use this prop for special css-classes, which are not defined by default.*
   */
  additionalClasses: PropTypes.string,

  /**
   * is logged in successfully
   */
  isAuthenticated: PropTypes.bool,

  /**
   * Method to store new offerNumber in localStorage
   * located in MainPage.js
   *
   */
  onRevisionChange: PropTypes.func

};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.globalStateManagement.isAuthenticated,
    isDesktop: state.globalStateManagement.isDesktop,
  }
};

export default connect(mapStateToProps)(HeaderDigitalOffer);

