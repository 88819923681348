import React, { useState } from 'react';
import './CheckoutNavigation.scss';
import classNames from "classnames";
import PropTypes from "prop-types";
import Button from "../button/Button";
import { dispatchCheckoutBackward } from "../../redux/actions/checkoutManagementAction";
import { connect } from "react-redux";
import Checkbox from "../checkbox/Checkbox";
import Link from "../link/Link";
import { Tooltip } from "../tooltip/Tooltip";

export const CHECKOUT_BUTTON_CONFIG = {
  1: {
    prev: {
      label: null,
      icon: null
    },
    next: {
      label: 'Zu den Adressdaten',
      icon: 'arrow-right',
    }
  },
  2: {
    prev: {
      appearance: 'link',
      label: 'zu den Reiseteilnehmerdaten',
      icon: 'arrow-left',
    },
    next: {
      label: 'Zur Zusammenfassung',
      icon: 'arrow-right',
    }
  },
  3: {
    reverseOrder: true,
    prev: {
      label: null,
      icon: null,
      disclaimerCheckbox: true
    },
    next: {
      appearance: 'important',
      label: 'Jetzt Buchen',
      icon: 'arrow-right',
    }
  }
};


function CheckoutNavigation(props) {
  const [arbChecked, setArbChecked] = useState(false);
  const classes = classNames(
    'checkout-navigation',
    {'checkout-navigation--reverse-order': CHECKOUT_BUTTON_CONFIG[props.checkoutStep].reverseOrder},

    props.additionalClasses
  );

  function checkboxHandler(valid) {
    setArbChecked(valid)
    props.onChange({
      valid: valid,
      type: 'checkbox',
      value: valid,
      name: 'arb',
      compareId: null
    })
  }

  function renderCheckbox(hasError) {
    let renderContent = null;
    const inputJSX = <Checkbox name={'arb'}
                               disabled={false}
                               required={false}
                               checked={arbChecked}
                               changeHandler={checkboxHandler}
                               additionalClasses={(hasError && !arbChecked) ? 'error' : ''}/>

    if (hasError && !arbChecked) {
      renderContent = <Tooltip
        content={'Bitte akzeptieren'}
        showDefault={hasError}
        inactive={!hasError}
        color={'important'}
        direction={'top-right'}
        parentContainerSelector={'#checkout-summary__consent-id'}
      >

        {inputJSX}
      </Tooltip>
    } else {
      renderContent = inputJSX;
    }
    return renderContent;
  }


  function renderNavigationButtons(currentStep) {
    const btn = CHECKOUT_BUTTON_CONFIG[currentStep]
    return (
      <div className={'checkout-navigation__button-wrapper'}>
        {btn.prev.label &&
        <Button label={btn.prev.label}
                icon={btn.prev.icon}
                appearance={btn.prev.appearance}
                width={'fit'}
                onClick={() => {
                  props.dispatchCheckoutBackward()
                }}
        />
        }

        {btn.prev.disclaimerCheckbox &&
        <div className={'checkout-summary__consent'} id={'checkout-summary__consent-id'}>
          {renderCheckbox(props.formHasError)}
          <div className={'checkout-summary__consent-label ts-bold'}>
            <Link label={'Allgemeine Reisebedingungen (ARB)'}
                  url={'https://www.canusa.de/service/reisebedingungen'}
                  target={'_blank'}
            /><span>gelesen und akzeptiert</span>
          </div>
        </div>
        }
        {(!btn.prev.disclaimerCheckbox && !btn.prev.label) && <div></div>}
        {btn.next.label ?
          <Button label={btn.next.label}
                  icon={btn.next.icon}
                  type={'submit'}
                  appearance={btn.next.appearance}
                  isLoading={props.isLoading}
                  width={'fit'}
                  onClick={() => {
                  }}
          />
          :
          <div></div>}
      </div>
    )
  }

  return (
    <div className={classes}>
      {renderNavigationButtons(props.checkoutStep)}
    </div>
  )
}

CheckoutNavigation.propTypes = {
  /**
   * amount of possible steps
   */
  steps: PropTypes.number,
  /**
   * progress
   */
  currentStep: PropTypes.number,

  /**
   * isLoading
   */
  isLoading: PropTypes.bool,

  /**
   * changeHandler
   */
  onChange: PropTypes.func,

};

CheckoutNavigation.defaultProps = {
  steps: 3,
  currentStep: 1
};

export default connect(null, {
  dispatchCheckoutBackward: dispatchCheckoutBackward,
})(CheckoutNavigation);
