import React, {useState} from 'react';
import classNames from "classnames";
import PropTypes from "prop-types";
import {Link} from "./Link";
import {Tooltip} from "../tooltip/Tooltip";
import "./LinkPdf.scss";
import withPdfDownload from "../../hoc/withPdfDownload";

const LinkWithPdfDownload = withPdfDownload(Link);

function LinkPdf(props) {

	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);

	const tooltipText = "Beim herunterladen der Datei ist ein Fehler aufgetreten, bitte versuchen Sie es noch einmal.";

	function downloadCompleteHandler() {
		setIsLoading(false);
		setIsError(false);
	}

	function downloadErrorHandler() {
		setIsLoading(false);
		setIsError(true);
	}

	function clickHandler() {
		setIsError(false);
		setIsLoading(true);
	}

	const classes = classNames(
		'pdf-link',
		props.additionalClasses,
		{'has-error': isError}
	);

	let iconName = isLoading ? 'loading' : props.icon;

	if (isError) {
		iconName = 'warning'
	}

	let linkContent = <LinkWithPdfDownload
		isLoading={isLoading}
		label={props.label}
		icon={iconName}
		url={props.url}
		download={props.download}
		type={'trigger'}
		underline={props.underline}
		additionalClasses={classes}
		onClick={clickHandler}
		onDownloadComplete={downloadCompleteHandler}
		onDownloadError={downloadErrorHandler}
	/>;

	if (isError) {
		linkContent = <Tooltip color={"important"}
							   content={tooltipText}
							   direction={props.optionalTooltipDirection}
		>
			{linkContent}
		</Tooltip>
	}


	return (
		linkContent
	);
}

LinkPdf.propTypes = {
	/**
	 * URl of the service which returns PDF as a blob
	 *
	 * @example: '/restapi/document/download/offer/?offerId=1339312&type=journey_info'
	 */
	url: PropTypes.string,

	/**
	 * Link Label
	 *
	 * @example: Link Label
	 */
	label: PropTypes.string,

	/**
	 * Link Icon
	 *
	 * Visit http://localhost:6060/#icon for more infos.
	 *
	 * @example: 'pdf'
	 */
	icon: PropTypes.string,

	/**
	 * Name of downloaded file, forces file to download if set
	 */
	download: PropTypes.string,

	/**
	 * Link with (dotted) underline
	 *
	 * @default: true
	 */
	underline: PropTypes.bool,

	/**
	 * Direction of the tooltip
	 *
	 * @default: top
	 */
	optionalTooltipDirection: PropTypes.oneOf(['top', 'top-right', 'top-left', 'bottom', 'bottom-right', 'bottom-left', 'right', 'left']),

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,
};


export {
	LinkPdf as default,
	LinkPdf
}
