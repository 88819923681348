import {DataProviderService, RestConfVO} from "./DataProviderService";


class LoginService extends DataProviderService {

	setData(value) {
		let siteBrand = 'portal-canusa';

		//TODO: use this process env for targeting different entrypoints for build, see also: https://blog.logrocket.com/multiple-entry-points-in-create-react-app-without-ejecting/
		if(process.env.REACT_APP_ENTRY_ENV ==='da' && this.endpoint === 'resetpassword') {
			siteBrand = 'angebot-canusa';
		}
		const vo = new RestConfVO();
		vo.apiUrl = `/api/userapi.php/${siteBrand}/${this.endpoint}`;

		if (this.useLegacyAPI) {
			vo.apiUrl = `/api/offer.php/${siteBrand}/${this.endpoint}`;
		}

		vo.apiUrl = `/api/${this.endpoint}`

		vo.method = 'post';
		// for legacy reasons token and securityToken, needs to be added
		if (this.endpoint !== 'login') {
			value.append('token', this.storageManager.authToken);
			value.append('securityToken', this.storageManager.securityToken);
		}
		vo.data = value;

		vo.headers = {"Content-type": "application/x-www-form-urlencoded"};

		return this.sendRequest(vo).then(response => {

			if (this.endpoint === 'login' || this.endpoint === 'resetpassword') {
				if (response.token) {
					this.storageManager.authToken = response.token;
				} else {
					this.storageManager.authToken = null;
				}
			}

			return response;
		});
	}

}

export {
	LoginService
}
