export const IS_DESKTOP = 'IS_DESKTOP';
export const OFFER_NUMBER = 'OFFER_NUMBER';
export const REVISION_NUMBER = 'REVISION_NUMBER';
export const OFFER_LIST = 'OFFER_LIST';
export const STAGE_IMAGE = 'STAGE_IMAGE';
export const JOURNEY_DATES = 'JOURNEY_DATES';
export const STORE_CLEAR = 'STORE_CLEAR';
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const PERMISSIONS = 'PERMISSIONS';
export const IS_MAIN_CLIENT = 'IS_MAIN_CLIENT';
export const ENVIRONMENT = 'ENVIRONMENT';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const NOTIFICATION_MESSAGE_SHOW = 'NOTIFICATION_MESSAGE_SHOW';
export const NOTIFICATION_MESSAGE_HIDDEN = 'NOTIFICATION_MESSAGE_HIDDEN';

export const dispatchClearStore = () => ({
	type: STORE_CLEAR
});

export const dispatchIsDesktop = viewport => ({
	type: IS_DESKTOP,
	isDesktop: viewport
});

export const dispatchUpdateOffer = offer => ({
	type: OFFER_NUMBER,
	offer: offer
});

export const dispatchRevisionChange = revision => ({
	type: REVISION_NUMBER,
	revision: revision
});

export const dispatchUpdateOfferList = offerList => ({
	type: OFFER_LIST,
	offerList: offerList
});

export const dispatchUpdateStageImage = stageImage => ({
	type: STAGE_IMAGE,
	stageImage: stageImage
});

export const dispatchUpdateJourneyDates = (journeyStart, journeyEnd) => ({
	type: JOURNEY_DATES,
	journeyStart: journeyStart,
	journeyEnd: journeyEnd
});

export const dispatchIsAuthenticated = isAuthenticated => ({
	type: IS_AUTHENTICATED,
	isAuthenticated: isAuthenticated
});

export const dispatchPermissions = permissions => ({
	type: PERMISSIONS,
	permissions: permissions
});

export const dispatchIsMainClient = isMainClient => ({
	type: IS_MAIN_CLIENT,
	isMainClient: isMainClient
});

export const dispatchEnvironment = environment => ({
	type: ENVIRONMENT,
	environment: environment
});

export const dispatchOpenModal = modalId => ({
	type: OPEN_MODAL,
	modalId
});

export const dispatchCloseModal = () => ({
	type: CLOSE_MODAL
});


export const dispatchShowNotificationMessage = notificationMessage => ({
	type: NOTIFICATION_MESSAGE_SHOW,
	notificationMessage
});

export const dispatchHiddenNotificationMessage = () => ({
	type: NOTIFICATION_MESSAGE_HIDDEN
});

