import React from 'react';
import './ItemSelectOption.scss'
import {Tooltip} from "../../tooltip/Tooltip";
import {connect} from "react-redux";
import {BUTTON_APPEARANCE, Button} from "../../button/Button";
import ControlledCheckbox from "../../checkbox/ControlledCheckbox";
import {dispatchUpdateOption} from "../../../redux/actions/itemManagementAction";

function ItemSelectOption(props) {

    const isSelected = props.selected;

    const tooltipText = props.permissions ? props.permissions.bookableReason : 'Bitte kontaktieren Sie Ihren Reiseberater, um Ihren gewünschten Ausflug zu buchen.';
    const matchTimeFormat = props.time.match(/(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]/gm);
    const dateText = props.time.split('-').reverse().join('.')

    const eventText = matchTimeFormat ? props.time.replace('-', ' — ') : dateText; // reformat date with "geviert" instead of "dash"

    const disabled = props.readOnly || !(props.permissions?.bookable);

    /**
     * toggles selectionButton
     * @param event
     */
    function toggleSelectionButton(event) {
        props.dispatchUpdateOption(!isSelected, {
            date: props.date,
            time: props.time,
            price: props.price,
            itemId: props.itemId
        });
    }

    /**
     * create content (date & price)
     */
    let content = (
        <div className={'item-select-option__details'}>
            <div className={'item-select__event ts-bold'}>{eventText}</div>
            <div className={'item-select__price'}>
                {'€ ' + props.price + ',-'}
            </div>
        </div>
    );

    // add tooltip on mobile devices
    if (!props.isDesktop) {
        content = (
            <Tooltip content={tooltipText}
                     direction={'top'}
                     inactive={!props.readOnly}
            >
                {content}
            </Tooltip>
        )
    }

    return (

        <div className={'item-select-option__option'}>
            <label htmlFor={props.optionId} className={'item-select-option__content'}>
                {content}
                {props.isDesktop ?
                    // hide Button on mobile devices
                    <span className={'item-select-option__selector-button'}>
				<Tooltip content={tooltipText}
                         direction={'left'}
                         inactive={!disabled}
                >
					<div className={'item-select__button'}>
						<Button label={!!isSelected ? 'Ausgewählt' : 'Auswählen'}
                                appearance={!!isSelected ? BUTTON_APPEARANCE.PRIMARY : BUTTON_APPEARANCE.SECONDARY}
                                disabled={disabled || props.isSaving} onClick={(event) => {
                            toggleSelectionButton(event);

                        }}/>
					</div>
				</Tooltip>
				</span>
                    :
                    <span className={'item-select-option__selector-checkbox'}>
				<Tooltip content={tooltipText}
                         direction={'left'}
                         inactive={!props.readOnly}
                >
					<div className={'item-select__checkbox'}>
						<ControlledCheckbox name={props.optionId}
                                            uid={props.optionId}
                                            checked={!!isSelected}
                                            disabled={disabled || props.isSaving}
                                            onChange={event => toggleSelectionButton(event)}
                        />
					</div>
				</Tooltip>
				</span>
                }
            </label>
        </div>
    );
}

const mapStateToProps = (state) => {

    return {
        isDesktop: state.globalStateManagement.isDesktop,
        permissions: state.globalStateManagement.permissions,
        isSaving: state.checkoutManagement.isSaving,
    }
};

const ItemSelectOptionDA = connect(mapStateToProps, {
    dispatchUpdateOption: dispatchUpdateOption,
})(ItemSelectOption);

export {
    ItemSelectOptionDA as default,
    ItemSelectOptionDA,

}
