import React, {useState} from 'react';
import './CheckoutParticipants.scss';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormValidator} from "../../form/FormValidator";
import {createParticipants} from "../../../../vendor/utils/ParticipantsUtils";
import CheckoutNavigation from "../CheckoutNavigation";
import {dispatchUpdateParticipants} from "../../../redux/actions/participantsAction";
import {viewportUtils} from "../../../../vendor/utils/ViewportUtils";
import ErrorMessage from "../../error/ErrorMessage";
import Icon from "../../icon/Icon";
import ContactBoxContainer from "../../contactbox/ContactBoxContainer";


function CheckoutParticipants(props) {

	const [showFormErrors, setShowFormErrors] = useState(false);

	/**
	 * Form submit handler
	 *
	 * @param form
	 * @param isValid
	 */
	function handleSubmit({form, isValid, firstErrorSelector}) {
		if (isValid) {
			props.dispatchUpdateParticipants(form)
		} else {
			viewportUtils.scrollIntoView(firstErrorSelector, '.participants-form__participant', '#checkout-modal');
			setShowFormErrors(true);
		}
	}

	return (
		<>
			<h1 className="ts-bold tt-none">Reiseteilnehmerdaten</h1>
			<p>Bitte vervollständigen Sie die Daten aller Reiseteilnehmer, um die Buchung durchführen zu können.</p>
			<FormValidator additionalClasses="checkout_wrapper__form"
										 onSubmit={handleSubmit}
										 render={({changeHandler, formIsValid}) => (
											 <>
												 <div className={'participants-form__content'}>
													 {props.participantsList && createParticipants(
														 props.participantsList,
														 changeHandler,
														 props.parentId,
														 showFormErrors
													 )}
												 </div>
												 {props.hasLoadingError && <ErrorMessage>
														 <h2 className={'error-message__h2'}><Icon name={'warning'} additionalClasses={'error-message__icon'}/>Es ist ein Fehler aufgetreten</h2>
														 <div className={'error-message__text-block'}>
															 <p>Leider haben wir gerade mit technischen Schwierigkeiten zu kämpfen und können Ihnen
																 unseren Service derzeit nicht zur Verfügung stellen.</p>
															 <p>Wir arbeiten bereits an einer Lösung.
																 Sollte das Problem weiterhin bestehen, rufen Sie mich an</p>
															 <ContactBoxContainer appearance={'modal'}/>
														 </div>
												 </ErrorMessage>}
												 <CheckoutNavigation checkoutStep={props.checkoutStep} isLoading={props.isLoading}/>
											 </>
										 )}
			/>
		</>
	);
}

CheckoutParticipants.propTypes = {
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string
};

CheckoutParticipants.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
	return {
		participantsList: state.participantManagement.list,
		isLoading: state.participantManagement.isLoading,
		hasLoadingError: state.participantManagement.hasLoadingError
	}
};

export default connect(mapStateToProps,
	{
		dispatchUpdateParticipants: (form, isCheckout) => dispatchUpdateParticipants(form, true)
	}
)(CheckoutParticipants);
