import React, {Component} from 'react';
import './LoginPage.scss';
import Footer from "../../components/footer/Footer";
import Stage from "../../components/stage/Stage";
import {AUTH_TYPES, LoginWrapper} from "../../components/login/LoginWrapper";
import withDataProvider from "../../hoc/WithDataprovider";
import {Header} from "../../components/header/Header";
import classNames from "classnames";
import {StorageManager} from "../../services/StorageManager";
import {Consent} from "../../components/consent/Consent";

const StageWithDataProvider = withDataProvider(Stage, {
	useMock: false,
	//endpoint: 'portal/stage',
	endpoint: 'stage',
	value: {topic: 'login'}
});

class LoginPage extends Component {

	constructor(props) {
		super(props);

		this.widthChange = this.widthChange.bind(this);
		this.mediaQuery = window.matchMedia("(min-width: 1024px)");
		this.mediaQuery.addListener(this.widthChange);

		this.state = {
			isDesktop: this.mediaQuery.matches
		}
	}

	widthChange() {

		this.setState({
			isDesktop: this.mediaQuery.matches
		});
	}

	componentWillUnmount() {
		if (this.mediaQuery) {
			this.mediaQuery.removeListener(this.widthChange);
		}
	}

	render() {

		let type = this.props.type || (this.props.match && this.props.match.params.variant) || "login";

		const classes = classNames(
			'login_page',
			'page-full-height'
		);

		return (
			<div className={classes}>
				<Header logoVariant={'portal'}/>
				<StageWithDataProvider withContent={false} join={true}/>
				<div className='centered-content'>
					<LoginWrapper type={type} location={this.props.location} isDesktop={this.state.isDesktop}/>
				</div>
				{!StorageManager.getCookie('acceptedCookies') &&
				<Consent brandName={'CANUSA'}
								 brandUrl={'https://www.canusa.de'}
				/>
				}
				<Footer/>
			</div>
		)
	}
}

export default LoginPage;

export function LoginScreen(props){
	return <LoginPage type={AUTH_TYPES.LOGIN} {...props}/>;
}

export function LogoutScreen(props){
	return <LoginPage type={AUTH_TYPES.LOGOUT} {...props}/>;
}

export function ResetScreen(props){
	return <LoginPage type={AUTH_TYPES.RESET} {...props}/>;
}

export function ForgotScreen(props){
	return <LoginPage type={AUTH_TYPES.FORGOT} {...props}/>;
}

export function NewPasswordScreen(props){
	return <LoginPage type={AUTH_TYPES.NEW_PASSWORD} {...props}/>;
}

export function VerificationScreen(props){
	return <LoginPage type={AUTH_TYPES.VERIFICATION} {...props}/>;
}
